import { create } from "zustand";

interface State {
  itwHoursWorkedByTeamMemberPageCounter: number;
  itwAttendanceDetailsPageCounter: number;
  awdHoursWorkedByAgentPageCounter: number;
  awdNumberOfAgentsByClientPageCounter: number;
  awdAttendanceDetailsPageCounter: number;
}

interface Actions {
  setItwHoursWorkedByTeamMemberPageCounter: (pageNum: number) => void;
  setAwdHoursWorkedByAgentPageCounter: (pageNum: number) => void;
  setAwdNumberOfAgentsByClientPageCounter: (pageNum: number) => void;
  setAwdAttendanceDetailsPageCounter: (pageNum: number) => void;
  setItwAttendanceDetailsPageCounter: (pageNum: number) => void;
  resetAllPageCounter: () => void;
}

const INITIAL_STATE: State = {
  itwHoursWorkedByTeamMemberPageCounter: 1,
  awdHoursWorkedByAgentPageCounter: 1,
  awdNumberOfAgentsByClientPageCounter: 1,
  awdAttendanceDetailsPageCounter: 1,
  itwAttendanceDetailsPageCounter: 1,
};

export const TableStore = create<State & Actions>((set) => ({
  itwHoursWorkedByTeamMemberPageCounter:
    INITIAL_STATE.itwHoursWorkedByTeamMemberPageCounter,
  awdHoursWorkedByAgentPageCounter:
    INITIAL_STATE.awdHoursWorkedByAgentPageCounter,
  awdNumberOfAgentsByClientPageCounter:
    INITIAL_STATE.awdNumberOfAgentsByClientPageCounter,
  awdAttendanceDetailsPageCounter:
    INITIAL_STATE.awdAttendanceDetailsPageCounter,
  itwAttendanceDetailsPageCounter:
    INITIAL_STATE.itwAttendanceDetailsPageCounter,
  resetAllPageCounter: () => {
    return set(() => ({
      itwHoursWorkedByTeamMemberPageCounter: 1,
      awdHoursWorkedByAgentPageCounter: 1,
      awdNumberOfAgentsByClientPageCounter: 1,
      awdAttendanceDetailsPageCounter: 1,
      itwAttendanceDetailsPageCounter: 1,
    }));
  },
  setItwAttendanceDetailsPageCounter: (pageNum) => {
    // alert(pageNum);
    return set(() => ({
      itwAttendanceDetailsPageCounter: pageNum,
    }));
  },
  setAwdAttendanceDetailsPageCounter: (pageNum) => {
    // alert(pageNum);
    return set(() => ({
      awdAttendanceDetailsPageCounter: pageNum,
    }));
  },
  setAwdNumberOfAgentsByClientPageCounter: (pageNum) => {
    // alert(pageNum);
    return set(() => ({
      awdNumberOfAgentsByClientPageCounter: pageNum,
    }));
  },
  setItwHoursWorkedByTeamMemberPageCounter: (pageNum) => {
    // alert(pageNum);
    return set(() => ({
      itwHoursWorkedByTeamMemberPageCounter: pageNum,
    }));
  },
  setAwdHoursWorkedByAgentPageCounter: (pageNum) => {
    // alert(pageNum);
    return set(() => ({
      awdHoursWorkedByAgentPageCounter: pageNum,
    }));
  },
}));
