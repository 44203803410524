import { useEffect, useState } from "react";
import logo from "../assests/Image.jpeg";
import dp from "../assests/dp.jpeg";
import { Link } from "react-router-dom";
import { ChevronUp } from "lucide-react";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import BillKeyIcon from "../assests/BillKeySideBaricon.png";
import ProcessBillingIcon from "../assests/ProcessBillingIcon.png";
import InvoiceReportingIcon from "../assests/InvoiceReportingSidebar.png";
import logOutIcon from "../assests/logoutIconSidebar.png";
import { LogOut } from "lucide-react";
export default function Sidebar() {
  const { name, setname, login, setlogin, LogOutUser, userName, userPic } =
    useContext(AppContext);
  const [dropdownopen, setdropdownopen] = useState(false);

  const [selected, setselected] = useState("Bill Key");
  const location = useLocation().pathname;

  const SideBarButton = (props) => {
    useEffect(() => {
      console.log("props", props);
      console.log("location", location);
    }, []);

    return (
      <Link to={props.linkTo}>
        <div
          style={{ background: location == props.linkTo ? "#DBFFDF" : "" }}
          className={` flex items-center px-4 py-3  cursor-pointer rounded  mx-1 my-3 mt-2  ${
            location == props.linkTo ? " shadow-lg" : " shadow-none"
          }`}
          // onClick={() => setselected(props.Name)}
        >
          <img className=" px-2 h-[23px] cursor-pointer" src={props.src} />
          <div className=" text-[#163143] text-[14px] font-[400]">
            {props.Name}
          </div>
        </div>
      </Link>
    );
  };
  return (
    <div className="   w-[17%]  py-3 bg-white flex flex-col justify-between">
      <div className="  w-full h-full ">
        <div className=" border-b-2 py-2 flex items-center">
          <img className=" mx-4 w-[120px]" src={logo} />
        </div>
        <SideBarButton src={BillKeyIcon} linkTo={"/"} Name={"Bill Key"} />
        <SideBarButton
          src={ProcessBillingIcon}
          linkTo={"/ProcessBilling"}
          Name={"Process Billing"}
        />
        <SideBarButton
          src={InvoiceReportingIcon}
          linkTo={"/HistoricInvoices"}
          Name={"Historic Invoices"}
        />
      </div>
      {dropdownopen == true ? (
        <div className=" pb-8 border  rounded-t-lg">
          <div
            onClick={() => LogOutUser()}
            className=" w-full  gap-3 hover:bg-slate-100 flex items-center    px-2 py-3  cursor-pointer  "
          >
            <LogOut
              className=" w-6 h-6  mx-2 cursor-pointer duration-500 rotate-180 "
              color="black "
            />
            <div className="  text-[14px] font-normal">Logout</div>
          </div>
        </div>
      ) : null}
      <div className=" w-full border-t-2 flex justify-between px-4 items-center h-[65px]">

        <div className=" flex items-center justify-center gap-2">
        {/* <img className=" px-2 h-[90%] cursor-pointer" src={dp} /> */}
        <div className=" bg-red-400 rounded-full flex items-center justify-center text-center w-8  h-8 text-white font-medium text-[22px]">
          {String(localStorage.getItem('user_name'))[0]}

        </div>

        <div className=" text-[16px] font-medium">{localStorage.getItem('user_name')}</div>
        </div>
        {dropdownopen == true ? (
          <ChevronUp
            onClick={() => setdropdownopen(false)}
            className=" w-6 h-6  cursor-pointer duration-500 rotate-180 "
            color="black "
          />
        ) : (
          <ChevronUp
            onClick={() => setdropdownopen(true)}
            className=" w-6 h-6 cursor-pointer duration-500 rotate-0 "
            color="black "
          />
        )}
      </div>
    </div>
  );
}
