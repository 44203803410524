import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import BillKeyPage from "../components/BillKey/BillKeyPage";
import ProcessBilling from "../components/ProcessBilling/ProcessBilling";
import Invoicereporting from "../components/InvoiceReporting/Invoicereporting";
import Login from "../components/Auth/Login";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import { useState, useEffect } from "react";
import SplashScreen from "./SplashScreen";

export default function AppRouter() {
  const [loading, setLoading] = useState(true);
  const { login } = useContext(AppContext);

  useEffect(() => {
    // Simulate checking login status or any async operation
    const timeout = setTimeout(() => setLoading(false), 1000); // Example delay
    return () => clearTimeout(timeout);
  }, []);

  if (loading) {
    return <SplashScreen />;
  }

  return login == true ? (
    <Router>
      <div
        style={{ display: "flex" }}
        className=" bg-[#F1F5F5] w-[100vw] h-[100vh] font-poppins"
      >
        <Sidebar />

        <div className=" w-[83%]    ">
          <Routes>
            <Route path="/" element={<BillKeyPage />} />
            <Route path="/ProcessBilling" element={<ProcessBilling />} />
            <Route path="/HistoricInvoices" element={<Invoicereporting />} />
          </Routes>
        </div>
      </div>
    </Router>
  ) : (
    <Login />
  );
}
