import "./App.css";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import AppState from "./context/AppState";
import AppRouter from "./Router/AppRouter";
import { Toaster } from "react-hot-toast";

function App() {
  useEffect(() => {}, []);
  const queryClient = new QueryClient();
  return (
    <AppState>
      <QueryClientProvider client={queryClient}>
        <AppRouter />
        <Toaster />
      </QueryClientProvider>
    </AppState>
  );
}

export default App;
