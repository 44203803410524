import Modal from "react-modal";
import { Button } from "../ui/button.tsx";
import { useEffect, useState } from "react";
import { X } from "lucide-react";
import { ChevronDown } from "lucide-react";
import toast from "react-hot-toast";
import axios from "axios";
import AppContext from "../../context/AppContext.js";
import { useContext } from "react";
import { Skeleton } from "../ui/skeleton.tsx";

export default function GenerateInvoiceConfirmationModal({
  showGenerateInvoiceModal,
  setshowGenerateInvoiceModal,
  selected,
  selectAll,
  loading,
  setloading,
  getData,
}) {
  const { ProcessBillingSelectedClientFilter } = useContext(AppContext);

  const GenerateInvoices = async () => {
    try {
      setloading(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {
        client_ids: selectAll==true?[]: selected,
      };

      console.log("body", body);
      const selectedClients =
        ProcessBillingSelectedClientFilter.length > 0
          ? ProcessBillingSelectedClientFilter?.map(
              (item) => `&filter_ids=${item}`
            )
          : [];

      const url = `${
        process.env.REACT_APP_BASE_URL
      }/generate-invoice?select_all=${selectAll}${selectedClients?.join("")}`;

      console.log("url", url);
   

      const res = await axios.post(url, body, config);

      console.log("res", res.data);
      if (res.status == 200) {
        console.log("res", res);

        toast.success("Invoice Generated Successfully");

        setshowGenerateInvoiceModal(false);
        
        getData(1);
      }
    } catch (error) {
      setloading(false);
      setshowGenerateInvoiceModal(false);

      console.log("error", error?.response);
 

      toast.error(error?.response?.data?.detail);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      outline: "none",
    },
  };
  
  
  if(loading==true)
  {
    return(
      <Modal
      isOpen={showGenerateInvoiceModal}
      onRequestClose={() => {
        setshowGenerateInvoiceModal(false);
      }}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      className="fixed inset-0 flex items-center justify-center z-50 font-poppins "
      overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
      //   ariaHideApp={false}
    >
       <div className="flex relative  flex-col overflow-hidden     bg-white rounded-3xl   shadow-lg  max-h-[300px] max-w-[500px]  h-[50vh] w-[45vw]  ">
        <div className=" z-10 absolute h-full text-[20px]  text-slate-400 w-full flex items-center  justify-center">
          Generating Invoices
        </div>
        <Skeleton className=" z-0 h-full w-full" />

       </div>
      </Modal>
    )
  }


  return (
    <Modal
      isOpen={showGenerateInvoiceModal}
      onRequestClose={() => {
        setshowGenerateInvoiceModal(false);
      }}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      className="fixed inset-0 flex items-center justify-center z-50 font-poppins "
      overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
      //   ariaHideApp={false}
    >
      <div className="flex  flex-col  py-4 px-6   bg-white rounded-3xl   shadow-lg  max-h-[300px] max-w-[500px]  h-[50vh] w-[45vw]  ">
        <div className="flex items-end justify-end">
          <X
            className=" cursor-pointer"
            onClick={() => setshowGenerateInvoiceModal(false)}
          />
        </div>
        <div className=" flex justify-center text-[24px] font-semibold">
          Confirmation
        </div>
        <div className=" flex justify-center text-center mt-4 text-[16px] font-normal">
          Are you sure you want to Generate and Send/Bill Invoices?
        </div>

        <div
          onClick={() => [GenerateInvoices()]}
          className=" my-1 mt-7 bg-[#69C920] rounded-3xl flex items-center justify-center text-[white] text-[16px] font-semibold h-[44px] cursor-pointer"
        >
          Confirm
        </div>
        <div
          onClick={() => setshowGenerateInvoiceModal(false)}
          className="  my-1 border rounded-3xl flex items-center justify-center text-[#163143] text-[16px] font-semibold h-[44px] cursor-pointer"
        >
          Cancel
        </div>
      </div>
    </Modal>
  );
}
