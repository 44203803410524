import Modal from "react-modal";
import { Button } from "../ui/button.tsx";
import { useEffect, useState } from "react";
import { X } from "lucide-react";
import axios from "axios";
import toast from "react-hot-toast";
import { Skeleton } from "../ui/skeleton.tsx";




export default function EditAgentBillkeyModal({
  showModal,
  setshowModal,
  agentInfo,
  setagentInfo,
  showArchiveClientModal,
  setshowArchiveClientModal,
  getData
}) {
  const customStyles2 = {
    content: {
      top: "auto",
      left: "auto",
      right: "0%",
      bottom: "auto",
      outline: "none",
    },
  };
  

  const [AgentName, setAgentName] = useState("");
  const [ClientStandardBillRate, setClientStandardBillRate] = useState("");
  const [AdjustmentToBillRate, setAdjustmentToBillRate] = useState("");
  const [CyberSecurityPlan, setCyberSecurityPlan] = useState("");
  const [Details, setDetails] = useState("");
  const [RateIncreaseNotes, setRateIncreaseNotes] = useState("");
  const [TeamLead, setTeamLead] = useState("");
  const [StripeProductID, setStripeProductID] = useState("");
  const [QuickbooksID, setQuickbooksID] = useState("");
  const [HubstaffuserID, setHubstaffuserID] = useState("");

  useEffect(() => {
    console.log("agentInfo", agentInfo);
    setAgentName(agentInfo?.user_name);
    setClientStandardBillRate(agentInfo?.bill_rate);
    setAdjustmentToBillRate(agentInfo?.adjustment_to_bill_rate);
    setCyberSecurityPlan(agentInfo?.cyber_security_plan);
    setDetails(agentInfo?.details);
    setRateIncreaseNotes(agentInfo?.rate_increase_notes);
    setTeamLead(agentInfo?.team_lead);
    setStripeProductID(agentInfo?.stripe_product_id);
    setQuickbooksID(agentInfo?.qb_id);
    setHubstaffuserID(agentInfo?.hubstaff_user_id);
  }, [showModal]);



  const [loading, setloading] = useState(false)

  const EditAgentBillKey = async () => {
    try {
      setloading(true);

      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {
        adjustment_to_bill_rate: AdjustmentToBillRate,
        cyber_security_plan: CyberSecurityPlan,
        detail: Details,
        rate_increase_notes: RateIncreaseNotes,
        team_lead: TeamLead,
        qb_id: QuickbooksID,
      };

      console.log("body", body);

      const url = `${process.env.REACT_APP_BASE_URL}/update-user-bill-key?id=${agentInfo?.id}`;

      console.log("url", url);

      const res = await axios.post(url, body, config);

      console.log("res", res.data);
      if (res.status == 200) {
        console.log("res", res);

        setshowModal(false);
        getData(1);
        toast.success("Agent Updated Successfully");
      }
    } catch (error) {
      setloading(false);

      console.log("error", error?.response);
    

      toast.error("error");
    }
  };


  if (loading == true) {
    return (
      <Modal
        isOpen={showModal}
        onRequestClose={() => {
          setshowModal(false);
        }}
        style={customStyles2}
        shouldCloseOnOverlayClick={false}
        className="fixed inset-0 flex items-center justify-center z-10 font-poppins"
        overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"

      >
        <div className="flex  flex-col justify-between   bg-white relative  rounded shadow-lg  h-[100vh] w-[45vw] max-w-[800px] ">
          <div className=" flex w-full h-full items-center justify-center absolute text-[40px] text-slate-400">
            Updating Info
          </div>
          <Skeleton className=" w-full h-full" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => {
        setshowModal(false);
      }}
      style={customStyles2}
      shouldCloseOnOverlayClick={false}
      className="fixed inset-0 flex items-center justify-center z-50 font-poppins"
      overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
      //   ariaHideApp={false}
    >
      <div className="flex  flex-col justify-between   bg-white  rounded shadow-lg  h-[100vh] w-[45vw] max-w-[800px] ">
        <div className=" w-full h-[100vh] ">
          <div className="  h-[144px] ">
            <h2 className="text-2xl flex items-center justify-between font-semibold text-main-text px-[20px]   border-b-2">
              <div className=" pl-2 py-4">Edit Agent Bill key Settings</div>
              <div>
                <X
                  className=" cursor-pointer"
                  onClick={() => {
                    setshowModal(false);
                  }}
                />
              </div>
            </h2>
            <div className=" flex flex-row px-[20px] border-b-2 ">
              <div className=" w-full flex flex-row justify-end py-4 ">
                <Button
                  onClick={() => {
                    setshowModal(false);
                  }}
                  className="py-3 px-8 mx-[10px] w-[130px] bg-white border text-[#163143] hover:bg-gray-100 font-normal text-base text-center rounded-3xl"
                >
                  Cancel
                </Button>
                <Button
                  variant={"secondary"}
                  onClick={() => {
                    EditAgentBillKey()
                  }}
                  className="py-3 bg-[#69C920]  px-8 mx-[10px] w-[130px] bg-green-1 border text-[white]  font-normal text-base text-center rounded-3xl"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          <div className=" px-[20px] flex-col w-full overflow-y-scroll  h-full pb-[174px]   ">
            <div className=" px-4 mt-4">
              <div>
                <div className=" flex flex-row font-semibold text-[14px]">
                  Agent Name <div className=" text-red-500">*</div>
                </div>
                <input
                  disabled
                  className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                  value={AgentName}
                  onChange={(e) => setAgentName(e.target.value)}
                />
              </div>
            </div>
            <div className=" px-4 mt-4">
              <div>
                <div className="flex flex-row font-semibold text-[14px]">
                  Client Standard Bill rate
                  <div className=" text-red-500">*</div>
                </div>
                <input
                  disabled
                  className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                  value={ClientStandardBillRate}
                  onChange={(e) => setClientStandardBillRate(e.target.value)}
                />
              </div>
            </div>

            <div className=" flex flex-col w-full px-4  mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Adjustment to Bill Rate <div className=" text-red-500">*</div>
              </div>
              <input
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                value={AdjustmentToBillRate}
                onChange={(e) => setAdjustmentToBillRate(e.target.value)}
              />
            </div>
            <div className=" flex flex-col w-full px-4  mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Cyber Security Plan <div className=" text-red-500">*</div>
              </div>
              <input
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                value={CyberSecurityPlan}
                onChange={(e) => setCyberSecurityPlan(e.target.value)}
              />
            </div>
            <div className=" flex flex-col w-full px-4  mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Details <div className=" text-red-500">*</div>
              </div>
              <input
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                value={Details}
                onChange={(e) => setDetails(e.target.value)}
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Rate Increase Notes
              </div>
              <input
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                value={RateIncreaseNotes}
                onChange={(e) => setRateIncreaseNotes(e.target.value)}
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Team Lead
              </div>
              <input
                // disabled
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                value={TeamLead}
                onChange={(e) => setTeamLead(e.target.value)}
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Stripe Product ID
              </div>
              <input
                disabled
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                value={StripeProductID}
                onChange={(e) => setStripeProductID(e.target.value)}
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                QuickBooks ID
              </div>
              <input
                // disabled
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                value={QuickbooksID}
                onChange={(e) => setQuickbooksID(e.target.value)}
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Hubstaff user ID
              </div>
              <input
                disabled
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                value={HubstaffuserID}
                onChange={(e) => setHubstaffuserID(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
