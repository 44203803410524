import { Check, ChevronDown, Cross, Search, X } from "lucide-react";
import { useRef, useState, useEffect } from "react";

const Datatemp = [
  "item1",
  "item2",
  "item3",
  "item4",
  "item5",
  "item6",
  "item7",
  "item8",
  "item9",
  "item10",
  "item11",
  "item12",
  "item13",
  "item14",
];

export default function ClientsFilter({className}) {
  const [serachQuery, setserachQuery] = useState("");
  const [openDropDown, setopenDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedList, setselectedList] = useState([]);

  const [Data, setData] = useState(Datatemp);

  const onSearchChange = (query) => {
    console.log("query", query);
    if (query == "") {
      setData(Datatemp);
    } else {
      let a = [...Datatemp];
      a = a.filter((item) => item.toLowerCase().includes(query.toLowerCase()));
      console.log("a", a);
      setData(a);
    }

    setserachQuery(query);
  };

  const AddToSelected = (item) => {
    let temp = [...selectedList];
    temp.push(item);
    setselectedList(temp);
  };

  const RemoveFromSelect = (item) => {
    let temp = [...selectedList];
    temp = temp.filter((items) => items != item);
    setselectedList(temp);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setopenDropDown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        onClick={() => setopenDropDown(!openDropDown)}
        className={`p-5 border ${className} h-9 rounded-full text-[12px]  cursor-pointer font-poppins`}
      >
        <div className=" mr-1  font-[300]">Clients</div>
        {selectedList.length > 0 && (
          <div className=" bg-[#69C920] border text-[white] font-medium text-[12px] mx-1 rounded-full w-[20px] flex h-[20px] items-center justify-center">
            {selectedList.length}
          </div>
        )}
        {openDropDown == false ? (
          <ChevronDown className="duration-500 rotate-0" />
        ) : (
          <ChevronDown className="duration-500 rotate-180" />
        )}
      </div>

      {openDropDown == true ? (
        <div className="  overflow-hidden absolute  flex mt-1  w-[300px] flex-col rounded-2xl border border-slate-400  bg-white z-30">
          <div className=" px-2 w-full  flex items-center h-[40px] justify-center border-b">
            <Search size={20} strokeWidth={2} color="gray" />
            <input
              value={serachQuery}
              placeholder="Search"
              onChange={(e) => onSearchChange(e.target.value)}
              className="flex w-full     h-[39px] px-2  rounded-t-xl outline-none"
            />
         <X onClick={()=>[setserachQuery(''), setData(Datatemp),setopenDropDown(false)]} size={20} strokeWidth={2} color="black" className=" cursor-pointer"/>
          </div>
          <div className="  flex flex-col max-h-[330px] overflow-y-scroll  ">
            {Data.map((item) => (
              <div
                onClick={() => {
                  selectedList.includes(item)
                    ? RemoveFromSelect(item)
                    : AddToSelected(item);
                }}
                className={` ${
                  selectedList.includes(item) ? "" : ""
                }  cursor-pointer px-2 flex hover:bg-slate-200  gap-3 text-[14px]  py-3    `}
              >
                <div className={  ` ${selectedList.includes(item)?'bg-[#69C920]':' border  border-slate-400'} rounded   w-5 h-5  flex items-center justify-center`}>
                  {selectedList.includes(item) && (
                    <Check size={17} color="#FFF" />
                  )}
                </div>
                {item}
              </div>
            ))}
          </div>
          <div
          className=" w-full flex items-center justify-center border-t min-h-[30px]"
            onClick={() => setselectedList([])}
          
          >
          { selectedList.length!=0&&  <div   className=" w-[80%]  flex items-center justify-center text-[14px] font-[500] py-2 my-3 rounded-full hover:bg-slate-200  cursor-pointer">
            Clear Selection
            </div>}
          </div>
        </div>
      ) : null}
    </div>
  );
}
