import { useEffect, useState } from "react";
import { ChevronUp, CheckIcon } from "lucide-react";
import editIcon from "../../assests/editIcon.jpeg";
import { ArrowUp, ArrowDown } from "lucide-react";
export default function ProcessBillingTable({
  setshowModal,
  selected,
  setselected,
  setShowModalAgent,
  data,
  setclientInfo,
  setagentInfo,
  selectAll,
  setselectAll,
  SortBy,
  setSortBy,
  SortOrder,
  setSortOrder,
}) {
  useEffect(() => {
    console.log("data", data);
  }, []);

  const handleSortName = () => {
    if (SortBy == "client_name") {
      if (SortOrder == "asc") {
        setSortOrder("desc");
      } else if (SortOrder == "desc") {
        setSortOrder("asc");
      }
    } else {
      setSortBy("client_name");
      setSortOrder("asc");
    }
  };
  const handleSortEmail = () => {
    if (SortBy == "email") {
      if (SortOrder == "asc") {
        setSortOrder("desc");
      } else if (SortOrder == "desc") {
        setSortOrder("asc");
      }
    } else {
      setSortBy("email");
      setSortOrder("asc");
    }
  };

  return (
    <div>
      <div className="  w-[3344px]  ">
        <table className="table-fixed w-full border-separate">
          <thead className=" border-b-2 text-main-text bg-[#EBF3F4]  ">
            <tr>
              <th className=" bg-[#EBF3F4]  sticky left-0 w-[110px] h-[50px] font-medium text-sm border-r-2">
                <div className=" w-full flex items-center justify-start px-2 gap-2">
                  <CheckBoxSelectAll
                    data={data}
                    selected={selected}
                    setselected={setselected}
                    selectAll={selectAll}
                    setselectAll={setselectAll}
                  />
                  <div className=" text-[12px]">Actions</div>
                </div>
              </th>
              <th className=" bg-[#EBF3F4]  sticky shadow-[#D7E6E7_3px_0px_3px_0px] left-[110px] w-[150px] font-medium text-[12px] border-r-2 border-b-2">
                <div className=" flex items-center justify-center gap-2">
                  Client Name
                  <div onClick={() => handleSortName()}>
                    {SortOrder == "asc" ? (
                      <ArrowDown
                        className=" cursor-pointer"
                        strokeWidth={2}
                        size={15}
                        color={SortBy == "client_name" ? "#69C920" : "gray"}
                      />
                    ) : (
                      <ArrowUp
                        className=" cursor-pointer"
                        strokeWidth={2}
                        size={15}
                        color={SortBy == "client_name" ? "#69C920" : "gray"}
                      />
                    )}
                  </div>
                </div>
              </th>
              <th className=" w-[200px] font-medium text-[12px] border-r-2 border-b-2">
                <div className=" flex items-center justify-center gap-2">
                  Email Address
                  <div onClick={() => handleSortEmail()}>
                    {SortOrder == "asc" ? (
                      <ArrowDown
                        className=" cursor-pointer"
                        strokeWidth={2}
                        size={15}
                        color={SortBy == "email" ? "#69C920" : "gray"}
                      />
                    ) : (
                      <ArrowUp
                        className=" cursor-pointer"
                        strokeWidth={2}
                        size={15}
                        color={SortBy == "email" ? "#69C920" : "gray"}
                      />
                    )}
                  </div>
                </div>
              </th>

              <th className=" w-[110px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Bill Rate Range</div>
              </th>
              <th className=" w-[110px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Total Invoice Amount</div>
              </th>
              <th className=" w-[110px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Total Hours Worked</div>
              </th>
              <th className=" w-[110px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Full-Time Bill Rate</div>
              </th>
              <th className=" w-[150px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Billing Status</div>
              </th>

              <th className=" w-[110px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Don't Auto Bill</div>
              </th>
              <th className=" w-[110px] font-medium text-[12px] border-r-2 border-b-2">
                <div>How We Bill?</div>
              </th>
              <th className=" w-[150px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Payment Method</div>
              </th>
              <th className=" w-[170px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Who to CC on Incvoices</div>
              </th>
              <th className=" w-[170px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Net Terms Delay</div>
              </th>
              <th className=" w-[200px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Detail</div>
              </th>
              <th className=" w-[200px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Rate Increase Notes</div>
              </th>
              <th className=" w-[110px] font-medium text-[12px] border-r-2 border-b-2">
                <div>CSM</div>
              </th>
              <th className=" w-[150px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Billing Period</div>
              </th>
              <th className=" w-[150px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Stripe ID</div>
              </th>
              <th className=" w-[110px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Google Drive Folder</div>
              </th>
              <th className=" w-[310px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Hubstaff Projects</div>
              </th>
              <th className=" w-[310px] font-medium text-[12px] border-r-2 border-b-2">
                <div>Hubstaff Clients Name</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <RenderClientData
                setclientInfo={setclientInfo}
                setshowModal={setshowModal}
                item={item}
                setShowModalAgent={setShowModalAgent}
                data={data}
                setagentInfo={setagentInfo}
                selected={selected}
                setselected={setselected}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const RenderClientData = ({
  setclientInfo,
  setshowModal,
  item,
  setShowModalAgent,
  data,
  setagentInfo,
  selected,
  setselected,
}) => {
  const [show, setshow] = useState(false);
  const RemoveID = (id) => {
    const updatedData = selected.filter((item) => item !== id);
    console.log("updatedData", updatedData, id);
    setselected(updatedData);
    console.log("Remove", id);
    console.log("selected", selected);
  };
  const AddID = (id) => {
    setselected([...selected, id]);
    console.log("selected", selected);
  };

  function formatString(input) {
    return input
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  }

  return (
    <>
      <tr>
        <td className="bg-white sticky left-0 w-[140px] border-r-2 border-b-2">
          <div className=" bg-white  w-full  flex  items-center px-2 h-[50px]">
            <div
              className=" w-1/3 flex items-center justify-center"
              onClick={() =>
                selected.includes(item.id) ? RemoveID(item.id) : AddID(item.id)
              }
            >
              <CheckBox item={item.id} selected={selected} />
            </div>
            <div className=" w-1/2 flex items-center justify-center">
              {show == true ? (
                <ChevronUp
                  className="cursor-pointer duration-500 rotate-0  w-5 h-5 "
                  onClick={() => setshow(false)}
                  color="black"
                />
              ) : (
                <ChevronUp
                  className=" cursor-pointer duration-500 rotate-180  w-5 h-5  "
                  onClick={() => setshow(true)}
                  // size={0}
                  color="black"
                />
              )}
            </div>
            <div className=" w-1/2 flex items-center justify-center">
              <img
                onClick={() => [setclientInfo(item), setshowModal(true)]}
                src={editIcon}
                className=" w-[20px]  cursor-pointer"
              />
            </div>
          </div>
        </td>
        <td className=" px-2 text-[11px] bg-white  sticky shadow-[#D7E6E7_3px_0px_3px_0px] left-[110px] border-r-2 border-b-2">
          {item.client_name}
        </td>
        <td className=" px-2 text-[11px] bg-white   text-center break-all border-r-2 border-b-2">
          {item.email}
        </td>

        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {" "}
          {item.bill_rate}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.total_invoice_amount}
        </td>

        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.total_hours_worked}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.full_time_bill_rate}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.billing_status ? formatString(item.billing_status) : "-"}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.dont_auto_bill}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.how_we_bill}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2 break-all">
          {item.payment_method}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2 break-all">
          {item.who_to_cc_on_email}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {" "}
          {item.net_terms_pay_delay}
        </td>

        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.detail}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.rate_increase_notes}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.csm}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.billing_period}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          {item.stripe_customer_id}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center text-blue-400 overflow-hidden whitespace-nowrap text-ellipsis  border-r-2 border-b-2">
          <div
            onClick={() => window.open(item.google_drive_folder, "_blank")}
            className="cursor-pointer whitespace-nowrap text-ellipsis overflow-hidden px-2"
          >
            {item.google_drive_folder}
          </div>
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          <div className=" w-full flex  flex-col flex-wrap items-center justify-center gap-1 py-3">
            {item.hubstaff_projects?.map((item) => (
              <div className=" bg-[#F5FFED] rounded-full px-2 py-1 text-[#163143] ">
                {" "}
                {item.name}
              </div>
            ))}
          </div>
        </td>
        <td className=" px-2 text-[11px] bg-white text-center border-r-2 border-b-2">
          <div className=" w-full flex  flex-col flex-wrap items-center justify-center gap-1 py-3">
            {item.hubstaff_clients?.map((item) => (
              <div className=" bg-[#69C920] rounded-full px-2 py-1 text-[white] ">
                {" "}
                {item?.name}
              </div>
            ))}
          </div>
        </td>
      </tr>
      {show == false ? null : (
        <DropDownComp
          setShowModalAgent={setShowModalAgent}
          data={data}
          agentsData={item.invoices}
          setagentInfo={setagentInfo}
        />
      )}
    </>
  );
};

const DropDownComp = ({
  setShowModalAgent,
  data,
  agentsData,
  setagentInfo,
}) => {
  if (agentsData?.length == 0) {
    return null;
  }

  return (
    <div>
      <div className=" flex flex-row  w-[3344px]  ">
        <table className=" table-fixed w-full border-separate ">
          {" "}
          <thead className=" border-b-2 text-main-text">
            <tr className="">
              <th className=" sticky left-[0px]  bg-[#EBF3F4]    w-[108px] font-medium text-[12px] text-main-text border-r-2  ">
                <div className="flex flex-row     w-full h-[50px] items-center   pl-4  ">
                  <span className=" "></span>
                </div>
              </th>

              <th className=" sticky left-[108px] shadow-[#D7E6E7_3px_0px_3px_0px]  bg-[#EBF3F4]   w-[150px] font-medium text-[12px] text-main-text border-r-2 ">
                <div className="flex flex-row     w-full h-[50px] items-center  pl-4   ">
                  <span className=" ">Agent Name</span>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[197px] font-medium text-[12px] text-main-text">
                <div className="hover:cursor-pointer flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Client Standard Bill Rate</span>
                  <div className="flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[110px]   font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Agent Scheduled Bill Rate</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[110px]   font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Adjustment To Bill Rate</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[110px]   flex-wrap   font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Confirmed Agent Bill Rate</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[110px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" "># 0f Hours Worked</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>

              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[150px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Cyber Security Plan</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[220px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Details</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[150px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Rate Increase Notes</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[170px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Total Invoice Amount</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[170px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Total Hours Worked</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4]  p-4 w-[1554px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" "></span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className=" bg-[#FBFBFB]">
            {agentsData?.map((item, index) => (
              <tr
                key={index}
                className={`${
                  index === data.length - 1 ? "border-b-2" : "border-b"
                } hover:bg-gray-200 hover:cursor-pointer bg-[#FBFBFB]`}
              >
                <td className=" sticky left-[0px]  bg-[#FBFBFB] text-start font-normal text-[11px] text-main-text border-r-2 border-b-2 ">
                  {" "}
                  <div className="   w-full pl-4  h-[50px] flex items-center  ">
                    <div
                      onClick={() => [
                        setagentInfo(item),
                        setShowModalAgent(true),
                      ]}
                      className=" bg-[#FBFBFB]  w-full h-[53px] flex justify-end px-4 items-center"
                    >
                      <img
                        src={editIcon}
                        className=" w-[20px]  cursor-pointer"
                      />
                    </div>
                  </div>
                </td>
                <td className=" shadow-[#D7E6E7_3px_0px_3px_0px] sticky left-[108px] bg-[#FBFBFB]  text-start font-normal text-[11px] text-main-text border-r-2">
                  {" "}
                  <div className="   w-full pl-4   flex items-center ">
                    {item.user_name}
                  </div>
                </td>
                <td className="p-4 border-r-2  text-center font-normal text-[11px] text-main-text">
                  {item.bill_rate && "$ "}
                  {item.bill_rate}
                </td>
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.scheduled_bill_rate ? item.scheduled_bill_rate : "-"}
                </td>
                <td className="p-4 border-r-2   bg-opacity-90  text-center font-normal text-[11px] text-main-text">
                  {item.adjustment_to_bill_rate != null && "$"}

                  {item.adjustment_to_bill_rate != null
                    ? item?.adjustment_to_bill_rate
                    : "-"}
                </td>
                <td className="p-4 border-r-2 bg-[#F5FFED] text-center font-normal text-[11px] text-black-400">
                  {" $"}
                  {item.actual_bill_rate}
                </td>
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.hours_worked}
                </td>
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.cyber_security_plan}
                </td>{" "}
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.detail}
                </td>{" "}
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.rate_increase_notes}
                </td>{" "}
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.invoice_amount}
                </td>{" "}
                <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                  {" "}
                  {item.total_hours_worked}
                </td>{" "}
                <td className="p-4 text-center font-normal text-[11px] text-main-text ">
                  {" "}
                  {}
                </td>{" "}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const CheckBox = ({ item, selected }) => {
  return (
    <div
      className={`${
        selected.includes(item)
          ? " bg-[#69C920]"
          : " bg-white border-2   hover:bg-opacity-10 hover:border-slate-300"
      }  rounded h-4 w-4  flex items-center justify-center cursor-pointer`}
    >
      {selected.includes(item) ? <CheckIcon color="white" /> : null}
    </div>
  );
};

const CheckBoxSelectAll = ({
  selected,
  setselected,
  data,
  selectAll,
  setselectAll,
}) => {
  let temp = data?.map((item) => item.id);
  return (
    <div
      onClick={() =>
        selected.length == data?.length
          ? [setselected([]), setselectAll(false)]
          : [setselected(temp), setselectAll(true)]
      }
      className={`${
        selectAll == true
          ? " bg-[#69C920]"
          : " bg-white border-2  hover:bg-opacity-10 hover:border-slate-300"
      }  rounded h-4 w-4  flex items-center justify-center cursor-pointer`}
    >
      {selectAll == true ? <CheckIcon color="white" /> : null}
    </div>
  );
};
