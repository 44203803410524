import Modal from "react-modal";
import { Button } from "../ui/button.tsx";
import { useEffect, useState } from "react";
import { X } from "lucide-react";
import { ChevronDown } from "lucide-react";
import toast from "react-hot-toast";
import axios from "axios";
import AppContext from "../../context/AppContext.js";
import { useContext } from "react";
import { Skeleton } from "../ui/skeleton.tsx";

export default function FinalizeInvoicesModal({
  showFinalizeInvoicesModal,
  setshowFinalizeInvoicesModal,
  selected,
  setloading,
  getData,
  selectAll,
  loading,
}) {
  const customStyles2 = {
    content: {
      top: "auto",
      left: "auto",
      right: "0%",
      bottom: "auto",
      outline: "none",
    },
  };
  const {
    invoiceReportingClientFilter,
    invoiceReportingStatusFilter,
    invoiceReportingEmailFilter,
    invoiceReportingAutoBillFilter,
    invoiceReportingNetTermFilter,
    invoiceReportingHowWeBillFilter,
    startingDateFilter,
    endingDateFilter,
    ApplyFilterInvoiceReporting,
  } = useContext(AppContext);

  const FinalizeInvoice = async () => {
    try {
      setloading(true);

      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
          
        },
      };
      const body = {
        ids: selectAll == true ? [] : selected,
      };

      console.log("body", body);

      const selectedClients =
        invoiceReportingClientFilter.length > 0
          ? invoiceReportingClientFilter.map((item) => `&clients=${item}`)
          : [];

      const selectedStatus =
        invoiceReportingStatusFilter.length > 0
          ? invoiceReportingStatusFilter.map((item) => `&status=${item}`)
          : [];

      const selectedEmail =
        invoiceReportingEmailFilter.length > 0
          ? invoiceReportingEmailFilter.map((item) => `&email=${item}`)
          : [];

      const selectedAutoBill =
        invoiceReportingAutoBillFilter.length > 0
          ? invoiceReportingAutoBillFilter.map((item) => `&auto_bill=${item}`)
          : [];

      const selectedNetTerms =
        invoiceReportingNetTermFilter.length > 0
          ? invoiceReportingNetTermFilter.map((item) => `&net_term=${item}`)
          : [];

      const selectedHowWeBill =
        invoiceReportingHowWeBillFilter.length > 0
          ? invoiceReportingHowWeBillFilter.map(
              (item) => `&how_we_bill=${item}`
            )
          : [];

      const StartDate =
        startingDateFilter == "" ? "" : `&start_date=${startingDateFilter}`;
      const EndDate =
        endingDateFilter == "" ? "" : `&end_date=${endingDateFilter}`;

      const url = `${
        process.env.REACT_APP_BASE_URL
      }/finalize-invoice?select_all=${selectAll}${StartDate}${EndDate}${selectedClients?.join(
        ""
      )}
      ${selectedStatus?.join("")}
      ${selectedEmail?.join("")}
      ${selectedAutoBill?.join("")}${selectedNetTerms?.join(
        ""
      )}${selectedHowWeBill?.join("")}`;

      console.log("url", url);

      const res = await axios.post(url, body, config);

      console.log("res", res?.data);
      if (res.status == 200) {
        console.log("res", res);

        toast.success("Finalized Invoice");

        setshowFinalizeInvoicesModal(false);
        getData(1);
      }
    } catch (error) {
      setloading(false);
      setshowFinalizeInvoicesModal(false);

      console.log("error", error?.response);
   

      toast.error('error');
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      outline: "none",
    },
  };

  if (loading == true) {
    return (
      <Modal
        isOpen={showFinalizeInvoicesModal}
        onRequestClose={() => {
          setshowFinalizeInvoicesModal(false);
        }}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        className="fixed inset-0 flex items-center justify-center z-50 font-poppins "
        overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
        //   ariaHideApp={false}
      >
        <div className="flex relative  flex-col overflow-hidden     bg-white rounded-3xl   shadow-lg  max-h-[300px] max-w-[500px]  h-[50vh] w-[45vw]  ">
          <div className=" z-10 absolute h-full text-[20px]  text-slate-400 w-full flex items-center  justify-center">
            Billed Manually Paid
          </div>
          <Skeleton className=" z-0 h-full w-full" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={showFinalizeInvoicesModal}
      onRequestClose={() => {
        setshowFinalizeInvoicesModal(false);
      }}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      className="fixed inset-0 flex items-center justify-center z-50 font-poppins "
      overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
      //   ariaHideApp={false}
    >
      <div className="flex  flex-col  py-4 px-6   bg-white rounded-3xl   shadow-lg  max-h-[300px] max-w-[500px]  h-[50vh] w-[45vw]  ">
        <div className="flex items-end justify-end">
          <X
            className=" cursor-pointer"
            onClick={() => setshowFinalizeInvoicesModal(false)}
          />
        </div>
        <div className=" flex justify-center text-[24px] font-semibold">
          Confirmation
        </div>
        <div className=" flex justify-center text-center mt-4 text-[16px] font-normal">
          Are you sure you want to Finalize this Invoice?
        </div>

        <div
          onClick={() => FinalizeInvoice()}
          className=" my-1 mt-7 bg-[#69C920] rounded-3xl flex items-center justify-center text-[white] text-[16px] font-semibold h-[44px] cursor-pointer"
        >
          Confirm
        </div>
        <div
          onClick={() => setshowFinalizeInvoicesModal(false)}
          className="  my-1 border rounded-3xl flex items-center justify-center text-[#163143] text-[16px] font-semibold h-[44px] cursor-pointer"
        >
          Cancel
        </div>
      </div>
    </Modal>
  );
}
