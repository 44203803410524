import React, { useState } from 'react'

const ClearAll = ({className, onChange}) => {
  return (
    <button onClick={onChange}  className={`${className} text-[14px] w-[101px] border rounded-full font-semibold`}>
        Clear All
    </button>
  )
}

export default ClearAll