import { useState } from "react";

const testValue = [
  {
    name: "Ammar",
    email: "ammar1@gmail.com",
    ClientIdDb: "-",
    BillingRateRange: "12-23",

    FullTimeBillRate: "34",
    GoogleDriveFolder: "https://GoogleDrive.com",
    CConInvoices: "random@gmail.com",
    BillingMethod: "Stripe",
    DontAutoBill: "Auto-Bill",
    netTermsPayDelay: "Due upon receipt",
    PaymentMethod: "Credit Card",
    Details: "100$ rate increase",
    RareIncreaseNotes: "the rate is increased",
    csm: "Syed",
    startDate: "8/4/2022 15:56:00",
    canceledDate: "-",
    Active_Inactive: "-",
    totalInvoiceAmount: "-",
    totalHoursWorked: "-",
    ExpansionCost: "-",
    onboardingFee: "-",
    agents: [
      {
        name: "airpop",
        billRate: 123,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
      {
        name: "Baffin",
        billRate: 345,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
    ],
  },
  {
    name: "afnaan",
    email: "afnaan1@gmail.com",
    ClientIdDb: "-",
    BillingRateRange: "12-23",

    FullTimeBillRate: "34",
    GoogleDriveFolder: "https://GoogleDrive.com",
    CConInvoices: "random@gmail.com",
    BillingMethod: "Stripe",
    DontAutoBill: "Auto-Bill",
    netTermsPayDelay: "Due upon receipt",
    PaymentMethod: "Credit Card",
    Details: "100$ rate increase",
    RareIncreaseNotes: "the rate is increased",
    csm: "Syed",
    startDate: "8/4/2022 15:56:00",
    canceledDate: "-",
    Active_Inactive: "-",
    totalInvoiceAmount: "-",
    totalHoursWorked: "-",
    ExpansionCost: "-",
    onboardingFee: "-",
    agents: [
      {
        name: "Nike",
        billRate: 123,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
      {
        name: "Balanciaga",
        billRate: 345,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
    ],
  },
  {
    name: "afnaan",
    email: "afnaan1@gmail.com",
    ClientIdDb: "-",
    BillingRateRange: "12-23",

    FullTimeBillRate: "34",
    GoogleDriveFolder: "https://GoogleDrive.com",
    CConInvoices: "random@gmail.com",
    BillingMethod: "Stripe",
    DontAutoBill: "Auto-Bill",
    netTermsPayDelay: "Due upon receipt",
    PaymentMethod: "Credit Card",
    Details: "100$ rate increase",
    RareIncreaseNotes: "the rate is increased",
    csm: "Syed",
    startDate: "8/4/2022 15:56:00",
    canceledDate: "-",
    Active_Inactive: "-",
    totalInvoiceAmount: "-",
    totalHoursWorked: "-",
    ExpansionCost: "-",
    onboardingFee: "-",
    agents: [
      {
        name: "Nike",
        billRate: 123,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
      {
        name: "Balanciaga",
        billRate: 345,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
    ],
  },
  {
    name: "afnaan",
    email: "afnaan1@gmail.com",
    ClientIdDb: "-",
    BillingRateRange: "12-23",

    FullTimeBillRate: "34",
    GoogleDriveFolder: "https://GoogleDrive.com",
    CConInvoices: "random@gmail.com",
    BillingMethod: "Stripe",
    DontAutoBill: "Auto-Bill",
    netTermsPayDelay: "Due upon receipt",
    PaymentMethod: "Credit Card",
    Details: "100$ rate increase",
    RareIncreaseNotes: "the rate is increased",
    csm: "Syed",
    startDate: "8/4/2022 15:56:00",
    canceledDate: "-",
    Active_Inactive: "-",
    totalInvoiceAmount: "-",
    totalHoursWorked: "-",
    ExpansionCost: "-",
    onboardingFee: "-",
    agents: [
      {
        name: "Nike",
        billRate: 123,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
      {
        name: "Balanciaga",
        billRate: 345,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
    ],
  },
  {
    name: "afnaan",
    email: "afnaan1@gmail.com",
    ClientIdDb: "-",
    BillingRateRange: "12-23",

    FullTimeBillRate: "34",
    GoogleDriveFolder: "https://GoogleDrive.com",
    CConInvoices: "random@gmail.com",
    BillingMethod: "Stripe",
    DontAutoBill: "Auto-Bill",
    netTermsPayDelay: "Due upon receipt",
    PaymentMethod: "Credit Card",
    Details: "100$ rate increase",
    RareIncreaseNotes: "the rate is increased",
    csm: "Syed",
    startDate: "8/4/2022 15:56:00",
    canceledDate: "-",
    Active_Inactive: "-",
    totalInvoiceAmount: "-",
    totalHoursWorked: "-",
    ExpansionCost: "-",
    onboardingFee: "-",
    agents: [
      {
        name: "Nike",
        billRate: 123,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
      {
        name: "Balanciaga",
        billRate: 345,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
    ],
  },
  {
    name: "afnaan",
    email: "afnaan1@gmail.com",
    ClientIdDb: "-",
    BillingRateRange: "12-23",

    FullTimeBillRate: "34",
    GoogleDriveFolder: "https://GoogleDrive.com",
    CConInvoices: "random@gmail.com",
    BillingMethod: "Stripe",
    DontAutoBill: "Auto-Bill",
    netTermsPayDelay: "Due upon receipt",
    PaymentMethod: "Credit Card",
    Details: "100$ rate increase",
    RareIncreaseNotes: "the rate is increased",
    csm: "Syed",
    startDate: "8/4/2022 15:56:00",
    canceledDate: "-",
    Active_Inactive: "-",
    totalInvoiceAmount: "-",
    totalHoursWorked: "-",
    ExpansionCost: "-",
    onboardingFee: "-",
    agents: [
      {
        name: "Nike",
        billRate: 123,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
      {
        name: "Balanciaga",
        billRate: 345,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
    ],
  },
  {
    name: "afnaan",
    email: "afnaan1@gmail.com",
    ClientIdDb: "-",
    BillingRateRange: "12-23",

    FullTimeBillRate: "34",
    GoogleDriveFolder: "https://GoogleDrive.com",
    CConInvoices: "random@gmail.com",
    BillingMethod: "Stripe",
    DontAutoBill: "Auto-Bill",
    netTermsPayDelay: "Due upon receipt",
    PaymentMethod: "Credit Card",
    Details: "100$ rate increase",
    RareIncreaseNotes: "the rate is increased",
    csm: "Syed",
    startDate: "8/4/2022 15:56:00",
    canceledDate: "-",
    Active_Inactive: "-",
    totalInvoiceAmount: "-",
    totalHoursWorked: "-",
    ExpansionCost: "-",
    onboardingFee: "-",
    agents: [
      {
        name: "Nike",
        billRate: 123,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
      {
        name: "Balanciaga",
        billRate: 345,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
    ],
  },
  {
    name: "afnaan",
    email: "afnaan1@gmail.com",
    ClientIdDb: "-",
    BillingRateRange: "12-23",

    FullTimeBillRate: "34",
    GoogleDriveFolder: "https://GoogleDrive.com",
    CConInvoices: "random@gmail.com",
    BillingMethod: "Stripe",
    DontAutoBill: "Auto-Bill",
    netTermsPayDelay: "Due upon receipt",
    PaymentMethod: "Credit Card",
    Details: "100$ rate increase",
    RareIncreaseNotes: "the rate is increased",
    csm: "Syed",
    startDate: "8/4/2022 15:56:00",
    canceledDate: "-",
    Active_Inactive: "-",
    totalInvoiceAmount: "-",
    totalHoursWorked: "-",
    ExpansionCost: "-",
    onboardingFee: "-",
    agents: [
      {
        name: "Nike",
        billRate: 123,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
      {
        name: "Balanciaga",
        billRate: 345,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
    ],
  },
  {
    name: "afnaan",
    email: "afnaan1@gmail.com",
    ClientIdDb: "-",
    BillingRateRange: "12-23",

    FullTimeBillRate: "34",
    GoogleDriveFolder: "https://GoogleDrive.com",
    CConInvoices: "random@gmail.com",
    BillingMethod: "Stripe",
    DontAutoBill: "Auto-Bill",
    netTermsPayDelay: "Due upon receipt",
    PaymentMethod: "Credit Card",
    Details: "100$ rate increase",
    RareIncreaseNotes: "the rate is increased",
    csm: "Syed",
    startDate: "8/4/2022 15:56:00",
    canceledDate: "-",
    Active_Inactive: "-",
    totalInvoiceAmount: "-",
    totalHoursWorked: "-",
    ExpansionCost: "-",
    onboardingFee: "-",
    agents: [
      {
        name: "Nike",
        billRate: 123,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
      {
        name: "Balanciaga",
        billRate: 345,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
    ],
  },
  {
    name: "afnaan",
    email: "afnaan1@gmail.com",
    ClientIdDb: "-",
    BillingRateRange: "12-23",

    FullTimeBillRate: "34",
    GoogleDriveFolder: "https://GoogleDrive.com",
    CConInvoices: "random@gmail.com",
    BillingMethod: "Stripe",
    DontAutoBill: "Auto-Bill",
    netTermsPayDelay: "Due upon receipt",
    PaymentMethod: "Credit Card",
    Details: "100$ rate increase",
    RareIncreaseNotes: "the rate is increased",
    csm: "Syed",
    startDate: "8/4/2022 15:56:00",
    canceledDate: "-",
    Active_Inactive: "-",
    totalInvoiceAmount: "-",
    totalHoursWorked: "-",
    ExpansionCost: "-",
    onboardingFee: "-",
    agents: [
      {
        name: "Nike",
        billRate: 123,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
      {
        name: "Balanciaga",
        billRate: 345,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
    ],
  },
  {
    name: "afnaan",
    email: "afnaan1@gmail.com",
    ClientIdDb: "-",
    BillingRateRange: "12-23",

    FullTimeBillRate: "34",
    GoogleDriveFolder: "https://GoogleDrive.com",
    CConInvoices: "random@gmail.com",
    BillingMethod: "Stripe",
    DontAutoBill: "Auto-Bill",
    netTermsPayDelay: "Due upon receipt",
    PaymentMethod: "Credit Card",
    Details: "100$ rate increase",
    RareIncreaseNotes: "the rate is increased",
    csm: "Syed",
    startDate: "8/4/2022 15:56:00",
    canceledDate: "-",
    Active_Inactive: "-",
    totalInvoiceAmount: "-",
    totalHoursWorked: "-",
    ExpansionCost: "-",
    onboardingFee: "-",
    agents: [
      {
        name: "Nike",
        billRate: 123,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
      {
        name: "Balanciaga",
        billRate: 345,
        ClientStandardBillRate: 100,
        AgentScheduledBillRate: 90,
        AdjustmentToBillRate: 5,
        ConfirmedAgentBillRate: 95,
        NumberOfHoursWorked: 40,
        Commision: 300,
        Bonus: 150,
        Miscellaneous: 50,
        CyberSecurityPlan: "Basic",
      },
    ],
  },
];

const RowComp = ({ item }) => {
  const [show, setshow] = useState(true);
  return (
    <div className=" flex flex-col w-full">
      <div className=" flex flex-row">
        <div onClick={()=>setshow(!show)} className="  z-10 border w-[250px] ">edit</div>
        <div className=" z-0  border w-[250px]">{item.name}</div>
        <div className=" border w-[250px]">{item.email}</div>
        <div className=" border w-[250px]">{item.BillingRateRange}</div>
        <div className=" border w-[250px]">{item.FullTimeBillRate}</div>
        <div className=" border w-[250px]">{item.GoogleDriveFolder}</div>
        <div className=" border w-[250px]">{item.CConInvoices}</div>
        <div className=" border w-[250px]">{item.BillingMethod}</div>
        <div className=" border w-[250px]">{item.DontAutoBill}</div>
        <div className=" border w-[250px]">{item.netTermsPayDelay}</div>
        <div className=" border w-[250px]">{item.PaymentMethod}</div>
        <div className=" border w-[250px]">{item.Details}</div>
        <div className=" border w-[250px]">{item.RareIncreaseNotes}</div>
        <div className=" border w-[250px]">{item.csm}</div>
        

      </div>
      {show == false ? null : (
        <div className=" flex flex-col bg-green-300">
          <div className=" flex flex-row">
            <div className=" border w-[250px] bg-red-500 ">...</div>
            <div className="  border w-[250px]">Agent Name</div>
            <div className=" border w-[250px]">Agent billrate</div>
          </div>
          {item.agents.map((itm) => (
            <div className=" flex flex-row">
              <div className=" border w-[250px] bg-red-400 ">edit</div>
              <div className=" border w-[250px] ">{itm.name}</div>
              <div className=" border w-[250px]">{itm.billRate}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default function Test() {
  return (
   <div className="overflow-x-auto ">
      <table className=" w-[2000px] border-collapse">
        <thead>
          <tr>
            <th className="sticky left-0 w-[100px] bg-gray-100 border border-gray-300 px-4 py-2">Header 1</th>
            <th className="border sticky left-[100px] border-gray-300 px-4 w-[100px] py-2">Header 2</th>
            <th className="border  border-gray-300 px-4 w-[300px] py-2">Header 3</th>
            <th className="border border-gray-300 px-4 w-[300px] py-2">Header 4</th>
            <th className="border border-gray-300 px-4 w-[300px] py-2">Header 5</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 1, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 1, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 1, Cagasgagagagsagasgasgasgagol 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 1, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 1, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
          <tr>
            <td className="sticky left-0 bg-gray-100 border border-gray-300 px-4 py-2">Row 2, Col 1</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 2</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 3</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 4</td>
            <td className="border border-gray-300 px-4 py-2">Row 2, Col 5</td>
          </tr>
        </tbody>
      </table>
    
    </div>
  );
}
