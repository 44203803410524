import Modal from "react-modal";
import { Button } from "../ui/button.tsx";
import { useEffect, useState, useRef } from "react";
import { ChevronDown, Star } from "lucide-react";
import { X } from "lucide-react";
import axios from "axios";
import toast from "react-hot-toast";
import { Skeleton } from "../ui/skeleton.tsx";




const DropDownCompMultiselect = ({ value, setvalue, dropdowndata }) => {
  const [dropdownopen, setdropdownopen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setdropdownopen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Function to handle selection from the dropdown
  const handleSelect = (item) => {
    // If the item is already selected, remove it from the value array
    console.log("value", value);
    console.log("typeof", typeof value);

    if (value?.length < 3) {
      // If less than 3 items are selected, add the item to the value array
      setvalue([...value, item]);
    } else {
      // setvalue(value?.filter((v) => v !== item));
      let a = [...value];
      a.shift();
      a.push(item);
      setvalue(a);
    }
  };

  return (
    <div
      ref={dropdownRef}
      className="relative cursor-pointer flex flex-col gap-1 justify-center"
    >
      <div
        onClick={() => setdropdownopen(!dropdownopen)}
        className="bg-[#FBFBFB] text-[14px] font-normal flex items-center justify-between border-2 rounded-full w-full px-3 py-2"
      >
        {/* Display selected values separated by commas */}

        <div className=" w-[80%] text-[12px]"> {value?.join(", ")}</div>

        {dropdownopen ? (
          <ChevronDown className="duration-500 rotate-180" />
        ) : (
          <ChevronDown className="duration-500 rotate-0" />
        )}
      </div>
      {dropdownopen && (
        <div className="overflow-hidden flex-col absolute top-[45px] z-50 flex justify-center border rounded-b-2xl w-full bg-white">
          <div className="w-full max-h-[155px] overflow-y-scroll">
            {dropdowndata.map((item) => (
              <div
                key={item}
                onClick={() => handleSelect(item)}
                className={`w-full border-b px-4 py-2 hover:bg-slate-100 ${
                  value.includes(item) ? "bg-slate-200" : ""
                }`}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const DropDownComp = ({ header, value, setvalue, dropdowndata }) => {
  const [dropdownopen, setdropdownopen] = useState(false);
  const [serachQuery, setserachQuery] = useState("");
  const dropdownRef = useRef(null);

  function formatString(input) {
    return input
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setdropdownopen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      className=" relative cursor-pointer  flex flex-col  gap-1 justify-center"
    >
      <div
        onClick={() => setdropdownopen(!dropdownopen)}
        className="  bg-[#FBFBFB] text-[12px] font-normal flex items-center justify-between border-2 rounded-full w-full px-3  py-2 "
      >
        {formatString(value)}
        {dropdownopen == false ? (
          <ChevronDown className="duration-500 rotate-0" />
        ) : (
          <ChevronDown className="duration-500 rotate-180" />
        )}
      </div>
      {dropdownopen && (
        <div className=" z-20 overflow-hidden flex-col  absolute top-[45px] flex justify-center   border rounded-b-2xl rounded w-full bg-white">
          <div className=" w-full max-h-[145px]     overflow-y-scroll">
            {dropdowndata.map((item) => (
              <div
                onClick={() => [setvalue(item), setdropdownopen(false)]}
                className=" w-full border-b px-4 py-2 hover:bg-slate-100 text-[14px]"
              >
                {formatString(item)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default function EditClientInvoiceModal({
  showModal,
  setshowModal,
  clientInfo,
  setclientInfo,
  showArchiveClientModal,
  setshowArchiveClientModal,
  getData,
}) {
  const customStyles2 = {
    content: {
      top: "auto",
      left: "auto",
      right: "0%",
      bottom: "auto",
      outline: "none",
    },
  };

  const [companyName, setcompanyName] = useState("-");
  const [EmailAddress, setEmailAddress] = useState("-");
  const [HubstaffProjectName, setHubstaffProjectName] = useState();
  const [BillingRateRange, setBillingRateRange] = useState();
  const [TotalInvoiceAmount, setTotalInvoiceAmount] = useState();
  const [TotalHoursWorked, setTotalHoursWorked] = useState();
  const [FullTimeBillRate, setFullTimeBillRate] = useState("-");
  const [BillingStatus, setBillingStatus] = useState();
  const [DonTAutoBill, setDonTAutoBill] = useState();
  const [HowWeBill, setHowWeBill] = useState("-");
  const [PaymentMethod, setPaymentMethod] = useState();
  const [WhoToCC, setWhoToCC] = useState("-");
  const [NetTermsPayDelay, setNetTermsPayDelay] = useState();
  const [Detail, setDetail] = useState();
  const [RateIncreaseNotes, setRateIncreaseNotes] = useState("-");
  const [CSM, setCSM] = useState();
  const [BillingPeriod, setBillingPeriod] = useState();
  const [GoogleDriveFolder, setGoogleDriveFolder] = useState("-");

  useEffect(() => {
    setcompanyName(clientInfo?.client_name);
    setEmailAddress(clientInfo?.email);
    setHubstaffProjectName(clientInfo?.hubstaff_projects);
    setFullTimeBillRate(clientInfo?.full_time_bill_rate);
    setBillingStatus(clientInfo?.billing_status);
    setGoogleDriveFolder(clientInfo?.google_drive_folder);
    setWhoToCC(clientInfo?.who_to_cc_on_email);
    setHowWeBill(clientInfo?.how_we_bill);
    setDonTAutoBill(clientInfo?.dont_auto_bill);
    setNetTermsPayDelay(clientInfo?.net_terms_pay_delay);
    clientInfo?.bill_rate
    ? setBillingRateRange(clientInfo.bill_rate.split(",").map(Number))
    : setBillingRateRange([]);
    setPaymentMethod(clientInfo?.payment_method);
    setDetail(clientInfo?.detail);
    setRateIncreaseNotes(clientInfo?.rate_increase_notes);
    setCSM(clientInfo?.csm);
    setBillingPeriod(clientInfo?.billing_period);
    setTotalInvoiceAmount(clientInfo?.total_invoice_amount);
    setTotalHoursWorked(clientInfo?.total_hours_worked);

    console.log("clientInfo", clientInfo);
  }, [showModal]);

  function formatString(input) {
    return input
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  }

  const [loading, setloading] = useState(false);

  const EditClientInvoice = async () => {
    try {
      if (BillingRateRange.length < 3) {
        toast.error(" Select at least 3 items in bill rate range");
        return;
      }
      
      setloading(true);

      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {
        bill_rate: BillingRateRange,
        google_drive_folder: GoogleDriveFolder,
        who_to_cc_on_email: WhoToCC,
        how_we_bill: HowWeBill,
        dont_auto_bill: DonTAutoBill,
        net_terms_pay_delay: NetTermsPayDelay,
        payment_method: PaymentMethod,
        detail: Detail,
        rate_increase_notes: RateIncreaseNotes,
        csm: CSM,
        billing_status: BillingStatus,
      };

      console.log("body", body);

      const url = `${process.env.REACT_APP_BASE_URL}/update-client-invoice?id=${clientInfo?.id}`;

      console.log("url", url);

      const res = await axios.post(url, body, config);

      console.log("res", res.data);
      if (res.status == 200) {
        console.log("res", res);

        setshowModal(false);
        getData(1);
        setloading(false);
        toast.success("Client Updated Successfully");
      }
    } catch (error) {
      setloading(false);

      console.log("error", error?.response);

      toast.error("error");
    }
  };

  if (loading == true) {
    return (
      <Modal
        isOpen={showModal}
        onRequestClose={() => {
          setshowModal(false);
        }}
        style={customStyles2}
        shouldCloseOnOverlayClick={false}
        className="fixed inset-0 flex items-center justify-center z-10 font-poppins"
        overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
      >
        <div className="flex  flex-col justify-between   bg-white relative  rounded shadow-lg  h-[100vh] w-[45vw] max-w-[800px] ">
          <div className=" flex w-full h-full items-center justify-center absolute text-[40px] text-slate-400">
            Updating Info
          </div>
          <Skeleton className=" w-full h-full" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => {
        setshowModal(false);
      }}
      style={customStyles2}
      shouldCloseOnOverlayClick={false}
      className="fixed inset-0 flex items-center justify-center z-50 font-poppins"
      overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
      //   ariaHideApp={false}
    >
      <div className="flex  flex-col justify-between   bg-white  rounded shadow-lg  h-[100vh] w-[45vw] max-w-[800px] ">
        <div className=" w-full h-[100vh] ">
          <div className="  h-[144px] ">
            <h2 className="text-2xl flex items-center justify-between font-semibold text-main-text px-[20px]   border-b-2">
              <div className=" pl-2 py-4"> Edit Client Invoice Settings</div>
              <div>
                <X
                  className=" cursor-pointer"
                  onClick={() => {
                    setshowModal(false);
                  }}
                />
              </div>
            </h2>
            <div className=" flex flex-row px-[20px] border-b-2 ">
              {/* <div className=" w-[50%]  flex flex-row py-4  ">
                <Button
                  onClick={() => {
                    // setshowModal(false);
                    setshowArchiveClientModal(true);
                  }}
                  className="py-3 px-8 mx-[10px] w-[210px] bg-white border text-[#163143] hover:bg-gray-100 font-normal text-base text-center rounded-3xl"
                >
                  Archive Client
                </Button>
              </div> */}
              <div className=" w-full flex flex-row justify-end py-4 ">
                <Button
                  onClick={() => {
                    setshowModal(false);
                  }}
                  className="py-3 px-8 mx-[10px] w-[130px] bg-white border text-[#163143] hover:bg-gray-100 font-normal text-base text-center rounded-3xl"
                >
                  Cancel
                </Button>
                <Button
                  variant={"secondary"}
                  onClick={() => {
                    // putData(seteditTeamLeadModal);
                    EditClientInvoice();
                  }}
                  className="py-3 bg-[#69C920]  px-8 mx-[10px] w-[130px] bg-green-1 border text-[white]  font-normal text-base text-center rounded-3xl"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          <div className=" px-[20px] flex-col w-full overflow-y-scroll  h-full pb-[174px]   ">
            <div className=" px-4 mt-4">
              <div>
                <div className=" flex flex-row font-semibold text-[14px]">
                  Company Name <div className=" text-red-500">*</div>
                </div>
                <input
                  disabled
                  className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                  value={companyName}
                  onChange={(e) => setcompanyName(e.target.value)}
                />
              </div>
            </div>
            <div className=" px-4 mt-4">
              <div>
                <div className="flex flex-row font-semibold text-[14px]">
                  Email Address
                  <div className=" text-red-500">*</div>
                </div>
                <input
                  disabled
                  className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                  value={EmailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </div>
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Hubstaff Project Name <div className=" text-red-500">*</div>
              </div>
              <input
                disabled
                value={HubstaffProjectName?.map((item) => item.name)?.join(
                  ", "
                )}
                onChange={(e) => setHubstaffProjectName(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>
            <div className=" px-4  mt-4">
              <div className="flex font-semibold text-[14px]">
                Billing Rate Range <div className=" text-red-500">*</div>
              </div>

              {/* <input
                disabled
                value={BillingRateRange}
                onChange={(e) => setBillingRateRange(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              /> */}

              <DropDownCompMultiselect
                value={BillingRateRange}
                setvalue={setBillingRateRange}
                dropdowndata={[
                  5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                ]}
              />
            </div>

            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Total Invoice Amount <div className=" text-red-500">*</div>
              </div>
              <input
                disabled
                value={TotalInvoiceAmount}
                onChange={(e) => setTotalInvoiceAmount(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Total Hours Worked
              </div>
              <input
                disabled
                value={TotalHoursWorked}
                onChange={(e) => setTotalHoursWorked(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Full Time Bill Rate <div className=" text-red-500">*</div>
              </div>
              <input
                disabled
                value={FullTimeBillRate}
                onChange={(e) => setFullTimeBillRate(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>
            <div className=" px-4  mt-4">
              <div className="font-semibold flex text-[14px]">
                Billing Status<div className=" text-red-500">*</div>
              </div>
              <DropDownComp
                value={BillingStatus}
                setvalue={setBillingStatus}
                dropdowndata={[
                  "invoice_to_bill",
                  "invoice_to_send",
                  "pending_manual_review",
                ]}
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                DonT Auto Bill<div className=" text-red-500">*</div>
              </div>
              <DropDownComp
                value={DonTAutoBill}
                setvalue={setDonTAutoBill}
                dropdowndata={["Auto Bill", `Don't Auto Bill`, "Manual"]}
              />
            </div>
            <div className=" flex flex-col w-full px-4  mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                How We Bill? <div className=" text-red-500">*</div>
              </div>
              <DropDownComp
                value={HowWeBill}
                setvalue={setHowWeBill}
                dropdowndata={["Bill.com", "Stripe", "Manual"]}
              />
            </div>

            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Payment Method <div className=" text-red-500">*</div>
              </div>
              <DropDownComp
                value={PaymentMethod}
                setvalue={setPaymentMethod}
                dropdowndata={["Prepaid", "ACH", "Bill.com", `Credit Card`]}
              />
            </div>

            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Who To CC on Invoices
              </div>
              <input
                value={WhoToCC}
                onChange={(e) => setWhoToCC(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Net Terms/Pay Delay
              </div>
              <input
                value={NetTermsPayDelay}
                onChange={(e) => setNetTermsPayDelay(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>

            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Details <div className=" text-red-500">*</div>
              </div>
              <input
                value={Detail}
                onChange={(e) => setDetail(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>

            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Rate Increase Notes <div className=" text-red-500">*</div>
              </div>
              <input
                value={RateIncreaseNotes}
                onChange={(e) => setRateIncreaseNotes(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>

            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                CSM <div className=" text-red-500">*</div>
              </div>
              <input
                value={CSM}
                onChange={(e) => setCSM(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>

            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Billing Period
                {/* <div className=" text-red-500">*</div> */}
              </div>
              <input
                disabled
                value={BillingPeriod}
                onChange={(e) => setBillingPeriod(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>

            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Google Drive Folder
                {/* <div className=" text-red-500">*</div> */}
              </div>
              <input
                value={GoogleDriveFolder}
                onChange={(e) => setGoogleDriveFolder(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
