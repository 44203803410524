import Modal from "react-modal";
import { Button } from "../ui/button.tsx";
import { useEffect, useState } from "react";
import { X } from "lucide-react";
import { ChevronDown } from "lucide-react";
import toast from "react-hot-toast";
import axios from "axios";
import AppContext from "../../context/AppContext.js";
import { useContext } from "react";
import { Skeleton } from "../ui/skeleton.tsx";

export default function MarkAsCompleted({
  showmarkAsCompletedModal,
  setshowmarkAsCompletedModal,
  selected,
  setloading,
  getData,
  selectAll,
  loading,
}) {
 
  const { ProcessBillingSelectedClientFilter } = useContext(AppContext);
 
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      outline: "none",
    },
  };

  const MarkAsCompleted = async () => {
    try {
      setloading(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {
        client_ids: selectAll == true ? [] : selected,
        billing_status: "pending_manual_review",
        status:'Mark As Completed'
      };

      console.log("body", body);
      const selectedClients =
      ProcessBillingSelectedClientFilter.length > 0
        ? ProcessBillingSelectedClientFilter?.map(
            (item) => `&filter_ids=${item}`
          )
        : [];
  
      const url = `${
        process.env.REACT_APP_BASE_URL
      }/invoice-status?select_all=${selectAll}${selectedClients?.join("")}`;

      const res = await axios.post(url, body, config);

      console.log("res", res.data);
      if (res.status == 200) {
        console.log("res", res);

        toast.success("Marked As Completed");

        setshowmarkAsCompletedModal(false);
        getData(1);
      }
    } catch (error) {
      setloading(false);
      setshowmarkAsCompletedModal(false);

      console.log("error", error?.response);
     

      toast.error(error?.response?.data?.detail);
    }
  };

  return (
    <Modal
      isOpen={showmarkAsCompletedModal}
      onRequestClose={() => {
        setshowmarkAsCompletedModal(false);
      }}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      className="fixed inset-0 flex items-center justify-center z-50 font-poppins "
      overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
      //   ariaHideApp={false}
    >
      <div className="flex  flex-col  py-4 px-6   bg-white rounded-3xl   shadow-lg  max-h-[300px] max-w-[500px]  h-[50vh] w-[45vw]  ">
        <div className="flex items-end justify-end">
          <X className=" cursor-pointer" onClick={()=>setshowmarkAsCompletedModal(false)} />
        </div>
        <div className=" flex justify-center text-[24px] font-semibold">
        Confirmation
        </div>
        <div className=" flex justify-center text-center mt-4 text-[16px] font-normal">
        Are you sure you want to mark this as Completed?
        </div>
        <div className=" flex justify-center text-center mt-4 text-[16px] font-normal">
       Marking as Completed will not change payment status to Received.
        </div>

        <div
          onClick={() => [MarkAsCompleted()]}
          className=" my-1 mt-7 bg-[#69C920] rounded-3xl flex items-center justify-center text-[white] text-[16px] font-semibold h-[44px] cursor-pointer"
        >
              Confirm
        </div>
        <div
          onClick={() => setshowmarkAsCompletedModal(false)}
          className="  my-1 border rounded-3xl flex items-center justify-center text-[#163143] text-[16px] font-semibold h-[44px] cursor-pointer"
        >
           Cancel
        </div>
      </div>
    </Modal>
  );
}
