import { DatePickerWithRange } from "../DateFilter.tsx";
import filterIcon from "../../assests/index.jpeg";
import { useEffect, useState } from "react";
import { useContext } from "react";
import AppContext from "../../context/AppContext.js";
import { useFiltersStore } from "../useFilterStore.tsx";
import ClientFilter from "../ClientFilter.js";
export default function ApplyFilter() {
  const {
    startingDateFilter,
    setStartingDateFilter,
    endingDateFilter,
    setEndingDateFilter,
    FilterApplied,
    setFilterApplied,
  } = useContext(AppContext);

  const [showFilters, setshowFilters] = useState(false);
  const [ApplyEnabled, setApplyEnabled] = useState(false);

  useEffect(() => {
    if (startingDateFilter !== "" && endingDateFilter !== "") {
      setApplyEnabled(true);
    } else {
      setApplyEnabled(false);
    }
  }, [startingDateFilter, endingDateFilter]);

  return (
    <div className="  flex">
      <ClientFilter />
    </div>
  );
}
