"use client";

import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import date_icon from "../assests/date-icon.svg";
import { DateRange } from "react-day-picker";
import { cn } from "./lib/utils.ts";
import { Button } from "../components/ui/button.tsx";
import { Calendar } from "../components/ui/calendar.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover.tsx";
import { ChevronUp } from "lucide-react";
import { TableStore } from "../components/TableStore.ts";

import { useContext } from "react";
import AppContext from "../context/AppContext";

export function DatePickerWithRange({
  className,
}: React.HTMLAttributes<HTMLDivElement>) {
  const {
    setStartingDateFilter,
    setEndingDateFilter,
    startingDateFilter,
    endingDateFilter,
    setIsDateOpen,
    isDateOpen,
    CurrntActiveTab,
    date,
    setDate
  } = useContext(AppContext);
  // const {
  //   addstartingdate,
  //   addendingdate,
  //   setIsDateOpen,
  //   setIsCSMOpen,
  //   setIsCSTeamManagerOpen,
  //   setIsDeptOpen,
  //   setIsOpsManager,
  //   setIsTeamLeadsOpen,
  //   setIsClientOpen,
  //   isDateOpen,
  //   setIsDate,
  // } = useFiltersStore();

  const { resetAllPageCounter } = TableStore();

  // const [date, setDate] = React.useState<DateRange | undefined>(undefined);

  const [isPreset, setIsPreset] = useState(false);

  const handleDateSelect = (selectedDate: any) => {
    // console.log(selectedDate);
    if (selectedDate) {
      // const { from, to } = selectedDate;
      console.log("selectedDate", selectedDate);

      setDate(selectedDate);
      selectedDate.from && setStartingDateFilter(format(selectedDate.from, "yyyy-MM-dd"))
      selectedDate.to && setEndingDateFilter(format(selectedDate.to, "yyyy-MM-dd"));
    }
  };

  const handleDate = () => {
    // console.log(date);
    // setIsPreset(false);
    if (date && date.from && date.to) {
      setStartingDateFilter(format(date.from, "yyyy-MM-dd"));
      setEndingDateFilter(format(date.to, "yyyy-MM-dd"));
    }

    // addstartingdate(format(from, "yyyy-MM-dd"));
    // addendingdate(format(to, "yyyy-MM-dd"));
  };

 
  
  const clearDate = () => {
    setDate(undefined);
    setStartingDateFilter("");
    setEndingDateFilter("");
  };

  const handleThisMonthPreSet = () => {
    const today = new Date(); // Get the current date
    const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const dateRange: DateRange = {
      from: firstDate,
      to: lastDate,
    };

    handleDateSelect(dateRange);
    handleDate();
    setIsPreset(true);

    // return { firstDate, lastDate };
  };

  const handleLastMonthPreSet = () => {
    const today = new Date(); // Get the current date
    const firstDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDate = new Date(today.getFullYear(), today.getMonth(), 0);

    const dateRange: DateRange = {
      from: firstDate,
      to: lastDate,
    };

    setDate(dateRange);
    handleDateSelect(dateRange);
    handleDate();
    setIsPreset(true);
    // setIsPreset(false);
    // setIsDateOpen(false);
  };

  const handleLast30DaysPreSet = () => {
    const today = new Date();
    const endOfWeek = new Date(today);

    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - 30); // Subtract 6 days to get the start of the week

    const dateRange: DateRange = {
      from: startOfWeek,
      to: endOfWeek,
    };
    handleDateSelect(dateRange);
    handleDate();
    setIsPreset(true);
  };

  const handleLast7DaysPreSet = () => {
    const today = new Date();
    const endOfWeek = new Date(today);

    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - 6); // Subtract 6 days to get the start of the week

    const dateRange: DateRange = {
      from: startOfWeek,
      to: endOfWeek,
    };
    handleDateSelect(dateRange);
    handleDate();
    setIsPreset(true);
  };

  const handleMonthChange = () => {
    setIsPreset(false);
  };

 

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover
        onOpenChange={() => {
          setIsDateOpen(!isDateOpen);
        }}
      >
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              " flex w-full bg-white  justify-center space-x-2 px-3 py-2 text-left font-normal rounded-full",
              !date
            )}
          >
            <div className="flex items-center text-xs bg-white text-black">
              <img className="mr-2 h-4 w-4" src={date_icon} alt="" />

              {date?.from ? (
                date.to ? (
                  <>
                    <span className="text-xs">
                      {format(date.from, "MM/dd/yy")}
                    </span>{" "}
                    -
                    <span className="text-xs">
                      {format(date.to, "MM/dd/yy")}
                    </span>
                  </>
                ) : (
                  format(date.from, "dd/MM/yy")
                )
              ) : (
                <span className="text-xs">Pick a Date</span>
              )}
            </div>
            {isDateOpen ? (
              <ChevronUp className="duration-500 rotate-0" color="black" />
            ) : (
              <ChevronUp className="duration-500 rotate-180" color="black" />
            )}
          </Button>
        </PopoverTrigger>
        {isDateOpen && (
          <PopoverContent
            className="w-auto border-0 p-0 flex justify-items-end rounded-xl"
            align="center"
          >
            <Calendar
              className="border-r-2"
              captionLayout="dropdown-buttons"
              fromYear={2015}
              toYear={2025}
              mode="range"
              month={isPreset ? date?.from : undefined}
              defaultMonth={date?.from}
              selected={date}
              onSelect={handleDateSelect}
              onMonthChange={handleMonthChange}
              numberOfMonths={1}
            />
            <div className="flex flex-col justify-between p-4 bg-white border-2 border-gray-200">
              <div className="grid grid-cols-2 gap-[15px]">
                <button
                  onClick={clearDate}
                  className={cn(
                    "text-sm py-2 px-4 rounded-full text-center font-normal border bg-white text-main-text text-black"
                  )}
                >
                  All Time
                </button>
                <button
                  onClick={handleLast7DaysPreSet}
                  className={cn(
                    "text-sm  py-2 px-4 rounded-full text-center font-normal border bg-white text-main-text text-black"
                  )}
                >
                  Last 7 Days
                </button>
                <button
                  onClick={handleLast30DaysPreSet}
                  className={cn(
                    "text-sm  py-2 px-4 rounded-full text-center font-normal border bg-white text-main-text text-black"
                  )}
                >
                  Last 30 Days
                </button>
                <button
                  onClick={handleThisMonthPreSet}
                  className={cn(
                    "text-sm  py-2 px-4 rounded-full text-center font-normal border bg-white text-main-text text-black"
                  )}
                >
                  This Month
                </button>
                <button
                  onClick={handleLastMonthPreSet}
                  className={cn(
                    "text-sm  py-2 px-4 rounded-full text-center font-normal border bg-white text-main-text text-black"
                  )}
                >
                  Last Month
                </button>
                <button
                  className={cn(
                    "text-sm  py-2 px-4 rounded-full text-center font-normal border bg-white text-main-text text-black"
                  )}
                >
                  Custom
                </button>
              </div>
              <div className="flex space-x-2 justify-between">
                <button
                  onClick={() => {
                    resetAllPageCounter();
                    clearDate();
                  }}
                  className={cn(
                    "text-sm py-2 px-4 w-full rounded-full text-center font-normal border bg-white text-main-text text-black"
                  )}
                >
                  Cancel
                </button>
                <button
                  id="apply"
                  className={cn(
                    "text-sm py-2 px-4 w-full rounded-full text-center font-normal bg-[#69C920] text-white "
                  )}
                  onClick={() => {
                    resetAllPageCounter();
                    handleDate();
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </PopoverContent>
        )}
      </Popover>
    </div>
  );
}
