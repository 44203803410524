import { useState } from "react";
import editIcon from "../../assests/editIcon.jpeg";
import { CheckIcon, ChevronDown, ChevronUp, X } from "lucide-react";
import AppContext from "../../context/AppContext";
import { useContext } from "react";
import DownloadCSVButton from "../ui/DownloadCSVButton";
import { DatePickerWithRange } from "../Filters/DateFilter.tsx";
import ClientFilter from "../Filters/ClientsFilter.js";
import ClearFilter from "../Filters/ClearFilter.js";
import FetchFilter from "../Filters/FetchFilter.js";
import ClientFilterProcessBilling from "../ClientFilterProcessBilling.js";
import ProgressBarLoader from "./ProgressBarLoader.js";
import Date_ClientFilter from "./Date_ClientFilter.js";
import GenerateInvoiceConfirmationModal from "../BillKey/GenerateInvoiceConfirmationModal.js";
import MoveToManualReviewConfirmationModal from "../BillKey/MoveToManualReviewConfirmationModal.js";
import axios from "axios";
import { useEffect } from "react";
import { Skeleton } from "../ui/skeleton.tsx";
import Pagination2 from "../ui/Pagination2.js";
import EditClientInvoiceModal from "./EditClientInvoiceModal.js";
import ArchiveClientsModal from "../BillKey/ArchiveClientsModal.js";
import EditAgentInvoiceModal from "./EditAgentInvoiceModal.js";
import toast from "react-hot-toast";
import ProcessBillingTable from "./ProcessBillingTable.js";

export default function AllInvoiceTable({ ShowTableData, setShowTableData }) {
  const {
    startingDateFilter,
    endingDateFilter,
    ApplyfilterProcessBilling,
    ProcessBillingSelectedClientFilter,
    SortBy,
    setSortBy,
    SortOrder,
    setSortOrder,
    LogOutUser,
  } = useContext(AppContext);
  const [showModal, setshowModal] = useState(false);
  const [clientInfo, setclientInfo] = useState();

  const [selected, setselected] = useState([]);

  const [fetch, setFetch] = useState(true);
  const [ShowBarloading, setShowBarloading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [showGenerateInvoiceModal, setshowGenerateInvoiceModal] =
    useState(false);

  const [showMoveToManualModal, setshowMoveToManualModal] = useState(false);
  const [showArchiveClientModal, setshowArchiveClientModal] = useState(false);
  const [ShowModalAgent, setShowModalAgent] = useState(false);

  const [agentInfo, setagentInfo] = useState("");

  const [loading, setloading] = useState(true);
  const [currentpage, setcurrentpage] = useState(0);
  const [totalPages, settotalPages] = useState(0);
  const [data, setdata] = useState([]);

  const [selectAll, setselectAll] = useState(false);

  const getData = async (page) => {
    try {
      setselected([]);
      setloading(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedClients =
        ProcessBillingSelectedClientFilter.length > 0
          ? ProcessBillingSelectedClientFilter?.map(
              (item) => `&filter_ids=${item}`
            )
          : [];
      const url = ` ${
        process.env.REACT_APP_BASE_URL
      }/all-invoices?page=${page}&&per_page=${30}&sort_by=${SortBy}&sort_order=${SortOrder}${selectedClients?.join(
        ""
      )}`;

      const res = await axios.get(url, config);

      if (res.status == 200) {
        console.log("res.status", res.status);
        console.log("res.data", res.data.grouped_invoices);
        setloading(false);

        setdata(res.data.grouped_invoices);
        settotalPages(res?.data?.pagination?.total_pages);
        setcurrentpage(res?.data?.pagination?.current_page);
        setloading(false);
        if (res.data.grouped_invoices.length > 0) {
          setFetch(true);
        } else {
          setFetch(false);
        }
      } else {
        alert("error");
        console.log("res", res);
      }
    } catch (error) {
      console.log("error", error.response);
      if (error?.response?.status == 401) {
        LogOutUser();
      }
      setloading(false);
      setdata([]);
      setFetch(false);
    }
  };

  const getCSVData = async () => {
    try {
      const billKeySelectedClientQueryParam = new URLSearchParams();

      if (ProcessBillingSelectedClientFilter.length > 0) {
        ProcessBillingSelectedClientFilter.forEach((client) => {
          billKeySelectedClientQueryParam.append("filter_ids", client);
        });
      }

      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${process.env.REACT_APP_BASE_URL}/invoices-download?${billKeySelectedClientQueryParam}`;

      const res = await axios.get(url, config);

      if (res.status !== 200) {
        toast.error("Error downloading CSV");
      }

      // Get the CSV content as text
      // const csvData = await res.data;

      // Create a Blob from the CSV data
      const blob = new Blob([res.data], { type: "text/csv" });

      // Create a link element
      const link = document.createElement("a");

      // Set the URL for the link (createObjectURL)
      link.href = URL.createObjectURL(blob);

      // Set the download attribute to specify the filename
      link.download = "All_Invoice_Data.csv";

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(link.href);
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    setselectAll(false);
    setselected([]);
    getData(1);
  }, [ApplyfilterProcessBilling, SortBy, SortOrder]);

  const loadto80perc = () => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 80) {
          clearInterval(interval);
          console.log("pause here");

          return 80;
        }
        return prevProgress + 1;
      });
    }, 50);

    // Cleanup interval on component unmount
    return () => {
      clearInterval(interval);
      console.log("inHerer");
    };
  };
  const loading_complete_showData = () => {
    console.log("executed");

    setTimeout(() => {
      setShowBarloading(false);
      getData(1);
      setShowTableData(true);
    }, 1000);
  };

  const loadto100perc = () => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          console.log("Completed");
          loading_complete_showData();

          return 100;
        }
        return prevProgress + 1;
      });
    }, 20);

    // Cleanup interval on component unmount
    return () => {
      clearInterval(interval);
      console.log("inHerer");
    };
  };

  const fetchInvoices = async () => {
    try {
      setShowBarloading(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {};

      console.log("body", body);

      const url = `${process.env.REACT_APP_BASE_URL}/pull-invoices-data?start_date=${startingDateFilter}&end_date=${endingDateFilter}`;
      loadto80perc();

      const res = await axios.post(url, body, config);

      console.log("res", res.data);
      if (res.status == 200) {
        console.log("res", res);

        toast.success("Invoice Generated Successfully");

        loadto100perc();
        // setShowBarloading(false);
        // getData(1);
        // setShowTableData(true);
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail);
      console.log("error", error);
      setShowBarloading(false);
      getData(1);
      setShowTableData(true);
    }
  };

  return (
    <div className=" flex flex-col space-y-5">
      <EditClientInvoiceModal
        showModal={showModal}
        setshowModal={setshowModal}
        clientInfo={clientInfo}
        setclientInfo={setclientInfo}
        showArchiveClientModal={showArchiveClientModal}
        setshowArchiveClientModal={setshowArchiveClientModal}
        getData={getData}
      />

      <ArchiveClientsModal
        showArchiveClientModal={showArchiveClientModal}
        setshowArchiveClientModal={setshowArchiveClientModal}
        showModal={showModal}
        setshowModal={setshowModal}
      />

      <EditAgentInvoiceModal
        showModal={ShowModalAgent}
        setshowModal={setShowModalAgent}
        agentInfo={agentInfo}
        setagentInfo={setagentInfo}
        showArchiveClientModal={showArchiveClientModal}
        setshowArchiveClientModal={setshowArchiveClientModal}
        getData={getData}
      />
      <GenerateInvoiceConfirmationModal
        showGenerateInvoiceModal={showGenerateInvoiceModal}
        setshowGenerateInvoiceModal={setshowGenerateInvoiceModal}
        selected={selected}
        selectAll={selectAll}
        loading={loading}
        setloading={setloading}
        getData={getData}
      />
      <MoveToManualReviewConfirmationModal
        showMoveToManualModal={showMoveToManualModal}
        setshowMoveToManualModal={setshowMoveToManualModal}
        selected={selected}
        selectAll={selectAll}
        loading={loading}
        setloading={setloading}
        getData={getData}
      />
      <Date_ClientFilter
        setFetch={setFetch}
        fetch={fetch}
        ShowBarloading={ShowBarloading}
        setShowBarloading={setShowBarloading}
        ShowTableData={ShowTableData}
        setShowTableData={setShowTableData}
        progress={progress}
        setProgress={setProgress}
        fetchInvoices={fetchInvoices}
        getData={getData}
      />
      {ShowBarloading == true && (
        <div className={``}>
          <ProgressBarLoader
            ShowTableData={ShowTableData}
            setShowTableData={setShowTableData}
            ShowBarloading={ShowBarloading}
            setShowBarloading={setShowBarloading}
            progress={progress}
            setProgress={setProgress}
          />
        </div>
      )}

      {ShowTableData == true && (
        <div className={``}>
          <div className="flex items-center align-middle h-[45px] justify-between pb-5">
            <div className="mb-2 flex items-center  gap-4">
              <span className="font-semibold text-xl text-main-text ">
                Invoices Overview
              </span>

              {selected.length == 0 ? null : (
                <div className=" flex items-center justify-center gap-1">
                  <div
                    onClick={() => setshowGenerateInvoiceModal(true)}
                    className=" rounded-3xl px-5 py-2 border  border-slate-200 text-sm bg-[#69C920] text-white cursor-pointer"
                  >
                    Generate Invoice
                  </div>
                  <div
                    onClick={() => setshowMoveToManualModal(true)}
                    className=" rounded-3xl px-5 py-2 border bg-white border-slate-200 text-sm  cursor-pointer"
                  >
                    Move to Manual Review
                  </div>
                </div>
              )}
            </div>

            <span
              onClick={() =>
                toast.promise(getCSVData(), {
                  loading: "Fetching Data",
                  success: <b>Starting Download</b>,
                  error: <b>Error Downloading CSV</b>,
                })
              }
            >
              <DownloadCSVButton></DownloadCSVButton>
            </span>
          </div>
          {loading == true ? (
            <div className=" w-full">
              <Skeleton className=" relative w-[100%] h-[425px]  p-4 border bg-slate-200  font-bold text-[#163143]" />
            </div>
          ) : data.length == 0 ? (
            <div className=" w-full rounded-3xl bg-white flex items-center justify-center min-h-[300px]">
              No Data Found
            </div>
          ) : (
            <div className=" bg-white rounded-3xl overflow-hidden">
              <div className=" w-full overflow-x-scroll border rounded-3xl ">
                <div className=" ">
                  <ProcessBillingTable
                    setshowModal={setshowModal}
                    selected={selected}
                    setselected={setselected}
                    setShowModalAgent={setShowModalAgent}
                    data={data}
                    setclientInfo={setclientInfo}
                    setagentInfo={setagentInfo}
                    selectAll={selectAll}
                    setselectAll={setselectAll}
                    SortBy={SortBy}
                    setSortBy={setSortBy}
                    SortOrder={SortOrder}
                    setSortOrder={setSortOrder}
                  />
                </div>
              </div>
              <Pagination2
                currentpage={currentpage}
                totalPages={totalPages}
                getData={getData}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
