import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Check } from "lucide-react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";






function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1, }}>
        <LinearProgress
          color="inherit"
          className=" text-[#69C920] py-[6px] rounded-3xl "
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
       {props.value==100? <div className=" bg-[#69C920] h-7 w-7 rounded-full flex items-center justify-center"> <Check size={15} color="#FFFF" /></div>:  <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>}
      </Box>
    </Box>
  );
}

export default function ({
  ShowTableData,
  setShowTableData,
  ShowBarloading,
  setShowBarloading,
  progress,
  setProgress,
}) {


    const loading_complete_showData=()=>{

        console.log('executed')

        setTimeout(() => {
            setShowBarloading(false)
        setShowTableData(true);
        }, 1000);
        
    }



  const getData = () => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          console.log("Completed");
          loading_complete_showData()
          return 100;
        }
        return prevProgress + 1;
      });
    }, 50);

    // Cleanup interval on component unmount
    return () => {
      clearInterval(interval);
      console.log("inHerer");
    };
  };

  useEffect(() => {
    // getData();
  }, [ShowBarloading]);

  return (
 
      <div className={""}>
        <div className="flex mt-5 items-center align-middle h-[45px] justify-between">
          <div className="mb-2 flex items-center  gap-4">
            <span className="font-semibold text-xl text-main-text ">
              Invoices Overview
            </span>
          </div>
        </div>
        {
          <div className=" w-full overflow-x-scroll border rounded-3xl ">
            <div className=" flex items-center justify-center w-full bg-white h-[210px] rounded-3xl">
              <div className=" w-3/5 text-[#163143] text-[14px] font-medium">
                Fetching Data From HubStaff
                <LinearProgressWithLabel value={progress} />
              </div>
            </div>
          </div>
        }
      </div>
    
  );
}
