import React, { useState } from 'react'

const Apply = ({className, onChange}) => {
  return (
    <button onClick={onChange}  className={`${className} text-[14px] w-[100px] rounded-full font-semibold`}>
        Apply
    </button>
  )
}

export default Apply