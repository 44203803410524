import downloadIcon from "../../assests/downloadSvg.png";
import { useState } from "react";
export default function DownloadCSVButton() {
  const [showHover, setshowHover] = useState(false);
  return (
    <div className=" flex flex-col cursor-pointer">
        
      <img
      alt="Download CSV"
        className=" w-9 mr-5  right-3"
        src={downloadIcon}
        onMouseEnter={() => setshowHover(true)}
        onMouseLeave={() => setshowHover(false)}
      />
    </div>
  );
}
