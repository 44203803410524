import { useEffect, useState } from "react";
import editIcon from "../../assests/editIcon.jpeg";
import { CheckIcon, ChevronDown, ChevronUp, X } from "lucide-react";
import AppContext from "../../context/AppContext";
import { useContext } from "react";
import DownloadCSVButton from "../ui/DownloadCSVButton";
import BilledManuallyPaidModal from "../BillKey/BilledManuallyPaidModal";
import BilledManuallyUnpaidModal from "../BillKey/BilledManuallyUnpaidModal";
import Pagination2 from "../ui/Pagination2";
import axios from "axios";
import { Skeleton } from "../ui/skeleton.tsx";
import EditAgentInvoiceModal from "./EditAgentInvoiceModal.js";
import EditClientInvoiceModal from "./EditClientInvoiceModal.js";
import ArchiveClientsModal from "../BillKey/ArchiveClientsModal.js";
import ProcessBillingTable from "./ProcessBillingTable.js";
import toast from "react-hot-toast";

export default function InvoicesToBillTable({}) {
  const {
    ApplyfilterProcessBilling,
    ProcessBillingSelectedClientFilter,
    SortBy,
    setSortBy,
    SortOrder,
    setSortOrder,
  } = useContext(AppContext);
  // console.log(data);
  const [showModal, setshowModal] = useState(false);
  const [clientInfo, setclientInfo] = useState();

  const [selected, setselected] = useState([]);

  const [showBilledManuallyPaidModal, setshowBilledManuallyPaidModal] =
    useState(false);
  const [showBilledManuallyUnpaidModal, setshowBilledManuallyUnpaidModal] =
    useState(false);
  const [showArchiveClientModal, setshowArchiveClientModal] = useState(false);
  const [ShowModalAgent, setShowModalAgent] = useState(false);

  const [agentInfo, setagentInfo] = useState("");

  const [loading, setloading] = useState(true);
  const [currentpage, setcurrentpage] = useState(0);
  const [totalPages, settotalPages] = useState(0);
  const [data, setdata] = useState([]);

  const [selectAll, setselectAll] = useState(false);

  const getData = async (page) => {
    try {
      setselected([]);
      setloading(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedClients =
        ProcessBillingSelectedClientFilter.length > 0
          ? ProcessBillingSelectedClientFilter.map(
              (item) => `&filter_ids=${item}`
            )
          : [];
      const url = ` ${
        process.env.REACT_APP_BASE_URL
      }/invoices-to-bill?page=${page}&&per_page=${30}&sort_by=${SortBy}&sort_order=${SortOrder}${selectedClients?.join(
        ""
      )}`;

      const res = await axios.get(url, config);

      if (res.status == 200) {
        console.log("res.status", res.status);
        console.log("res.data", res.data.grouped_invoices);
        setloading(false);

        setdata(res.data.grouped_invoices);
        settotalPages(res?.data?.pagination?.total_pages);
        setcurrentpage(res?.data?.pagination?.current_page);
        setloading(false);
      } else {
        alert("error");
        console.log("res", res);
      }
    } catch (error) {
      setdata([]);

      console.log("error", error.response);
      setloading(false);
    }
  };

  const getCSVData = async () => {
    try {
      const billKeySelectedClientQueryParam = new URLSearchParams();

      if (ProcessBillingSelectedClientFilter.length > 0) {
        ProcessBillingSelectedClientFilter.forEach((client) => {
          billKeySelectedClientQueryParam.append("filter_ids", client);
        });
      }

      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${process.env.REACT_APP_BASE_URL}/invoices-download?billing_status=invoice_to_bill&${billKeySelectedClientQueryParam}`;

      const res = await axios.get(url, config);

      if (res.status !== 200) {
        toast.error("Error downloading CSV");
      }

      // Get the CSV content as text
      // const csvData = await res.data;

      // Create a Blob from the CSV data
      const blob = new Blob([res.data], { type: "text/csv" });

      // Create a link element
      const link = document.createElement("a");

      // Set the URL for the link (createObjectURL)
      link.href = URL.createObjectURL(blob);

      // Set the download attribute to specify the filename
      link.download = "Invoice_to_bill_Data.csv";

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(link.href);
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    setselected([]);
    setselectAll(false);
    getData(1);
  }, [ApplyfilterProcessBilling,SortBy,SortOrder]);

  if (loading == true) {
    return (
      <div className=" w-full">
        <Skeleton className=" relative w-[100%] h-[425px]  p-4 border bg-slate-200  font-bold text-[#163143]" />
      </div>
    );
  }
  if (data.length == 0) {
    return (
      <div className=" w-full rounded-3xl bg-white flex items-center justify-center min-h-[300px]">
        No Data Found
      </div>
    );
  }
  return (
    <div className="flex flex-col space-y-10">
      <EditClientInvoiceModal
        showModal={showModal}
        setshowModal={setshowModal}
        clientInfo={clientInfo}
        setclientInfo={setclientInfo}
        showArchiveClientModal={showArchiveClientModal}
        setshowArchiveClientModal={setshowArchiveClientModal}
        getData={getData}
      />
      <ArchiveClientsModal
        showArchiveClientModal={showArchiveClientModal}
        setshowArchiveClientModal={setshowArchiveClientModal}
        showModal={showModal}
        setshowModal={setshowModal}
      />
      <EditAgentInvoiceModal
        showModal={ShowModalAgent}
        setshowModal={setShowModalAgent}
        agentInfo={agentInfo}
        setagentInfo={setagentInfo}
        showArchiveClientModal={showArchiveClientModal}
        setshowArchiveClientModal={setshowArchiveClientModal}
        getData={getData}
      />
      <BilledManuallyPaidModal
        showBilledManuallyPaidModal={showBilledManuallyPaidModal}
        setshowBilledManuallyPaidModal={setshowBilledManuallyPaidModal}
        selected={selected}
        setloading={setloading}
        getData={getData}
        selectAll={selectAll}
        loading={loading}
      />
      <BilledManuallyUnpaidModal
        showBilledManuallyUnpaidModal={showBilledManuallyUnpaidModal}
        setshowBilledManuallyUnpaidModal={setshowBilledManuallyUnpaidModal}
        selected={selected}
        setloading={setloading}
        getData={getData}
        selectAll={selectAll}
        loading={loading}
      />
      <div>
        <div className="flex items-center align-middle h-[50px] justify-between ">
          <div className="mb-2 flex items-center  gap-4">
            <span className="font-semibold text-xl text-main-text ">
              Invoices Overview
            </span>

            {selected.length == 0 ? null : (
              <div className=" flex items-center justify-center gap-1">
                <div
                  onClick={() => setshowBilledManuallyPaidModal(true)}
                  className=" rounded-3xl px-5 py-2 border  border-slate-200 text-sm bg-[#69C920] text-white cursor-pointer"
                >
                  Invoice-Paid
                </div>
                <div
                  onClick={() => setshowBilledManuallyUnpaidModal(true)}
                  className=" rounded-3xl px-5 py-2 border bg-white border-slate-200 text-sm  cursor-pointer"
                >
                  Invoice-Unpaid
                </div>
              </div>
            )}
          </div>

          <span
            onClick={() =>
              toast.promise(getCSVData(), {
                loading: "Fetching Data",
                success: <b>Starting Download</b>,
                error: <b>Error Downloading CSV</b>,
              })
            }
          >
            <DownloadCSVButton></DownloadCSVButton>
          </span>
        </div>

        <div className=" bg-white rounded-3xl overflow-hidden">
          <div className=" w-full overflow-x-scroll border rounded-3xl ">
            <div className=" ">
              <ProcessBillingTable
                setshowModal={setshowModal}
                selected={selected}
                setselected={setselected}
                setShowModalAgent={setShowModalAgent}
                data={data}
                setclientInfo={setclientInfo}
                setagentInfo={setagentInfo}
                selectAll={selectAll}
                setselectAll={setselectAll}
                SortBy={SortBy}
                setSortBy={setSortBy}
                SortOrder={SortOrder}
                setSortOrder={setSortOrder}
              />
            </div>
          </div>
          <Pagination2
            currentpage={currentpage}
            totalPages={totalPages}
            getData={getData}
          />
        </div>
      </div>
    </div>
  );
}
