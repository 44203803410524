import React from 'react'

const ClearFilter = ({className}) => {
  return (
    <button className={`${className} border text-[14px] px-8 py-2 rounded-full font-semibold text-[#163143]`}>
        Clear Result
    </button>
  )
}

export default ClearFilter