import React from "react";

const FetchFilter = ({className}) => {
    return (
        <button className={`${className} bg-[#69C920] text-[14px] px-8 py-2 rounded-full font-semibold text-white`}>
            Fetch Invoices
        </button>
    )
};

export default FetchFilter;
