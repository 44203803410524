import Modal from "react-modal";
import { Button } from "../ui/button.tsx";
import { useEffect, useState } from "react";
import { ChevronUp, X } from "lucide-react";
import { DatePickerDemo } from "../Filters/DateFilter.tsx";
import { useRef } from "react";
import { ChevronDown } from "lucide-react";
import { DatePickerSingle } from "../Filters/DatePickerSingle.tsx";

import axios from "axios";
import toast from "react-hot-toast";
import { Skeleton } from "../ui/skeleton.tsx";
export default function EditClientModal_MBI({
  showModal,
  setshowModal,
  clientInfo,
  setagentInfo,
  showArchiveClientModal,
  setshowArchiveClientModal,
  getData
}) {
  const customStyles2 = {
    content: {
      top: "auto",
      left: "auto",
      right: "0%",
      bottom: "auto",
      outline: "none",
    },
  };

  const [clientName, setclientName] = useState(
    clientInfo?.bill_key?.client_name
  );
  const [date, setdate] = useState(null);

  const [Status, setStatus] = useState("Status");

  const [Reason, setReason] = useState("");

  const DropDownComp = ({ header, value, setvalue, dropdowndata }) => {
    const [dropdownopen, setdropdownopen] = useState(false);
    const [serachQuery, setserachQuery] = useState("");
    const dropdownRef = useRef(null);

    useEffect(() => {
      function handleClickOutside(event) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setdropdownopen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropdownRef]);

    return (
      <div
        ref={dropdownRef}
        className=" relative cursor-pointer w-full  flex flex-col  gap-1 justify-center"
      >
        <div
          onClick={() => setdropdownopen(!dropdownopen)}
          className="  bg-[#FBFBFB] text-[14px] font-normal flex items-center justify-between border-2 rounded-full w-full px-3  py-2 "
        >
          {value}
          {dropdownopen == false ? (
            <ChevronDown className="duration-500 rotate-0" />
          ) : (
            <ChevronDown className="duration-500 rotate-180" />
          )}
        </div>
        {dropdownopen && (
          <div className=" z-20 overflow-hidden flex-col  absolute top-[45px] flex justify-center   border rounded-b-2xl rounded w-full bg-white">
            <div className=" w-full max-h-[145px]     overflow-y-scroll">
              {dropdowndata.map((item) => (
                <div
                  onClick={() => [setvalue(item), setdropdownopen(false)]}
                  className=" w-full border-b px-4 py-2 hover:bg-slate-100"
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const EditClientInvoiceReporting = async () => {
    try {
      setloading(true);

      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {
        status: Status,
        status_date: date,
        status_reason:Reason,
      };

      console.log("body", body);

      const url = `${process.env.REACT_APP_BASE_URL}/update-manual-invoice?id=${clientInfo?.id}`;

      console.log("url", url);


      const res = await axios.post(url, body, config);

      console.log("res", res.data);
      if (res.status == 200) {
        console.log("res", res);

        setshowModal(false);
        getData(1);
        toast.success("Client Updated Successfully");
      }
    } catch (error) {
      setloading(false);

      console.log("error", error?.response);
   

      toast.error("error");
    }
  };

  useEffect(() => {
    setclientName(clientInfo?.bill_key?.client_name);
  }, [showModal]);

  const [loading, setloading] = useState(false);

  if (loading == true) {
    return (
      <Modal
        isOpen={showModal}
        onRequestClose={() => {
          setshowModal(false);
        }}
        style={customStyles2}
        shouldCloseOnOverlayClick={false}
        className="fixed inset-0 flex items-center justify-center z-10 font-poppins"
        overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
      >
        <div className="flex  flex-col justify-between   bg-white relative  rounded shadow-lg  h-[100vh] w-[45vw] max-w-[800px] ">
          <div className=" flex w-full h-full items-center justify-center absolute text-[40px] text-slate-400">
            Updating Info
          </div>
          <Skeleton className=" w-full h-full" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => {
        setshowModal(false);
      }}
      style={customStyles2}
      shouldCloseOnOverlayClick={false}
      className="fixed inset-0 flex items-center justify-center z-50 font-poppins"
      overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
      //   ariaHideApp={false}
    >
      <div className="flex  flex-col justify-between   bg-white  rounded shadow-lg  h-[100vh] w-[45vw] max-w-[800px] ">
        <div className=" w-full h-[100vh] ">
          <div className="  h-[144px] ">
            <h2 className="text-2xl flex items-center justify-between font-semibold text-main-text px-[20px]   border-b-2">
              <div className=" pl-2 py-4">Edit Client Invoice Reporting</div>
              <div>
                <X
                  className=" cursor-pointer"
                  onClick={() => {
                    setshowModal(false);
                  }}
                />
              </div>
            </h2>
            <div className=" flex flex-row px-[20px] border-b-2 ">
              {/* <div className=" w-[50%]  flex flex-row py-4  ">
              <Button
                onClick={() => {
                  // setshowModal(false);
                  setshowArchiveClientModal(true)
                }}
                className="py-3 px-8 mx-[10px] w-[210px] bg-white border text-[#163143] hover:bg-gray-100 font-normal text-base text-center rounded-3xl"
              >
                Archive Client
              </Button>
            </div> */}
              <div className=" w-full flex flex-row justify-end py-4 ">
                <Button
                  onClick={() => {
                    setshowModal(false);
                  }}
                  className="py-3 px-8 mx-[10px] w-[130px] bg-white border text-[#163143] hover:bg-gray-100 font-normal text-base text-center rounded-3xl"
                >
                  Cancel
                </Button>
                <Button
                  variant={"secondary"}
                  onClick={() => {
                    // putData(seteditTeamLeadModal);
                    EditClientInvoiceReporting()
                  }}
                  className="py-3 bg-[#69C920]  px-8 mx-[10px] w-[130px] bg-green-1 border text-[white]  font-normal text-base text-center rounded-3xl"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          <div className=" px-[20px] flex-col w-full overflow-y-scroll  h-full pb-[144px]   ">
            <div className=" px-4 mt-4"></div>
            <div className=" px-4 mt-4">
              <div>
                <div className=" flex flex-row font-semibold text-[16px]">
                  Date<div className=" text-red-500">*</div>
                </div>
                <DatePickerSingle
                  className="flex justify-between bg-[#FBFBFB]  w-full"
                  date={date}
                  setdate={setdate}
                />
              </div>
            </div>
            {/* <div className=" px-4 mt-4">
              <div>
                <div className="flex flex-row font-semibold text-[16px]">
                  Status
                  <div className=" text-red-500">*</div>
                </div>
                <input placeholder=" Paid" className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 outline-none" />
              </div>
            </div> */}
            <div className=" px-4 mt-4">
              <DropDownComp
                value={Status}
                setvalue={setStatus}
                dropdowndata={["Void", "Paid", `Unpaid`]}
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[16px]">
                Reason
              </div>

              <textarea
                value={Reason}
                onChange={(e) => setReason(e.target.value)}
                className=" w-full h-[200px] rounded-xl outline-none  py-4 bg-[#FBFBFB] border px-4"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
