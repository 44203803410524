import Modal from "react-modal";
import { Button } from "../ui/button.tsx";
import { useEffect, useState } from "react";
import { X } from "lucide-react";
import { ChevronDown } from "lucide-react";
import AppContext from "../../context/AppContext.js";
import { useContext } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Skeleton } from "../ui/skeleton.tsx";

export default function ClearResultModal({
  showClearResultModal,
  setshowClearResultModal,
  setFetch,
  setShowTableData,
  setProgress,
  setShowBarloading,
  getData,
}) {
  const customStyles2 = {
    content: {
      top: "auto",
      left: "auto",
      right: "0%",
      bottom: "auto",
      outline: "none",
    },
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      outline: "none",
    },
  };
  const { setStartingDateFilter, setEndingDateFilter } = useContext(AppContext);

  const [loading, setloading] = useState(false);

  const ClearResult = async () => {
    try {
      setloading(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {};

      console.log("body", body);

      const url = `${process.env.REACT_APP_BASE_URL}/clear-invoices`;

      const res = await axios.post(url, body, config);

      console.log("res", res.data);
      if (res.status == 200) {
        console.log("res", res);

        toast.success("Data Cleared Successfully");

        setloading(false);
        setshowClearResultModal(false);
        setFetch(!fetch);
        setShowTableData(true);
        setProgress(0);
        setStartingDateFilter("");
        setEndingDateFilter("");
        setShowBarloading(false);
        getData(1);
        // setShowTableData(true);
      }
    } catch (error) {
      console.log("error", error);
     
      setShowBarloading(false);
      getData(1);

      setShowTableData(true);

      toast.error("error fetching data");
    }
  };

  if (loading == true) {
    return (
      <Modal
        isOpen={showClearResultModal}
        onRequestClose={() => {
          setshowClearResultModal(false);
        }}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        className="fixed inset-0 flex items-center justify-center z-50 font-poppins "
        overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
        //   ariaHideApp={false}
      >
        <div className="flex  flex-col relative   bg-white rounded-3xl   shadow-lg  max-h-[300px] max-w-[500px]  h-[50vh] w-[45vw]  ">
          <div className=" absolute flex items-center justify-center w-full h-full  text-slate-400 text-[20px]">
            Clearing Result
          </div>
          <Skeleton className=" w-full h-full" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={showClearResultModal}
      onRequestClose={() => {
        setshowClearResultModal(false);
      }}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      className="fixed inset-0 flex items-center justify-center z-50 font-poppins "
      overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
      //   ariaHideApp={false}
    >
      <div className="flex  flex-col  py-4 px-6   bg-white rounded-3xl   shadow-lg  max-h-[300px] max-w-[500px]  h-[50vh] w-[45vw]  ">
        <div className="flex items-end justify-end">
          <X
            className=" cursor-pointer"
            onClick={() => setshowClearResultModal(false)}
          />
        </div>
        <div className=" flex justify-center text-[24px] font-semibold">
          Clear Result
        </div>
        <div className=" flex justify-center text-center mt-4 text-[16px] font-normal">
          Are you sure you want to clear result? Fetching result will require
          some time.
        </div>

        <div
          onClick={() => ClearResult()}
          className=" my-1 mt-7 bg-[#69C920] rounded-3xl flex items-center justify-center text-[white] text-[16px] font-semibold h-[44px] cursor-pointer"
        >
          Confirm
        </div>
        <div
          onClick={() => setshowClearResultModal(false)}
          className="  my-1 border rounded-3xl flex items-center justify-center text-[#163143] text-[16px] font-semibold h-[44px] cursor-pointer"
        >
          Cancel
        </div>
      </div>
    </Modal>
  );
}
