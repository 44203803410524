import { Check, ChevronDown, Cross, Search, X } from "lucide-react";
import { useRef, useState, useEffect } from "react";
import axios from "axios";
// import AppContext from "../context/AppContext";
import { useContext } from "react";
// import { Skeleton } from "./ui/skeleton.tsx";
import toast from "react-hot-toast";
import AppContext from "../../../context/AppContext.js";
import { Skeleton } from "../../ui/skeleton.tsx";
// import { Skeleton } from "../../ui/skeleton.js";

export default function AutoBillFilter() {
  const [serachQuery, setserachQuery] = useState("");
  const [openDropDown, setopenDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedList, setselectedList] = useState([]);

  const [Data, setData] = useState([]);
  const [Datatemp, setDatatemp] = useState([]);
  const [loading, setloading] = useState(false);

  const {

    invoiceReportingAutoBillFilter,
    setInvoiceReportingAutoBillFilter,
  } = useContext(AppContext);

  const onSearchChange = (query) => {
    console.log("query", query);
    if (query == "") {
      setData(Datatemp);
    } else {
      let a = [...Datatemp];
      a = a.filter((item) => item.toLowerCase().includes(query.toLowerCase()));
      console.log("a", a);
      setData(a);
    }

    setserachQuery(query);
  };

  const AddToSelected = (item) => {
    let temp = [...invoiceReportingAutoBillFilter];
    temp.push(item);
    console.log("temp", temp);
    setInvoiceReportingAutoBillFilter(temp);
  };

  const RemoveFromSelect = (item) => {
    let temp = [...invoiceReportingAutoBillFilter];
    temp = temp.filter((items) => items != item);
    setInvoiceReportingAutoBillFilter(temp);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setserachQuery("");
        setopenDropDown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const getData = async () => {
    try {
      setloading(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = process.env.REACT_APP_BASE_URL + `/invoice-history-filters`;

      const res = await axios.get(url, config);

      console.log("res", res.data);
      if (res.status == 200) {
        setDatatemp(res?.data?.auto_bill);
        setData(res?.data?.auto_bill);
      }

      setloading(false);
    } catch (error) {
      console.log("error", error);

      setloading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    setserachQuery("");
    setData(Datatemp);
  }, [openDropDown]);

  if (loading == true) {
    return (
      <div className=" relative ">
        <Skeleton className=" relative w-[100px] h-[35px]  py-2 rounded-full border bg-slate-200  font-bold text-[#163143]" />
      </div>
    );
  }

  if (Datatemp.length == 0) {
    return (
      <div className="  px-4 border bg-white  flex items-center justify-center h-9 rounded-full text-[12px]  cursor-pointer font-poppins">
        <div className=" mr-1  font-[300]">No Auto Bill Found</div>
      </div>
    );
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        onClick={() => setopenDropDown(!openDropDown)}
        className="  px-4 border bg-white  flex items-center justify-center h-9 rounded-full text-[12px]  cursor-pointer font-poppins"
      >
        <div className=" mr-1  font-[300]">Auto Bill</div>
        {invoiceReportingAutoBillFilter.length > 0 && (
          <div className=" bg-[#69C920] border text-[white] font-medium text-[12px] mx-1 rounded-full w-[20px] flex h-[20px] items-center justify-center">
            {invoiceReportingAutoBillFilter.length}
          </div>
        )}
        {openDropDown == false ? (
          <ChevronDown className="duration-500 rotate-0" />
        ) : (
          <ChevronDown className="duration-500 rotate-180" />
        )}
      </div>

      {openDropDown == true ? (
        <div className="  overflow-hidden absolute  flex mt-1  w-[300px] flex-col rounded-2xl border border-slate-400  bg-white z-30">
          <div className=" px-2 w-full  flex items-center h-[40px] justify-center border-b">
            <Search size={20} strokeWidth={2} color="gray" />
            <input
              value={serachQuery}
              placeholder="Search"
              onChange={(e) => onSearchChange(e.target.value)}
              className="flex w-full     h-[39px] px-2  rounded-t-xl outline-none"
            />
            <X
              onClick={() => [
                setserachQuery(""),
                setData(Datatemp),
                setopenDropDown(false),
              ]}
              size={20}
              strokeWidth={2}
              color="black"
              className=" cursor-pointer"
            />
          </div>
          <div className="  flex flex-col max-h-[330px] overflow-y-scroll  ">
            {Data.map((item) => (
              <div
                onClick={() => {
                  invoiceReportingAutoBillFilter.includes(item)
                    ? RemoveFromSelect(item)
                    : AddToSelected(item);
                }}
                className={` ${
                  invoiceReportingAutoBillFilter.includes(item) ? "" : ""
                }  cursor-pointer px-2 flex hover:bg-slate-200  gap-3 text-[14px]  py-3    `}
              >
                <div
                  className={` ${
                    invoiceReportingAutoBillFilter.includes(item)
                      ? "bg-[#69C920]"
                      : " border  border-slate-400"
                  } rounded   w-5 h-5  flex items-center justify-center`}
                >
                  {invoiceReportingAutoBillFilter.includes(item) && (
                    <Check size={17} color="#FFF" />
                  )}
                </div>
                {item}
              </div>
            ))}
          </div>
          <div
            className=" w-full flex items-center justify-center border-t min-h-[30px]"
            onClick={() => setInvoiceReportingAutoBillFilter([])}
          >
            {invoiceReportingAutoBillFilter.length != 0 && (
              <div className=" w-[80%]  flex items-center justify-center text-[14px] font-[500] py-2 my-3 rounded-full hover:bg-slate-200  cursor-pointer">
                Clear Selection
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}
