import logo from "../assests/Image.jpeg";
export default function SplashScreen() {
  return (
    <div className="flex items-center justify-center  h-svh ">
      <div>
        <img className="  w-[290px] animate-pulse" src={logo} />
      </div>
    </div>
  );
}
