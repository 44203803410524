import { useEffect, useState } from "react";
import { Eye, EyeOff, LoaderCircle } from "lucide-react";
import logo from "../../assests/Image.jpeg";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import axios from "axios";
import toast from "react-hot-toast";
import { CheckIcon, ChevronDown, ChevronUp, X } from "lucide-react";
import googleLogo from "../../assests/GoogleLogo.png";
import { GoogleLogin, googleLogout, useGoogleLogin } from "@react-oauth/google";

export default function Login() {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState([]);
  const { name, setname, login, setlogin, setUserName, setUserPic } =
    useContext(AppContext);
  const [loading, setloading] = useState(false);

  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  const [EmailFormatcheck, setEmailFormatcheck] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user && user !== undefined) {
        console.log(user);

        const url = `${process.env.REACT_APP_BASE_URL}/google-login?google_token=${user.access_token}`;

        const resp = await axios.post(url);

        console.log("resp", resp);

        if (resp.status === 200) {
          localStorage.setItem("auth_token", resp?.data?.access_token);
          localStorage.setItem("user_name", resp?.data?.user?.name);

          setlogin(true);
        }

        axios
          .get(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
            {
              headers: {
                Authorization: `Bearer ${user.access_token}`,
                Accept: "application/json",
              },
            }
          )
          .then((res) => {
            console.log("google info res");
            console.log(res);

            setUserName(res?.data?.name);
            setUserPic(res?.data?.picture);
          })
          .catch((err) => console.log(err));
      }
    };

    fetchData();
  }, [user]);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log("regex.test(email)", regex.test(email));
    return regex.test(email);
  };

  const CheckBox = () => {
    const [checked, setchecked] = useState(false);
    return (
      <div
        onClick={() => setchecked(!checked)}
        className={`${
          checked == true
            ? " bg-[#69C920]"
            : " bg-white border-2 border-gray-300   "
        }  rounded h-4 w-4  flex items-center justify-center cursor-pointer`}
      >
        {checked == true ? <CheckIcon color="white" /> : null}
      </div>
    );
  };

  const [style, setStyle] = useState({
    // position: 'absolute',

    transform: "translate(0, 0%)",

    transition: " transform 2s",
  });

  const checkLogin = async () => {
    const token = await localStorage.getItem("auth_token");
    console.log("token", token);
    if (token !== null) {
      console.log("TESSSTTTTTT", localStorage.getItem("auth_token"));
      console.log("TESSSTTTTTT", localStorage.getItem("user_name"));

      setlogin(true);
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  const LoginCall = async () => {
    try {
      setloading(true);
      const formData = new FormData();
      formData.append("username", Email);
      formData.append("password", Password);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          accept: "application/json",
        },
      };

      const url = process.env.REACT_APP_BASE_URL + `/login`;

      const res = await axios.post(url, formData, config);
      console.log("res.data", res.data);

      localStorage.setItem("auth_token", res?.data?.access_token);
      localStorage.setItem("user_name", res?.data?.user?.name);
      setlogin(true);

      setloading(false);
    } catch (error) {
      setloading(false);
      if (error.response.data.detail) {
        toast.error(error.response.data.detail);
      }
      console.log("error in getData", error);
    }
  };

  const activateAnimation = () => {
    setStyle((prevStyle) => ({
      ...prevStyle,
      //   left: "0",
      transform: "translate(-10vw, 100vh)",
    }));
  };
  const activateAnimation2 = () => {
    setStyle((prevStyle) => ({
      ...prevStyle,
      //   left: "0",
      transform: "translate(10vw, -100vh)",
    }));
  };

  const responseMessage = (response) => {
    console.log("SUCCESSS");

    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <div
      style={{ opacity: loading ? "60%" : null }}
      className=" bg-[#F1F5F5] flex flex-col gap-5 w-[100vw] h-[100vh] items-center justify-center"
    >
      <div className=" bg-white border  items-center justify-center flex flex-col min-w-[600px] w-[40%] h-[85%] rounded-3xl ">
        <div className=" w-full flex items-center justify-center mb-[50px]">
          <img src={logo} className=" w-[160px] " />
        </div>
        <div className="  flex  flex-col gap-5 ">
          <div>
            <div className=" text-[#163143]  text-[24px] font-bold">
              Sign In
            </div>
            <div className=" text-[16px] text-[#163143] ">
              Welcome back, sign-in to continue.
            </div>
          </div>

          <div className=" flex flex-col ">
            <div className=" text-[#4F4F4F] text-lg font-semibold">
              {" "}
              Username{" "}
            </div>
            <input
              value={Email}
              // placeholder="Username"
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              className=" w-[400px] h-10 px-2 rounded-3xl overflow-hidden border border-black outline-none  "
            />
          </div>

          <div className=" flex flex-col">
            <div className=" text-[#4F4F4F] text-lg font-semibold">
              {" "}
              Password{" "}
            </div>
            <div className=" w-[400px] flex items-center justify-between h-10  rounded-3xl overflow-hidden border border-black">
              <input
                // value={Email}
                // placeholder="Password"

                onChange={(e) => setPassword(e.target.value)}
                type={showPassword == true ? "text" : "password"}
                className=" w-full h-9 px-2  outline-none "
              />
              {showPassword == true ? (
                <Eye
                  onClick={() => setshowPassword(!showPassword)}
                  className=" w-[23px] h-full  cursor-pointer mr-2"
                />
              ) : (
                <EyeOff
                  onClick={() => setshowPassword(!showPassword)}
                  className=" w-[23px] h-full  cursor-pointer mr-2"
                />
              )}
            </div>
          </div>
          <div className="  flex w-[400px] justify-between">
            <div className=" flex gap-2  items-center ">
              {" "}
              <CheckBox /> Remember me
            </div>
            <div className=" underline cursor-pointer">Forgot Password</div>
          </div>

          <div className=" flex flex-col gap-4 items-center mt-3">
            <div
              onClick={() =>
                loading == true || Password.length < 8 || Email.length < 2
                  ? null
                  : LoginCall()
              }
              className={` w-[400px] bg-[#6ECA28] text-white font-bold flex items-center justify-center  rounded-3xl p-1 h-10 ${
                Password.length < 8 || Email.length < 2
                  ? " opacity-75 text-slate-300 bg-slate-200 border-gray-200 cursor-not-allowed "
                  : "cursor-pointer   "
              }`}
            >
              {loading == true ? (
                <LoaderCircle className=" animate-spin" />
              ) : (
                <div>Sign In</div>
              )}
            </div>
          </div>

          <div className=" flex w-[400px] items-center justify-center">
            <div className=" bg-slate-200 w-full h-[1px]"></div>
            OR
            <div className=" bg-slate-200 w-full h-[1px]"></div>
          </div>

          <button
            onClick={() => googleLogin()}
            className=" text-[#163143] text-md flex w-[400px] h-10  items-center gap-3 justify-center rounded-3xl border border-[#69C920]"
          >
            <img src={googleLogo} className=" h-6 " />
            Sign in with Google
          </button>
          {/* {profile ? (
                <div>
                    <img src={profile.picture} alt="user image" />
                    <h3>User Logged in</h3>
                    <p>Name: {profile.name}</p>
                    <p>Email Address: {profile.email}</p>
                    <br />
                    <br />
                    <button onClick={logOut}>Log out</button>
                </div>
            ) : (
                <button onClick={() => googleLogin()}>Sign in with Google 🚀 </button>
            )} */}
        </div>
      </div>
    </div>
  );
}
