"use client"

import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"

import { cn } from "../lib/utils.ts"
import { Button } from "../ui/button.tsx"
import { Calendar } from "../ui/calendar.tsx"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover.tsx"



export function DatePickerSingle({date,setdate}) {

    function formatDate(dateString:any) {
        console.log('dateString', dateString)
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
      console.log('first', `${year}-${month}-${day}`)
        return `${year}-${month}-${day}`;
      }
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-normal rounded-3xl bg-white",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" color="#69C920" />
          {date ?<span className=" text-slate-900"> {format(date, "PPP")}</span> : <span className=" ">Pick a date</span>}
          
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
         className=""
          mode="single"
          selected={date}
          onSelect={(e)=>[setdate(formatDate(e)),console.log('date', e)]}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
