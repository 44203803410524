import { useState, useEffect } from "react";
import axios from "axios";
import { Skeleton } from "../ui/skeleton.tsx";
import DownloadCSVButton from "../ui/DownloadCSVButton.js";
import editIcon from "../../assests/editIcon.jpeg";
import copyIcon from "../../assests/copyIcon.jpeg";
import Pagination2 from "../ui/Pagination2.js";
import toast from "react-hot-toast";
import Papa from "papaparse";
import UnmatchedFilterModal from "./UnmatchedFilterModal.js";
import AppContext from "../../context/AppContext.js";
import { useContext } from "react";

export default function BillKeyTable() {
  const {
    ApplyfilterBillKeyUnmatched,
    BillKeySelectedStripeAccountFilter,
    BillKeySelectedProjectNameFilter,
    BillKeySelectedBillKeyAccountNameFilter,
  } = useContext(AppContext);

  const [loading, setloading] = useState(true);
  const [Data, setData] = useState([]);
  const [totalPages, settotalPages] = useState();
  const [currentpage, setcurrentpage] = useState();
  const [clientInfo, setclientInfo] = useState("");
  const [showModal, setshowModal] = useState(false);

  const getData = async (page) => {
    try {
      setloading(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedClients =
        BillKeySelectedBillKeyAccountNameFilter.length > 0
          ? BillKeySelectedBillKeyAccountNameFilter.map(
              (item) => `&filter_ids=${item}`
            )
          : [];
      const url = `${
        process.env.REACT_APP_BASE_URL
      }/bill-key?page=${page}${selectedClients?.join("")}`;
      console.log("url", url);

      const res = await axios.get(url, config);
      if (res?.data) {
        console.log("bill key table data", res.data);

        setData(res?.data?.keys);
        settotalPages(res?.data?.pagination?.total_pages);
        setcurrentpage(res?.data?.pagination?.current_page);
      } else {
        console.log("error in getDataStripe", res);
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log("error in getDataStripe", error);
    }
  };

  useEffect(() => {
    getData(1);
  }, [ApplyfilterBillKeyUnmatched]);

  const copyToClipboard = (text) => {
    if (navigator?.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success("Copied to clipboard");
        })
        .catch((err) => {
          toast.error("Failed to copy");
          console.error("Error copying to clipboard: ", err);
        });
    } else {
      toast.error("Clipboard not supported");
    }
  };

  if (loading == true) {
    return (
      <div className=" w-full">
        <div className="flex  items-center align-middle justify-between px-4 h-[50px] bg-white border-r">
          <span className="font-semibold text-[16px] text-main-text  ">
            Bill Key Customers
          </span>
        </div>
        <Skeleton className=" relative w-[100%] h-[425px]  p-4 border bg-slate-200  font-bold text-[#163143]" />
      </div>
    );
  }

  if (Data?.length == 0) {
    return (
      <div className=" w-full border rounded-bl-3xl h-full">
        <div className="flex  items-center align-middle justify-between px-4 h-[50px] bg-white border-r">
          <span className="font-semibold text-[16px] text-main-text  ">
            Bill Key Customers
          </span>
        </div>
        <div className=" w-full flex items-center justify-center my-10">
          No Records Found
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full space-t-10">
      <UnmatchedFilterModal
        showModal={showModal}
        setshowModal={setshowModal}
        clientInfo={clientInfo}
        setclientInfo={setclientInfo}
        // showArchiveClientModal={showArchiveClientModal}
        // setshowArchiveClientModal={setshowArchiveClientModal}
      />
      <div className=" h-full">
        <div className="flex  items-center align-middle justify-between px-4 h-[50px] bg-white border-r">
          <span className="font-semibold text-[16px] text-main-text  ">
            Bill Key Customers
          </span>
        </div>

        <div className=" h-full ">
          <div className=" flex flex-row  bg-white   h-full  w-[100%]    border-r ">
            <div className=" w-[2000px] h-[66vh] overflow-x-auto  border-r">
              <table className=" table-fixed w-full border-separate  ">
                {" "}
                <thead className=" border-b-2 text-main-text">
                  <tr className="h-[50px] px-4 bg-[#EBF3F4]">
                    <th className="   h-[50px] w-[60px] border-r border-slate-100 font-medium text-[12px] text-main-text sticky left-0 shadow-[#D7E6E7_3px_0px_3px_0px] bg-[#EBF3F4] ">
                      <div className="flex items-center align-middle justify-start space-x-2  w-full   ">
                        <span className=" "> </span>
                      </div>
                    </th>
                    <th className="text-center h-[50px] px-4 w-[220px] font-medium text-[12px] text-main-text  border-b">
                      <div className="flex items-center align-middle justify-start space-x-2">
                        <span>Client Name</span>
                      </div>
                    </th>
                    <th className="text-center h-[50px] px-4 w-[170px] font-medium text-[12px] text-main-text border-b">
                      <div className="flex items-center align-middle justify-start space-x-2">
                        <span>Client ID</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className=" overflow-y-scroll ">
                  {Data.map((item, index) => (
                    <tr
                      key={index}
                      className={`${
                        index === Data.length - 1 ? "border-b-2" : "border-b"
                      } hover:bg-gray-200 hover:cursor-pointer`}
                    >
                      <td className="   border-b p-4 text-start font-normal text-[12px] text-main-text sticky left-0 bg-white  shadow-[#D7E6E7_3px_0px_3px_0px] border-r border-slate-100">
                        <div className=" bg-white h-[20px] w-full  flex justify-center items-center">
                          <img
                            onClick={() => [
                              setclientInfo(item),
                              setshowModal(true),
                            ]}
                            src={editIcon}
                            className=" w-[20px]  cursor-pointer"
                          />
                        </div>
                      </td>
                      <td className="p-2 text-start font-normal text-[12px] text-main-text border-b ">
                        {" "}
                        <div className=" flex  gap-2">
                          {item?.client_name ? (
                            <img
                              onClick={() => copyToClipboard(item?.client_name)}
                              className="w-5 h-5  cursor-pointer"
                              src={copyIcon}
                            />
                          ) : null}

                          {item?.client_name == null ? "-" : item?.client_name}
                        </div>
                      </td>
                      <td className="border-b p-4 text-start font-normal text-[12px] text-main-text ">
                        {" "}
                        {item?.id ? item?.id : "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
