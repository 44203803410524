import Modal from "react-modal";
import { Button } from "../ui/button.tsx";
import { useEffect, useState, useRef } from "react";
import { ChevronDown, CircleMinus, CirclePlus, X } from "lucide-react";
import toast from "react-hot-toast";
import axios from "axios";
import { Skeleton } from "../ui/skeleton.tsx";

const DropDownCompHubstaffProject = ({
  header,
  placeholder,
  dropdowndata,
  value,
  setvalue,
  loading,
}) => {
  const [dropdownopen, setdropdownopen] = useState(false);
  const [serachQuery, setserachQuery] = useState("");
  const dropdownRef = useRef(null);

  const [Data, setData] = useState(dropdowndata);

  useEffect(() => {
    setData(dropdowndata);
    setvalue([]);
  }, []);

  const onSearchChange = (query) => {
    console.log("query", query);
    if (query == "") {
      setData(dropdowndata);
    } else {
      let a = [...dropdowndata];
      a = a.filter((item) =>
        item?.name?.toLowerCase().includes(query.toLowerCase())
      );
      console.log("a", a);
      setData(a);
    }

    setserachQuery(query);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setdropdownopen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const AddToSelected = (item) => {
    let temp = [...value];
    temp.push(item);
    console.log("temp", temp);
    setvalue(temp);
  };

  const RemoveFromSelect = (item) => {
    let temp = [...value];
    temp = temp.filter((items) => items != item);
    setvalue(temp);
  };

  if (loading == true) {
    return (
      <div className=" relative cursor-pointer  flex flex-col  gap-1 justify-center">
        <Skeleton className=" w-[200px] h-[40px]" />
      </div>
    );
  }

  return (
    <div
      ref={dropdownRef}
      className=" relative w-[23%] cursor-pointer  flex flex-col  gap-1 justify-center"
    >
      <div className="  text-[14px]  font-semibold">{header}</div>
      <div
        onClick={() => setdropdownopen(!dropdownopen)}
        className={`   text-[14px] font-normal flex items-center justify-between border-2 rounded-full  w-full px-3  py-2 border-[#69C920] `}
      >
        <div className=" flex   w-[90%]  max-h-[50px] overflow-x-scroll no-scrollbar ">
          <div className=" flex text-[12px]  w-[2000px] ">
            {value.length == 0
              ? placeholder
              : value.map((item) => (
                  <div className=" max-h-[30px] w-max   mx-1 p-1 px-2 text-center bg-[#69C920] font-[600] text-white rounded-full text-[12px]">
                    {" "}
                    {item.name}
                  </div>
                ))}
          </div>
        </div>
        {dropdownopen == false ? (
          <ChevronDown className="duration-500 rotate-0" />
        ) : (
          <ChevronDown className="duration-500 rotate-180" />
        )}
      </div>
      {dropdownopen && (
        <div className=" z-10 overflow-hidden flex-col  absolute top-[72px] flex justify-center   border rounded-b-2xl rounded  w-full bg-white">
          <input
            value={serachQuery}
            className=" w-full border h-10 px-2 outline-none text-[14px] "
            placeholder=" search"
            onChange={(e) => onSearchChange(e.target.value)}
          />
          <div className=" w-full max-h-[115px]     overflow-y-scroll">
            {Data?.map((item) => (
              <div
                onClick={() => [
                  value.includes(item)
                    ? RemoveFromSelect(item)
                    : AddToSelected(item),
                ]}
                className={` w-full border-b px-4 py-2 hover:bg-slate-100 text-[12px] ${
                  value.includes(item) && " bg-slate-200 "
                }`}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const MatchAnotherClient = ({
  DropDownComp,
  HubstaffClientNameList,
  HubstaffClientname2,
  setHubstaffClientname2,
  DropDownCompBillKey,
  BillKeyClientNameList,
  BillKeyClientName2,
  setBillKeyClientName2,
  DropDownCompStripe,
  StripeClientNameList,
  StripeClientName2,
  setStripeClientName2,
  DropDownCompHubstaffProject,
  HubstaffProjectnameList,
  HubstaffProjectName2,
  setHubstaffProjectName2,
  loading,
}) => {
  return (
    <div className=" flex items-center justify-evenly h-[90px]">
      <DropDownComp
        header={" HubStaff Client Name"}
        placeholder={"Select Hubstaff Client Name"}
        dropdowndata={HubstaffClientNameList}
        value={HubstaffClientname2}
        setvalue={setHubstaffClientname2}
        loading={loading}
      />
      <DropDownCompBillKey
        header={" Bill key Client Name"}
        placeholder={" Select Bill key Client Name"}
        dropdowndata={BillKeyClientNameList}
        value={BillKeyClientName2}
        setvalue={setBillKeyClientName2}
        loading={loading}
      />
      <DropDownCompStripe
        header={"   Stripe Client Name"}
        placeholder={" Select  Stripe Client Name"}
        dropdowndata={StripeClientNameList}
        value={StripeClientName2}
        setvalue={setStripeClientName2}
        loading={loading}
      />
      <DropDownCompHubstaffProject
        header={"  HubStaff Project Name"}
        placeholder={" Select HubStaff Project Name"}
        dropdowndata={HubstaffProjectnameList}
        value={HubstaffProjectName2}
        setvalue={setHubstaffProjectName2}
        loading={loading}
      />
    </div>
  );
};

const MatchAnotherButton = ({ setshowExtraRow, showExtraRow }) => {
  return showExtraRow == false ? (
    <div
      onClick={() => setshowExtraRow(true)}
      className=" flex mx-[20px] items-center w-[211px] h-[42px] border-2 hover:bg-slate-100 bg-white cursor-pointer rounded-full gap-2 px-2  "
    >
      <CirclePlus color={"#69C920"} strokeWidth={"1.5px"} />
      <div className=" text-[14px] font-[500]"> Match Another Client</div>
    </div>
  ) : (
    <div
      onClick={() => setshowExtraRow(false)}
      className=" flex mx-[20px] items-center w-[211px] h-[42px] border-2 hover:bg-slate-100  bg-white cursor-pointer rounded-full gap-2 px-2  "
    >
      <CircleMinus color={"#FF3434"} strokeWidth={"1.5px"} />
      <div className=" text-[14px] font-[500]"> Hide Additional Row</div>
    </div>
  );
};

// Main Function
export default function UnmatchedFilterModal({
  showModal,
  setshowModal,
  clientInfo,
}) {
  const customStyles2 = {
    content: {
      top: "auto",
      left: "auto",
      right: "auto",
      bottom: "0%",
      outline: "none",
    },
  };

  // All the lists in the dropdown
  const [HubstaffClientNameList, setHubstaffClientNameList] = useState();
  const [BillKeyClientNameList, setBillKeyClientNameList] = useState();
  const [StripeClientNameList, setStripeClientNameList] = useState();
  const [HubstaffProjectnameList, setHubstaffProjectnameList] = useState();
  const [loading, setloading] = useState(true);

  // For First Row
  const [HubstaffClientname, setHubstaffClientname] = useState("");
  const [BillKeyClientName, setBillKeyClientName] = useState("");
  const [StripeClientName, setStripeClientName] = useState("");
  const [HubstaffProjectName, setHubstaffProjectName] = useState([]);

  // For the additional Row
  const [showExtraRow, setshowExtraRow] = useState(false);
  const [HubstaffClientname2, setHubstaffClientname2] = useState("");
  const [BillKeyClientName2, setBillKeyClientName2] = useState("");
  const [StripeClientName2, setStripeClientName2] = useState("");
  const [HubstaffProjectName2, setHubstaffProjectName2] = useState([]);

  // Api Calls

  const PostData = async () => {
    try {
      setloading(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {
        hubstaff_client_id: HubstaffClientname.id,
        bill_key_id: BillKeyClientName.id,
        stripe_customer_id: StripeClientName.id,
        hubstaff_project_ids: HubstaffProjectName.map((item) => item.id),
      };

      console.log("body", body);

      const url = process.env.REACT_APP_BASE_URL + `/manually-match-client`;

      const res = await axios.post(url, body, config);

      console.log("res1", res.data);
      if (res.status == 200) {

        console.log("res1", res);
        if (showExtraRow == true) {
        toast.success("Updated 1");

          console.log("heeeeeeeeee");

          const body2 = {
            hubstaff_client_id: HubstaffClientname2.id,
            bill_key_id: BillKeyClientName2.id,
            stripe_customer_id: StripeClientName2.id,
            hubstaff_project_ids: HubstaffProjectName2.map((item) => item.id),
          };
          const res2 = await axios.post(url, body2, config);
          if (res2.status == 200) {
            toast.success("Updated 2");

            window.location.reload();
            setloading(false);
            setshowModal(false);
          }
        }
        toast.success("Updated");
        window.location.reload();
        setloading(false);
        setshowModal(false);
      }
    } catch (error) {
      setloading(false);
      setshowModal(false);

      console.log("error", error?.response);
   

      toast.error(error?.response?.data?.detail);
    }
  };

  const getData = async () => {
    try {
      setloading(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = process.env.REACT_APP_BASE_URL + `/manually-match-client`;

      const res = await axios.get(url, config);

      console.log("res", res.data);
      if (res.status == 200) {
        setHubstaffProjectnameList(res?.data?.hubstaff_projects);
        setBillKeyClientNameList(res?.data?.bill_keys);
        setHubstaffClientNameList(res?.data?.hubstaff_clients);
        setStripeClientNameList(res?.data?.stripe_customers);

        setloading(false);
      }
    } catch (error) {
      console.log("error", error);
   
    }
  };

  // Components

  const DropDownComp = ({
    header,
    placeholder,
    dropdowndata,
    value,
    setvalue,
    loading,
  }) => {
    const [dropdownopen, setdropdownopen] = useState(false);
    const [serachQuery, setserachQuery] = useState("");
    const dropdownRef = useRef(null);

    const [Data, setData] = useState(dropdowndata);

    const onSearchChange = (query) => {
      console.log("query", query);
      if (query == "") {
        setData(dropdowndata);
      } else {
        let a = [...dropdowndata];
        a = a.filter((item) =>
          item?.name?.toLowerCase().includes(query.toLowerCase())
        );
        console.log("a", a);
        setData(a);
      }

      setserachQuery(query);
    };

    useEffect(() => {
      function handleClickOutside(event) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setdropdownopen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropdownRef]);

    if (loading == true) {
      return (
        <div className=" relative cursor-pointer  flex flex-col  gap-1 justify-center">
          <Skeleton className=" w-[200px] h-[40px]" />
        </div>
      );
    }

    return (
      <div
        ref={dropdownRef}
        className=" relative w-[23%]   cursor-pointer  flex flex-col  gap-1 justify-center"
      >
        <div className="  text-[14px]  font-semibold">{header}</div>
        <div
          onClick={() => setdropdownopen(!dropdownopen)}
          className="   text-[12px] font-normal flex items-center justify-between border-2 rounded-full  px-3 w-full  py-2 border-[#69C920]"
        >
          {value.name == null || value.name == "" ? placeholder : value.name}
          {dropdownopen == false ? (
            <ChevronDown className="duration-500 rotate-0" />
          ) : (
            <ChevronDown className="duration-500 rotate-180" />
          )}
        </div>
        {dropdownopen && (
          <div className=" z-10 overflow-hidden flex-col  absolute top-[72px] flex justify-center   border rounded-b-2xl rounded w-full  bg-white">
            <input
              value={serachQuery}
              className=" w-full border h-10 px-2 outline-none text-[14px] bg-white "
              placeholder=" search"
              onChange={(e) => onSearchChange(e.target.value)}
            />
            <div className=" w-full max-h-[115px]     overflow-y-scroll">
              {Data?.map((item) => (
                <div
                  onClick={() => [setvalue(item), setdropdownopen(false)]}
                  className=" w-full border-b px-4 py-2 text-[12px] hover:bg-slate-100 bg-white"
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const DropDownCompStripe = ({
    header,
    placeholder,
    dropdowndata,
    value,
    setvalue,
    loading,
  }) => {
    const [dropdownopen, setdropdownopen] = useState(false);
    const [serachQuery, setserachQuery] = useState("");
    const dropdownRef = useRef(null);

    const [Data, setData] = useState(dropdowndata);

    const onSearchChange = (query) => {
      console.log("query", query);
      if (query == "") {
        setData(dropdowndata);
      } else {
        let a = [...dropdowndata];
        a = a.filter((item) =>
          item?.email?.toLowerCase().includes(query.toLowerCase())
        );
        console.log("a", a);
        setData(a);
      }

      setserachQuery(query);
    };

    useEffect(() => {
      function handleClickOutside(event) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setdropdownopen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropdownRef]);

    if (loading == true) {
      return (
        <div className=" relative cursor-pointer  flex flex-col  gap-1 justify-center">
          <Skeleton className=" w-[200px] h-[40px]" />
        </div>
      );
    }

    return (
      <div
        ref={dropdownRef}
        className=" relative w-[23%] cursor-pointer  flex flex-col  gap-1 justify-center"
      >
        <div className="  text-[14px]  font-semibold">{header}</div>
        <div
          onClick={() => setdropdownopen(!dropdownopen)}
          className="   text-[12px] font-normal flex items-center justify-between border-2 rounded-full  w-full px-3  py-2 border-[#69C920]"
        >
          {value.email == null || value.email == "" ? placeholder : value.email}
          {dropdownopen == false ? (
            <ChevronDown className="duration-500 rotate-0" />
          ) : (
            <ChevronDown className="duration-500 rotate-180" />
          )}
        </div>
        {dropdownopen && (
          <div className=" z-10 overflow-hidden flex-col  absolute top-[72px] flex justify-center   border rounded-b-2xl rounded  w-full bg-white">
            <input
              value={serachQuery}
              className=" w-full border h-10 px-2 outline-none text-[14px] "
              placeholder=" search"
              onChange={(e) => onSearchChange(e.target.value)}
            />
            <div className=" w-full max-h-[115px]      overflow-y-scroll">
              {Data?.map((item) => (
                <div
                  onClick={() => [setvalue(item), setdropdownopen(false)]}
                  className=" w-full border-b px-4 py-2 hover:bg-slate-100 text-[12px]"
                >
                  {item.email}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };
  const DropDownCompBillKey = ({
    header,
    placeholder,
    dropdowndata,
    value,
    setvalue,
    loading,
  }) => {
    const [dropdownopen, setdropdownopen] = useState(false);
    const [serachQuery, setserachQuery] = useState("");
    const dropdownRef = useRef(null);

    const [Data, setData] = useState(dropdowndata);

    const onSearchChange = (query) => {
      console.log("query", query);
      if (query == "") {
        setData(dropdowndata);
      } else {
        let a = [...dropdowndata];
        a = a.filter((item) =>
          item?.client_name?.toLowerCase().includes(query.toLowerCase())
        );
        console.log("a", a);
        setData(a);
      }

      setserachQuery(query);
    };

    useEffect(() => {
      function handleClickOutside(event) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setdropdownopen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropdownRef]);
    if (loading == true) {
      return (
        <div className=" relative cursor-pointer  flex flex-col  gap-1 justify-center">
          <Skeleton className=" w-[200px] h-[40px]" />
        </div>
      );
    }

    return (
      <div
        ref={dropdownRef}
        className=" relative w-[23%] cursor-pointer   flex flex-col  gap-1 justify-center"
      >
        <div className="  text-[14px]  font-semibold">{header}</div>
        <div
          onClick={() => setdropdownopen(!dropdownopen)}
          className="   text-[12px] font-normal flex items-center justify-between border-2 rounded-full  w-full px-3  py-2 border-[#69C920]"
        >
          {value.client_name == null || value.client_name == ""
            ? placeholder
            : value.client_name}
          {dropdownopen == false ? (
            <ChevronDown className="duration-500 rotate-0" />
          ) : (
            <ChevronDown className="duration-500 rotate-180" />
          )}
        </div>
        {dropdownopen && (
          <div className=" z-10 overflow-hidden flex-col  absolute top-[72px] flex justify-center   border rounded-b-2xl rounded  w-full bg-white">
            <input
              value={serachQuery}
              className=" w-full border h-10 px-2 outline-none text-[14px] "
              placeholder=" search"
              onChange={(e) => onSearchChange(e.target.value)}
            />
            <div className=" w-full max-h-[115px]      overflow-y-scroll">
              {Data?.map((item) => (
                <div
                  onClick={() => [setvalue(item), setdropdownopen(false)]}
                  className=" w-full border-b px-4 py-2 hover:bg-slate-100 text-[12px]"
                >
                  {item?.client_name}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    console.log("clientInfo?.client_name", clientInfo?.client_name);
    setBillKeyClientName(clientInfo);
    setshowExtraRow(false);
  }, [showModal, clientInfo]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => {
        setshowModal(false);
      }}
      style={customStyles2}
      shouldCloseOnOverlayClick={false}
      className="fixed inset-0 flex items-center justify-center z-50 font-poppins"
      overlayClassName="fixed  blur-bg "
      //   ariaHideApp={false}
    >
      <div className="flex flex-col justify-between shadow-xl   bg-white  rounded border-t   w-[100vw] ">
        <div className=" w-full ">
          <div className=" ">
            <div className=" w-full flex items-center justify-between ">
              <h2 className="text-[16px] mt-[13px]  flex items-center gap-2   font-medium text-main-text px-[20px]   ">
                <div className=" pl-2 py-2">Bill Key Account Name:</div>
                <div className=" text-[#69C920] font-semibold">
                  {BillKeyClientName?.client_name}
                </div>
              </h2>

              <MatchAnotherButton
                setshowExtraRow={setshowExtraRow}
                showExtraRow={showExtraRow}
              />
            </div>

            <div className=" flex flex-row  px-[20px] py-2  justify-end    ">
              <Button
                onClick={() => {
                  setshowModal(false);
                }}
                className=" px-8 mx-[10px] w-[130px] bg-white border text-[#163143] border-[#69C920] hover:bg-gray-100 font-normal text-[14px] text-center rounded-3xl"
              >
                Cancel
              </Button>
              {showExtraRow == false ? (
                <Button
                  variant={
                    HubstaffClientname.id != null &&
                    BillKeyClientName.id != null &&
                    StripeClientName.id != null &&
                    HubstaffProjectName.length > 0
                      ? "secondary"
                      : "disable"
                  }
                  onClick={() => {
                    // putData(seteditTeamLeadModal);
                    HubstaffClientname.id != null &&
                    BillKeyClientName.id != null &&
                    StripeClientName.id != null &&
                    HubstaffProjectName.length > 0
                      ? PostData()
                      : toast.error("Please Select All fields");
                  }}
                  className=" px-8 mx-[10px] w-[130px] bg-green-1 border text-[white] hover:bg-gray-100 font-normal text-[14px] text-center rounded-3xl"
                >
                  Save
                </Button>
              ) : (
                <Button
                  variant={
                    HubstaffClientname.id != null &&
                    BillKeyClientName.id != null &&
                    StripeClientName.id != null &&
                    HubstaffProjectName.length > 0 &&
                    HubstaffClientname2.id != null &&
                    BillKeyClientName2.id != null &&
                    StripeClientName2.id != null &&
                    HubstaffProjectName2.length > 0
                      ? "secondary"
                      : "disable"
                  }
                  onClick={() => {
                    // putData(seteditTeamLeadModal);
                    HubstaffClientname2.id != null &&
                    BillKeyClientName2.id != null &&
                    StripeClientName2.id != null &&
                    HubstaffProjectName2.length > 0
                      ? PostData()
                      : toast.error("Please Select All fields");
                  }}
                  className=" px-8 mx-[10px] w-[130px] bg-green-1 border text-[white] hover:bg-gray-100 font-normal text-[14px] text-center rounded-3xl"
                >
                  Save
                </Button>
              )}
            </div>


            <div className=" flex items-center justify-evenly  h-max ">
              <DropDownComp
                header={" HubStaff Client Name"}
                placeholder={"Select Hubstaff Client Name"}
                dropdowndata={HubstaffClientNameList}
                value={HubstaffClientname}
                setvalue={setHubstaffClientname}
                loading={loading}
              />
              <DropDownCompBillKey
                header={" Bill key Client Name"}
                placeholder={" Select Bill key Client Name"}
                dropdowndata={BillKeyClientNameList}
                value={BillKeyClientName}
                setvalue={setBillKeyClientName}
                loading={loading}
              />
              <DropDownCompStripe
                header={"   Stripe Client Name"}
                placeholder={" Select  Stripe Client Name"}
                dropdowndata={StripeClientNameList}
                value={StripeClientName}
                setvalue={setStripeClientName}
                loading={loading}
              />
              <DropDownCompHubstaffProject
                header={"  HubStaff Project Name"}
                placeholder={" Select HubStaff Project Name"}
                dropdowndata={HubstaffProjectnameList}
                value={HubstaffProjectName}
                setvalue={setHubstaffProjectName}
                loading={loading}
              />
            </div>

            {showExtraRow == true && (
              <MatchAnotherClient
                DropDownComp={DropDownComp}
                HubstaffClientNameList={HubstaffClientNameList}
                HubstaffClientname2={HubstaffClientname2}
                setHubstaffClientname2={setHubstaffClientname2}
                DropDownCompBillKey={DropDownCompBillKey}
                BillKeyClientNameList={BillKeyClientNameList}
                BillKeyClientName2={BillKeyClientName2}
                setBillKeyClientName2={setBillKeyClientName2}
                DropDownCompStripe={DropDownCompStripe}
                StripeClientNameList={StripeClientNameList}
                StripeClientName2={StripeClientName2}
                setStripeClientName2={setStripeClientName2}
                DropDownCompHubstaffProject={DropDownCompHubstaffProject}
                HubstaffProjectnameList={HubstaffProjectnameList}
                HubstaffProjectName2={HubstaffProjectName2}
                setHubstaffProjectName2={setHubstaffProjectName2}
                loading={loading}
              />
            )}
            <div className=" flex flex-row  px-[20px] pt-4 mt-4 justify-start h-[150px]   ">
             
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
