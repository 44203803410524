import React, { useState } from "react";
import AppContext from "./AppContext";


const AppState = (props) => {
  const [name, setname] = useState("");
  const [login, setlogin] = useState(false);
  const [startingDateFilter, setStartingDateFilter] = useState("");
  const [endingDateFilter, setEndingDateFilter] = useState("");
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [FilterApplied, setFilterApplied] = useState(false);
  const [CurrntActiveTab, setCurrntActiveTab] = useState("");
  const [userPic, setUserPic] = useState(null);
  const [userName, setUserName] = useState(null)
  const [date, setDate] = useState(undefined);

  // Bill key page client filter
  const [BillKeySelectedClientsFilter, setBillKeySelectedClientsFilter] =
    useState([]);

  // Below are for the unmatched  filter

  // StripeAccountNameFilter
  const [
    BillKeySelectedStripeAccountFilter,
    setBillKeySelectedStripeAccountFilter,
  ] = useState([]);

  // ProjectNameFilter
  const [
    BillKeySelectedProjectNameFilter,
    setBillKeySelectedProjectNameFilter,
  ] = useState([]);

  // BillKeyAccountNameFilter
  const [
    BillKeySelectedBillKeyAccountNameFilter,
    setBillKeySelectedBillKeyAccountNameFilter,
  ] = useState([]);
  //

  // invoice reporting
  const [invoiceReportingClientFilter, setInvoiceReportingClientFilter] = useState([]);
  const [invoiceReportingStatusFilter, setInvoiceReportingStatusFilter] = useState([]);
  const [invoiceReportingEmailFilter, setInvoiceReportingEmailFilter] = useState([]);
  const [invoiceReportingAutoBillFilter, setInvoiceReportingAutoBillFilter] = useState([]);
  const [invoiceReportingNetTermFilter, setInvoiceReportingNetTermFilter] = useState([]);
  const [invoiceReportingHowWeBillFilter, setInvoiceReportingHowWeBillFilter] = useState([]);

  // Client Filter Invoice Reporting
const [ProcessBillingSelectedClientFilter, setProcessBillingSelectedClientFilter] = useState([])
// Apply Filters
  const [ApplyfilterBillKeyUnmatched, setApplyfilterBillKeyUnmatched] =
    useState(false);
  const [ApplyfilterBillKeyMatched, setApplyfilterBillKeyMatched] =
    useState(false);

    const [ApplyfilterProcessBilling, setApplyfilterProcessBilling] = useState(false)

    const [ApplyFilterInvoiceReporting, setApplyFilterInvoiceReporting] = useState(false)


// Single Date Select in Historic Invoices

const [DateSelected, setDateSelected] = useState()




  const LogOutUser = () => {
    console.log("logingout");
    localStorage.clear("auth_token");
    localStorage.clear("user_name");


    setlogin(false);
    setUserName(null);
    setUserPic(null);
  };

  const clearAllFilters = () => {
    // setStartingDateFilter("");
    // setEndingDateFilter("");
    setBillKeySelectedClientsFilter([]);
    setBillKeySelectedProjectNameFilter([]);
    setBillKeySelectedBillKeyAccountNameFilter([]);
    setBillKeySelectedStripeAccountFilter([])
    // setDate(undefined)
    // setProcessBillingSelectedClientFilter([])
  };


  // Below are the sorting variables

  const [SortBy, setSortBy] = useState('client_name')
  const [SortOrder, setSortOrder] = useState('asc')



  return (
    <AppContext.Provider
      value={{
        name,
        setname,
        login,
        setlogin,
        startingDateFilter,
        setStartingDateFilter,
        endingDateFilter,
        setEndingDateFilter,
        isDateOpen,
        setIsDateOpen,
        FilterApplied,
        setFilterApplied,
        CurrntActiveTab,
        setCurrntActiveTab,
        BillKeySelectedClientsFilter,
        setBillKeySelectedClientsFilter,
        BillKeySelectedStripeAccountFilter,
        setBillKeySelectedStripeAccountFilter,
        BillKeySelectedProjectNameFilter,
        setBillKeySelectedProjectNameFilter,
        BillKeySelectedBillKeyAccountNameFilter,
        setBillKeySelectedBillKeyAccountNameFilter,
        ApplyfilterBillKeyUnmatched,
        setApplyfilterBillKeyUnmatched,
        ApplyfilterBillKeyMatched,
        setApplyfilterBillKeyMatched,
        clearAllFilters,
        LogOutUser,
        invoiceReportingClientFilter, 
        setInvoiceReportingClientFilter,
        invoiceReportingStatusFilter, 
        setInvoiceReportingStatusFilter,
        invoiceReportingEmailFilter, 
        setInvoiceReportingEmailFilter,
        invoiceReportingAutoBillFilter, 
        setInvoiceReportingAutoBillFilter,
        invoiceReportingNetTermFilter, 
        setInvoiceReportingNetTermFilter,
        invoiceReportingHowWeBillFilter, 
        setInvoiceReportingHowWeBillFilter,
        ApplyfilterProcessBilling,
        setApplyfilterProcessBilling,
        ProcessBillingSelectedClientFilter,
        setProcessBillingSelectedClientFilter,
        ApplyFilterInvoiceReporting,
        setApplyFilterInvoiceReporting,
        userPic, 
        setUserPic,
        userName, 
        setUserName,
        DateSelected,
        setDateSelected,
        date,
        setDate,
        SortBy,
        setSortBy,
        SortOrder,
        setSortOrder

      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
