import { Tabs, Tab } from "../Tabs.js";

import { DatePickerWithRange } from "../DateFilter.tsx";
import filterIcon from "../../assests/index.jpeg";
import ClientFilter from "../Filters/InvoiceReporting/Client.js";
import PastInvoiceTable from "./PastInvoiceTable.js";
import { CheckIcon, ChevronUp, X, ChevronDown } from "lucide-react";
import DownloadCSVButton from "../ui/DownloadCSVButton";
import ManuallyBillingInvoicesTable from "./ManuallyBillingInvoiceTable.js";
import StatusFilter from "../Filters/InvoiceReporting/Status.js";
import EmailFilter from "../Filters/InvoiceReporting/Email.js";
import AutoBillFilter from "../Filters/InvoiceReporting/AutoBill.js";
import NetTermFilter from "../Filters/InvoiceReporting/NetTerm.js";
import HowWeBillFilter from "../Filters/InvoiceReporting/HowWeBill.js";
import StripeInvoiceTable from "./StripeInvoices.js";
import Apply from "../Filters/Apply.js";
import { useContext } from "react";
import AppContext from "../../context/AppContext.js";
import ClearFilter from "../Filters/ClearFilter.js";
import ClearAll from "../Filters/ClearAll.js";
import { useEffect } from "react";
// import DownloadCSVButton from '../'
export default function Invoicereporting() {
  const {
    ApplyFilterInvoiceReporting,
    setApplyFilterInvoiceReporting,
    setInvoiceReportingClientFilter,
    setInvoiceReportingStatusFilter,
    setInvoiceReportingEmailFilter,
    setInvoiceReportingAutoBillFilter,
    setInvoiceReportingNetTermFilter,
    setInvoiceReportingHowWeBillFilter,
    invoiceReportingClientFilter,
    invoiceReportingStatusFilter,
    invoiceReportingEmailFilter,
    invoiceReportingAutoBillFilter,
    invoiceReportingNetTermFilter,
    invoiceReportingHowWeBillFilter,
    setStartingDateFilter,
    setEndingDateFilter,
    setDate,
  } = useContext(AppContext);

  const clearFilters = () => {
    setInvoiceReportingClientFilter([]);
    setInvoiceReportingStatusFilter([]);
    setInvoiceReportingEmailFilter([]);
    setInvoiceReportingAutoBillFilter([]);
    setInvoiceReportingNetTermFilter([]);
    setInvoiceReportingHowWeBillFilter([]);
    setStartingDateFilter("");
    setEndingDateFilter("");
    setDate(undefined);
  };

  useEffect(() => {
    if (
      invoiceReportingClientFilter.length === 0 &&
      invoiceReportingStatusFilter.length === 0 &&
      invoiceReportingEmailFilter.length === 0 &&
      invoiceReportingAutoBillFilter.length === 0 &&
      invoiceReportingNetTermFilter.length === 0 &&
      invoiceReportingHowWeBillFilter.length === 0
    ) {
      setApplyFilterInvoiceReporting((prev) => !prev);
    }
  }, [
    invoiceReportingClientFilter,
    invoiceReportingStatusFilter,
    invoiceReportingEmailFilter,
    invoiceReportingAutoBillFilter,
    invoiceReportingNetTermFilter,
    invoiceReportingHowWeBillFilter,
  ]);

  useEffect(() => {
    clearFilters()
  }, [])
  

  return (
    // <div className="pl-[24px]">
    //   <Header />
    //   <FilterView />
    // </div>
    <div className="flex bg-[#F1F5F5] text-main-text  w-full h-screen overflow-y-scroll">
      <div className="flex flex-col space-y-6 w-[98%] mx-auto p-8">
        <div className="font-bold text-2xl ">Invoice Reporting</div>
        <div className=" gap-3 flex flex-col">
          <div className="flex items-center align-middle space-x-3  w-full">
            <div className="flex flex-wrap items-center  space-x-4   ">
              {/* <div className=" flex items-center">
              <div>
                <img src={filterIcon} className=" w-5" />
              </div>{" "}
              <div className=" text-[14px] font-bold">Filters:</div>
            </div> */}
              <DatePickerWithRange />
              <ClientFilter />
              <StatusFilter />
              <EmailFilter />
              <AutoBillFilter />
              <NetTermFilter />
              <HowWeBillFilter />
            </div>
          </div>
          <div className=" gap-2 flex w-full ">
            <Apply
              className={`text-white px-4 h-8 bg-[#69C920]`}
              onChange={() =>
                setApplyFilterInvoiceReporting(!ApplyFilterInvoiceReporting)
              }
            ></Apply>
            <ClearAll
              className={`text-[black] px-4 h-8 bg-[white] border`}
              onChange={() => clearFilters()}
            />
          </div>
        </div>
        <div className="flex flex-col pb-7">
          <Tabs>
            <Tab data-label="All Past Invoices">
              <PastInvoiceTable />
            </Tab>
            <Tab data-label="Manually Billing Invoices">
              <ManuallyBillingInvoicesTable />
            </Tab>
            <Tab data-label="Stripe Invoices">
              <StripeInvoiceTable />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
