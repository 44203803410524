import Modal from "react-modal";
import { Button } from "../ui/button.tsx";
import { useEffect, useState, useRef } from "react";
import { ChevronDown, Star } from "lucide-react";
import { X } from "lucide-react";
import { Skeleton } from "../ui/skeleton.tsx";
import axios from "axios";
import toast from "react-hot-toast";

const DropDownCompMultiselect = ({ value, setvalue, dropdowndata }) => {
  const [dropdownopen, setdropdownopen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setdropdownopen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Function to handle selection from the dropdown
  const handleSelect = (item) => {
    // If the item is already selected, remove it from the value array
    console.log("value", value);
    console.log("typeof", typeof value);

    if (value?.length < 3) {
      // If less than 3 items are selected, add the item to the value array
      setvalue([...value, item]);
    } else {
      // setvalue(value?.filter((v) => v !== item));
      let a = [...value];
      a.shift();
      a.push(item);
      setvalue(a);
    }
  };

  return (
    <div
      ref={dropdownRef}
      className="relative cursor-pointer flex flex-col gap-1 justify-center"
    >
      <div
        onClick={() => setdropdownopen(!dropdownopen)}
        className="bg-[#FBFBFB] text-[14px] font-normal flex items-center justify-between border-2 rounded-full w-full px-3 py-2"
      >
        {/* Display selected values separated by commas */}

        <div className=" w-[80%] text-[12px]"> {value?.join(", ")}</div>

        {dropdownopen ? (
          <ChevronDown className="duration-500 rotate-180" />
        ) : (
          <ChevronDown className="duration-500 rotate-0" />
        )}
      </div>
      {dropdownopen && (
        <div className="overflow-hidden flex-col absolute top-[45px] z-50 flex justify-center border rounded-b-2xl w-full bg-white">
          <div className="w-full max-h-[155px] overflow-y-scroll">
            {dropdowndata.map((item) => (
              <div
                key={item}
                onClick={() => handleSelect(item)}
                className={`w-full border-b px-4 py-2 hover:bg-slate-100 ${
                  value.includes(item) ? "bg-slate-200" : ""
                }`}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const DropDownCompHubstaff = ({ value, setvalue }) => {
  const [dropdownopen, setdropdownopen] = useState(false);
  const [serachQuery, setserachQuery] = useState("");

  const dropdownRef = useRef(null);
  const [loading, setloading] = useState(true);
  const [Data, setData] = useState([]);
  const [dropdowndata, setdropdowndata] = useState([]);

  const onSearchChange = (query) => {
    console.log("query", query);
    if (query == "") {
      setData(dropdowndata);
    } else {
      let a = [...dropdowndata];
      a = a.filter((item) =>
        // String(item?.name)?.includes(query)
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      console.log("a", a);
      setData(a);
    }

    setserachQuery(query);
  };

  const getData = async () => {
    try {
      setloading(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url =
        process.env.REACT_APP_BASE_URL + `/edit-hubstaff-projects-filter`;

      const res = await axios.get(url, config);

      console.log("res", res.data);

      if (res.status == 200) {
        setData(res?.data);
        setdropdowndata(res?.data);
        setloading(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setdropdownopen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Function to handle selection from the dropdown
  const handleSelect = (item) => {
    // If the item is already selected, remove it from the value array
    console.log("value", value);
    console.log("item", item);
    console.log("typeof", typeof item);

    if (value?.includes(item)) {
      setvalue(value?.filter((v) => v !== item));
    } else {
      setvalue([...value, item]);
    }
  };

  return (
    <div
      ref={dropdownRef}
      className="relative cursor-pointer flex flex-col gap-1 justify-center"
    >
      <div className="bg-[#FBFBFB] text-[14px] font-normal flex items-center justify-between border-2 rounded-full w-full px-3 py-2">
        {/* Display selected values separated by commas */}

        <div className=" w-[90%] flex gap-2 overflow-x-scroll h-[20px] no-scrollbar">
          {" "}
          {value?.map((item) => (
            <div className="  flex-shrink-0 text-[11px] flex items-center justify-center bg-[#69C920] rounded-full px-2 text-white">
              {item.name}
              <X
                onClick={() => handleSelect(item)}
                size={15}
                strokeWidth={2}
                color="white"
                className=" cursor-pointer ml-2"
              />{" "}
            </div>
          ))}
        </div>

        {dropdownopen ? (
          <ChevronDown
            onClick={() => setdropdownopen(!dropdownopen)}
            className="duration-500 rotate-180"
          />
        ) : (
          <ChevronDown
            onClick={() => setdropdownopen(!dropdownopen)}
            className="duration-500 rotate-0"
          />
        )}
      </div>
      {dropdownopen == true &&
        (loading == true ? (
          <div className="overflow-hidden h-[80px] items-center flex-col absolute top-[45px] z-50 flex justify-center border rounded-b-2xl w-full bg-white">
            <Skeleton className=" w-full h-full" />
          </div>
        ) : (
          <div className="overflow-hidden flex-col absolute top-[45px] z-50 flex justify-center border rounded-b-2xl w-full bg-white">
            <input
              value={serachQuery}
              className=" w-full border h-12 px-2 outline-none text-[14px] "
              placeholder=" search"
              onChange={(e) => onSearchChange(e.target.value)}
            />

            <div className="w-full max-h-[125px] overflow-y-scroll">
              {Data.map((item) => (
                <div
                  // key={item}
                  onClick={() => handleSelect(item)}
                  className={`w-full border-b px-4 py-2 hover:bg-slate-100 text-[12px] ${
                    value.includes(item) ? "bg-slate-200" : ""
                  }`}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

const DropDownComp = ({ header, value, setvalue, dropdowndata }) => {
  const [dropdownopen, setdropdownopen] = useState(false);
  const [serachQuery, setserachQuery] = useState("");
  const dropdownRef = useRef(null);

  function formatString(input) {
    return input
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setdropdownopen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      className=" relative cursor-pointer  flex flex-col  gap-1 justify-center"
    >
      <div
        onClick={() => setdropdownopen(!dropdownopen)}
        className="  bg-[#FBFBFB] text-[12px] font-normal flex items-center justify-between border-2 rounded-full w-full px-3  py-2 "
      >
        {formatString(value)}
        {dropdownopen == false ? (
          <ChevronDown className="duration-500 rotate-0" />
        ) : (
          <ChevronDown className="duration-500 rotate-180" />
        )}
      </div>
      {dropdownopen && (
        <div className=" overflow-hidden flex-col  z-20 absolute top-[45px] flex justify-center   border rounded-b-2xl rounded w-full bg-white">
          <div className=" w-full max-h-[145px]     overflow-y-scroll">
            {dropdowndata.map((item) => (
              <div
                onClick={() => [setvalue(item), setdropdownopen(false)]}
                className=" w-full border-b px-4 py-2 hover:bg-slate-100 text-[14px]"
              >
                {formatString(item)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default function AllMatchedClientsModal({
  showModal,
  setshowModal,
  clientInfo,
  setclientInfo,
  showArchiveClientModal,
  setshowArchiveClientModal,

  getData,
}) {
  const customStyles2 = {
    content: {
      top: "auto",
      left: "auto",
      right: "0%",
      bottom: "auto",
      outline: "none",
    },
  };

  const [companyName, setcompanyName] = useState(clientInfo?.client_name);
  const [EmailAddress, setEmailAddress] = useState(clientInfo?.email);
  const [OriginalFullTimeBillRate, setOriginalFullTimeBillRate] = useState("-");
  const [BillingStatus, setBillingStatus] = useState("");
  const [FullTimeBillRate, setFullTimeBillRate] = useState("-");
  const [GoogleDriveFolder, setGoogleDriveFolder] = useState("-");
  const [WhoToCC, setWhoToCC] = useState("-");
  const [HowWeBill, setHowWeBill] = useState("-");
  const [DonTAutoBill, setDonTAutoBill] = useState();
  const [NetTermsPayDelay, setNetTermsPayDelay] = useState();
  const [BillingRateRange, setBillingRateRange] = useState();
  const [PaymentMethod, setPaymentMethod] = useState();
  const [Detail, setDetail] = useState();
  const [RateIncreaseNotes, setRateIncreaseNotes] = useState("-");
  const [CSM, setCSM] = useState();
  const [HubstaffProjectName, setHubstaffProjectName] = useState([]);
  const [StripeCustomerID, setStripeCustomerID] = useState("");
  const [QuickBooksID, setQuickBooksID] = useState("");

  const [StartDate, setStartDate] = useState();
  const [CancelledDate, setCancelledDate] = useState("-");
  const [ActiveInactive, setActiveInactive] = useState("-");
  const [TotalInvoiceAmount, setTotalInvoiceAmount] = useState();
  const [TotalHoursWorked, setTotalHoursWorked] = useState();
  const [ExpansionCost, setExpansionCost] = useState("-");
  const [OnboardingFee, setOnboardingFee] = useState("-");
  const [ClientIdDb, setClientIdDb] = useState();
  const [ClientIdStripe, setClientIdStripe] = useState();

  const [loading, setloading] = useState(false);

  useEffect(() => {
    setcompanyName(clientInfo?.client_name);
    setEmailAddress(clientInfo?.email);
    setBillingStatus(clientInfo?.billing_status);
    setOriginalFullTimeBillRate(clientInfo?.original_full_time_bill_rate);
    setFullTimeBillRate(clientInfo?.full_time_bill_rate);
    setGoogleDriveFolder(clientInfo?.google_drive_folder);
    setWhoToCC(clientInfo?.who_to_cc_on_email);
    setHowWeBill(clientInfo?.how_we_bill);
    setDonTAutoBill(clientInfo?.dont_auto_bill);
    setNetTermsPayDelay(clientInfo?.net_terms_pay_delay);
    // clientInfo?.bill_rate
    //   ? setBillingRateRange(clientInfo.bill_rate.split(",").map(Number))
    //   : setBillingRateRange([]);
    setBillingRateRange(clientInfo?.bill_rate);
    setPaymentMethod(clientInfo?.payment_method);
    setDetail(clientInfo?.detail);
    setRateIncreaseNotes(clientInfo?.rate_increase_notes);
    setCSM(clientInfo?.csm);
    setStartDate(clientInfo?.start_date);
    setCancelledDate(clientInfo?.cancelled_date);
    setActiveInactive(clientInfo?.active_inactive);
    setTotalInvoiceAmount(clientInfo?.total_invoice_amount);
    setTotalHoursWorked(clientInfo?.total_hours_worked);
    setExpansionCost(clientInfo?.expansion_cost);
    setOnboardingFee(clientInfo?.onboarding_fee);
    setClientIdDb(clientInfo?.id);
    setClientIdStripe(clientInfo?.clienafasasgagaga);
    setHubstaffProjectName(clientInfo?.hubstaff_projects);
    setStripeCustomerID(clientInfo?.stripe_customer_id);
    setQuickBooksID(clientInfo?.qb_id);

    console.log("clientInfo", clientInfo);
  }, [showModal]);

  const EditClientBillKey = async () => {
    try {
      setloading(true);

      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let billing_Status = BillingStatus;

      console.log("billing_Status", billing_Status);

      // if (billing_Status.toLowerCase == "invoice to bill") {
      //   billing_Status = "invoice_to_bill";
      // }
      // if (billing_Status.toLowerCase() == "invoice to send") {
      //   billing_Status = "invoice_to_send";
      // }
      // if (billing_Status.toLowerCase() == "pending manual review") {
      //   billing_Status = "pending_manual_review";
      // }
      const body = {
        google_drive_folder: GoogleDriveFolder,
        who_to_cc_on_email: WhoToCC,
        how_we_bill: HowWeBill,
        dont_auto_bill: DonTAutoBill,
        net_terms_pay_delay: NetTermsPayDelay,
        // bill_rate: String(BillingRateRange?.join(",")),
        bill_rate: BillingRateRange,

        payment_method: PaymentMethod,
        detail: Detail,
        rate_increase_notes: RateIncreaseNotes,
        csm: CSM,
        qb_id: QuickBooksID,
        billing_status: billing_Status,
        hubstaff_project_ids: HubstaffProjectName.map((item) => item.id),
        original_full_time_bill_rate: OriginalFullTimeBillRate,
      };

      console.log("body", body);

      const url = `${process.env.REACT_APP_BASE_URL}/update-client-bill-key?id=${clientInfo?.id}`;

      console.log("url", url);

      const res = await axios.post(url, body, config);

      console.log("res", res.data);
      if (res.status == 200) {
        console.log("res", res);

        setshowModal(false);
        getData(1);
        toast.success("Client Updated Successfully");
      }
    } catch (error) {
      setloading(false);

      console.log("error", error?.response);

      toast.error("error");
    }
  };

  if (loading == true) {
    return (
      <Modal
        isOpen={showModal}
        onRequestClose={() => {
          setshowModal(false);
        }}
        style={customStyles2}
        shouldCloseOnOverlayClick={false}
        className="fixed inset-0 flex items-center justify-center z-10 font-poppins"
        overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
      >
        <div className="flex  flex-col justify-between   bg-white relative  rounded shadow-lg  h-[100vh] w-[45vw] max-w-[800px] ">
          <div className=" flex w-full h-full items-center justify-center absolute text-[40px] text-slate-400">
            Updating Info
          </div>
          <Skeleton className=" w-full h-full" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => {
        setshowModal(false);
      }}
      style={customStyles2}
      shouldCloseOnOverlayClick={false}
      className="fixed inset-0 flex items-center justify-center z-10 font-poppins"
      overlayClassName="fixed inset-0 blur-bg backdrop-blur-sm"
      //   ariaHideApp={false}
    >
      <div className="flex  flex-col justify-between   bg-white  rounded shadow-lg  h-[100vh] w-[45vw] max-w-[800px] ">
        <div className=" w-full h-[100vh] ">
          <div className="  h-[144px] ">
            <h2 className="text-2xl flex items-center justify-between font-semibold text-main-text px-[20px]   border-b-2">
              <div className=" pl-2 py-4">Edit Client Bill key Settings</div>
              <div>
                <X
                  className=" cursor-pointer"
                  onClick={() => {
                    setshowModal(false);
                  }}
                />
              </div>
            </h2>
            <div className=" flex flex-row px-[20px] border-b-2 ">
              <div className=" w-[50%]  flex flex-row py-4  ">
                <Button
                  onClick={() => {
                    setshowArchiveClientModal(true);
                  }}
                  className="py-3 px-8 mx-[10px] w-[210px] bg-white border text-[#163143] hover:bg-gray-100 font-normal text-base text-center rounded-3xl"
                >
                  Archive Client
                </Button>
              </div>
              <div className=" w-full flex flex-row justify-end py-4 ">
                <Button
                  onClick={() => {
                    setshowModal(false);
                  }}
                  className="py-3 px-8 mx-[10px] w-[130px] bg-white border text-[#163143] hover:bg-gray-100 font-normal text-base text-center rounded-3xl"
                >
                  Cancel
                </Button>
                <Button
                  variant={"secondary"}
                  onClick={() => {
                    EditClientBillKey();
                  }}
                  className="py-3 bg-[#69C920]  px-8 mx-[10px] w-[130px] bg-green-1 border text-[white]  font-normal text-base text-center rounded-3xl"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          <div className=" px-[20px] flex-col w-full overflow-y-scroll  h-full pb-[200px]   ">
            <div className=" px-4 mt-4">
              <div>
                <div className=" flex flex-row font-semibold text-[14px]">
                  Company Name <div className=" text-red-500">*</div>
                </div>
                <input
                  disabled
                  className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                  value={companyName}
                  onChange={(e) => setcompanyName(e.target.value)}
                />
              </div>
            </div>
            <div className=" px-4 mt-4">
              <div>
                <div className="flex flex-row font-semibold text-[14px]">
                  Email Address
                  <div className=" text-red-500">*</div>
                </div>
                <input
                  disabled
                  className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                  value={EmailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </div>
            </div>
            <div className=" px-4  mt-4">
              <div className="flex font-semibold text-[14px]">
                Billing Rate Range <div className=" text-red-500">*</div>
                <div className=" text-slate-500 pl-2 text-sm font-extralight">
                  {`Input Format: ( 13,14,15 )`}
                </div>
              </div>
              <input
                value={BillingRateRange}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^(\d{1,3}(\.\d{0,2})?,?){0,3}$/.test(value)) {
                    setBillingRateRange(value);
                  }
                }}
                className="w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
                placeholder="13,14,15"
              />

              {/* <DropDownCompMultiselect
                value={BillingRateRange}
                setvalue={setBillingRateRange}
                dropdowndata={[
                  5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                ]}
              /> */}
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Original Full Time Bill Rate
              </div>
              <input
                value={OriginalFullTimeBillRate}
                onChange={(e) => setOriginalFullTimeBillRate(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Billing Status <div className=" text-red-500">*</div>
              </div>
              <DropDownComp
                value={BillingStatus}
                setvalue={setBillingStatus}
                dropdowndata={[
                  "invoice_to_bill",
                  "invoice_to_send",
                  "pending_manual_review",
                ]}
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                How We Bill? <div className=" text-red-500">*</div>
              </div>
              <DropDownComp
                value={HowWeBill}
                setvalue={setHowWeBill}
                dropdowndata={["Bill.com", "Stripe", "Manual"]}
              />
            </div>
            <div className=" px-4  mt-4">
              <div className="font-semibold flex text-[14px]">
                Don't Auto Bill<div className=" text-red-500">*</div>
              </div>
              {/* <input
                value={DonTAutoBill}
                onChange={(e) => setDonTAutoBill(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4"
              /> */}
              <DropDownComp
                value={DonTAutoBill}
                setvalue={setDonTAutoBill}
                dropdowndata={["Auto Bill", `Don't Auto Bill`, "Manual"]}
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Payment Method <div className=" text-red-500">*</div>
              </div>
              <DropDownComp
                value={PaymentMethod}
                setvalue={setPaymentMethod}
                dropdowndata={["Prepaid", "ACH", "Bill.com", `Credit Card`]}
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Net terms/Pay Delay<div className=" text-red-500">*</div>
              </div>
              <input
                value={NetTermsPayDelay}
                onChange={(e) => setNetTermsPayDelay(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Details
              </div>
              <input
                value={Detail}
                onChange={(e) => setDetail(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Rate Increase Notes
              </div>
              <input
                value={RateIncreaseNotes}
                onChange={(e) => setRateIncreaseNotes(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Who to CC on Invoices
              </div>
              <input
                value={WhoToCC}
                onChange={(e) => setWhoToCC(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>

            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                CSM <div className=" text-red-500">*</div>
              </div>
              <input
                value={CSM}
                onChange={(e) => setCSM(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Google Drive Folder <div className=" text-red-500">*</div>
              </div>
              <input
                value={GoogleDriveFolder}
                onChange={(e) => setGoogleDriveFolder(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>

            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Hubstaff Project Name <div className=" text-red-500">*</div>
              </div>
              <DropDownCompHubstaff
                value={HubstaffProjectName}
                setvalue={setHubstaffProjectName}
                // dropdowndata={["Bill.com", "Stripe", "Manual"]}
              />
            </div>

            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                Stripe ID<div className=" text-red-500">*</div>
              </div>
              <input
                disabled
                value={StripeCustomerID}
                onChange={(e) => setStripeCustomerID(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>
            <div className=" flex flex-col w-full px-4 mt-4">
              <div className="  flex flex-row font-semibold text-[14px]">
                QuickBooks ID <div className=" text-red-500">*</div>
              </div>
              <input
                // disabled
                value={QuickBooksID}
                onChange={(e) => setQuickBooksID(e.target.value)}
                className=" w-full rounded-3xl h-10 bg-[#FBFBFB] border px-4 text-[12px]"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
