import { create } from "zustand";
import { User } from "./Authstore";

export interface SavedFilter {
  name: string;
  selectedNames: string[];
}

export interface AgentIdAndNameDict {
  [key: string]: any;
}

interface State {
  filterAgentIdAndName: any[];
  isLoading: boolean;
  error: string | null;
  filterClientName: string[];
  filterDeptName: string[];
  filterAgentsName: string[];
  filterTeamLeadsName: string[];
  filterOMsName: string[];
  filterCSMsName: string[];
  filterCSMTeamManagers: string[];
  filterDeptManagerNames: string[];
  filterInternalTeamMembers: string[];
  filterTeamManagers: string[];
  filterTeamMembers: string[];
  filterMyTeamDeptNames: string[];
  filterMyTeamTeamLeads: string[];

  startingDateFilter: string;
  endingDateFilter: string;
  isDate: boolean;
  isDeptOpen: boolean;
  isCSTeamManagerOpen: boolean;
  isCSMOpen: boolean;
  isTeamLeadsOpen: boolean;
  isOpsManager: boolean;
  isDateOpen: boolean;
  isClientOpen: boolean;
  isAgentOpen: boolean;
  isDeptManagersOpen: boolean;
  isInternalTeamMembersOpen: boolean;
  isTeamManagersOpen: boolean;
  isTeamMembersOpen: boolean;

  isMyTeamDeptNamesOpen: boolean;
  isMyTeamTeamLeadsOpen: boolean;

  displayOMNames: string[];
  // agentSavedFilters: SavedFilter[];
  clientSavedFilters: SavedFilter[];
  agentSavedFilters: SavedFilter[];
  teamLeadSavedFilters: SavedFilter[];

  isAgentIdAndNameOpen: boolean;
}

interface Actions {
  addAgentIdAndName: (agent_data: any[]) => void;
  addClientNames: (name: string[]) => void;
  addDeptNames: (name: string[]) => void;
  addAgentsNames: (name: string[]) => void;
  addTeamLeadsNames: (name: string[]) => void;
  addOmsNames: (name: string[]) => void;
  addCsmsNames: (name: string[]) => void;
  addDeptManagersNames: (name: string[]) => void;
  addInternalTeamMembers: (name: string[]) => void;
  addstartingdate: (date: string) => void;
  addendingdate: (date: string) => void;
  addCSTeamManagers: (csTeamManagers: string[]) => void;
  addTeamManagers: (teamManagers: string[]) => void;
  addTeamMembers: (teamMembers: string[]) => void;
  addMyTeamDeptNames: (deptNames: string[]) => void;
  addMyTeamTeamLeads: (teamLeads: string[]) => void;

  setIsDeptOpen: (isOpen: boolean) => void;
  setIsCSTeamManagerOpen: (isOpen: boolean) => void;
  setIsCSMOpen: (isOpen: boolean) => void;
  setIsTeamLeadsOpen: (isOpen: boolean) => void;
  setIsOpsManager: (isOpen: boolean) => void;
  setIsDateOpen: (isOpen: boolean) => void;
  setIsClientOpen: (isOpen: boolean) => void;
  setIsAgentOpen: (isOpen: boolean) => void;
  setIsDate: (isDate: boolean) => void;
  setIsDeptManagersOpen: (isOpen: boolean) => void;
  setIsInternalTeamMembersOpen: (isOpen: boolean) => void;

  setIsTeamManagersOpen: (isOpen: boolean) => void;
  setIsTeamMembersOpen: (isOpen: boolean) => void;
  setIsMyTeamDeptNamesOpen: (isOpen: boolean) => void;
  setIsMyTeamTeamLeadsOpen: (isOpen: boolean) => void;

  addDisplayOMNames: (name: string[]) => void;
  addClientSavedFilter: (savedFilter: SavedFilter) => void;
  editClientSavedFilter: (index: number, selectedNames: string[]) => void;
  setClientSavedFilter: (savedFilter: SavedFilter[]) => void;
  addAgentSavedFilter: (savedFilter: SavedFilter) => void;
  editAgentSavedFilter: (index: number, selectedNames: string[]) => void;
  setAgentSavedFilter: (savedFilter: SavedFilter[]) => void;
  addTeamLeadSavedFilter: (savedFilter: SavedFilter) => void;
  editTeamLeadSavedFilter: (index: number, selectedNames: string[]) => void;
  setTeamLeadSavedFilter: (savedFilter: SavedFilter[]) => void;

  setIsAgentIdAndNameOpen: (isOpen: boolean) => void;

  clearFilters: () => void;
}

const savedFiltersData = localStorage.getItem("client_saved_filters");
const parseClientdSavedFilters: SavedFilter[] = savedFiltersData
  ? JSON.parse(savedFiltersData)
  : [];

const savedAgentFiltersData = localStorage.getItem("agent_saved_filters");
const parseAgentSavedFilters: SavedFilter[] = savedAgentFiltersData
  ? JSON.parse(savedAgentFiltersData)
  : [];

const savedTeamLeadFiltersData = localStorage.getItem(
  "team_lead_saved_filters"
);

const parseTeamLeadSavedFilters: SavedFilter[] = savedTeamLeadFiltersData
  ? JSON.parse(savedTeamLeadFiltersData)
  : [];

const userJSON = localStorage.getItem("talentPOP_user");
console.log(userJSON);

const user = userJSON ? (JSON.parse(userJSON) as User) : null;

console.log("USER");
console.log(user);

const INITIAL_STATE: State = {
  filterAgentIdAndName: [],
  isLoading: false,
  error: null,
  filterClientName: [],
  filterDeptName: [],
  filterAgentsName: [],
  filterTeamLeadsName: user?.role === "tl" ? [user.name] : [],
  filterOMsName: user?.role === "om" ? [user.name] : [],
  filterCSMsName: user?.role === "csm" ? [user.name] : [],
  filterCSMTeamManagers: [],
  filterDeptManagerNames: [],
  filterInternalTeamMembers: [],
  filterTeamManagers: [],
  filterTeamMembers: [],
  filterMyTeamDeptNames: [],
  filterMyTeamTeamLeads: user?.role === "tl" ? [user.name] : [],

  startingDateFilter: "",
  endingDateFilter: "",
  isDeptOpen: false,
  isCSTeamManagerOpen: false,
  isCSMOpen: false,
  isTeamLeadsOpen: false,
  isOpsManager: false,
  isDateOpen: false,
  isClientOpen: false,
  isDate: false,
  isAgentOpen: false,
  isDeptManagersOpen: false,
  isInternalTeamMembersOpen: false,
  isTeamManagersOpen: false,
  isTeamMembersOpen: false,
  isMyTeamDeptNamesOpen: false,
  isMyTeamTeamLeadsOpen: false,

  displayOMNames: user?.role == "om" ? [user.name] : [],
  clientSavedFilters: parseClientdSavedFilters,
  agentSavedFilters: parseAgentSavedFilters,
  teamLeadSavedFilters: parseTeamLeadSavedFilters,

  isAgentIdAndNameOpen: false,
};

console.log("INITIAL_STATE.filterTeamLeadsName");
console.log(INITIAL_STATE.filterTeamLeadsName);

export const useFiltersStore = create<State & Actions>((set) => ({
  filterAgentIdAndName: INITIAL_STATE.filterAgentIdAndName,
  isLoading: INITIAL_STATE.isLoading,
  error: INITIAL_STATE.error,
  filterClientName: INITIAL_STATE.filterClientName,
  filterDeptName: INITIAL_STATE.filterDeptName,
  filterAgentsName: INITIAL_STATE.filterAgentsName,
  filterTeamLeadsName: INITIAL_STATE.filterTeamLeadsName,
  filterOMsName: INITIAL_STATE.filterOMsName,
  filterCSMsName: INITIAL_STATE.filterCSMsName,
  filterCSMTeamManagers: INITIAL_STATE.filterCSMTeamManagers,
  filterDeptManagerNames: INITIAL_STATE.filterDeptManagerNames,
  filterInternalTeamMembers: INITIAL_STATE.filterInternalTeamMembers,
  filterTeamManagers: INITIAL_STATE.filterTeamManagers,
  filterTeamMembers: INITIAL_STATE.filterTeamMembers,
  startingDateFilter: INITIAL_STATE.startingDateFilter,
  endingDateFilter: INITIAL_STATE.endingDateFilter,
  isDeptOpen: INITIAL_STATE.isDeptOpen,
  isCSTeamManagerOpen: INITIAL_STATE.isCSTeamManagerOpen,
  isCSMOpen: INITIAL_STATE.isCSMOpen,
  isTeamLeadsOpen: INITIAL_STATE.isTeamLeadsOpen,
  isOpsManager: INITIAL_STATE.isOpsManager,
  isDateOpen: INITIAL_STATE.isDateOpen,
  isClientOpen: INITIAL_STATE.isClientOpen,
  isAgentOpen: INITIAL_STATE.isAgentOpen,
  isDeptManagersOpen: INITIAL_STATE.isDeptManagersOpen,
  isInternalTeamMembersOpen: INITIAL_STATE.isInternalTeamMembersOpen,
  isDate: INITIAL_STATE.isDate,
  isTeamManagersOpen: INITIAL_STATE.isTeamManagersOpen,
  isTeamMembersOpen: INITIAL_STATE.isTeamMembersOpen,
  displayOMNames: INITIAL_STATE.displayOMNames,
  clientSavedFilters: INITIAL_STATE.clientSavedFilters,
  agentSavedFilters: INITIAL_STATE.agentSavedFilters,
  teamLeadSavedFilters: INITIAL_STATE.teamLeadSavedFilters,

  filterMyTeamDeptNames: INITIAL_STATE.filterMyTeamDeptNames,
  filterMyTeamTeamLeads: INITIAL_STATE.filterMyTeamTeamLeads,

  isMyTeamDeptNamesOpen: INITIAL_STATE.isMyTeamDeptNamesOpen,
  isMyTeamTeamLeadsOpen: INITIAL_STATE.isMyTeamTeamLeadsOpen,

  isAgentIdAndNameOpen: INITIAL_STATE.isAgentIdAndNameOpen,

  setIsAgentIdAndNameOpen: (isOpen: boolean) => {
    return set(() => ({
      isLoading: false,
      error: null,
      isAgentIdAndNameOpen: isOpen,
    }));
  },

  addAgentIdAndName: (agent_data: any[]) => {
    return set(() => ({
      isLoading: false,
      error: null,
      filterAgentIdAndName: agent_data,
    }));
  },

  clearFilters: () => {
    console.log("Clearing filters");

    return set(() => ({
      isLoading: false,
      error: null,
      filterAgentIdAndName: [],
      filterClientName: [],
      filterDeptName: [],
      filterAgentsName: [],
      filterTeamLeadsName: [],
      filterOMsName: [],
      filterCSMsName: [],
      filterCSMTeamManagers: [],
      filterDeptManagerNames: [],
      filterInternalTeamMembers: [],
      filterTeamManagers: [],
      filterTeamMembers: [],
      filterMyTeamDeptNames: [],
      filterMyTeamTeamLeads: [],
      startingDateFilter: "",
      endingDateFilter: "",
    }));
  },

  setIsMyTeamDeptNamesOpen: (isOpen: boolean) => {
    return set(() => ({
      isLoading: false,
      error: null,
      isMyTeamDeptNamesOpen: isOpen,
    }));
  },
  setIsMyTeamTeamLeadsOpen: (isOpen: boolean) => {
    return set(() => ({
      isLoading: false,
      error: null,
      isMyTeamTeamLeadsOpen: isOpen,
    }));
  },

  addMyTeamDeptNames: (deptNames: string[]) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        filterMyTeamDeptNames: deptNames,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addMyTeamTeamLeads: (teamLeads: string[]) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        filterMyTeamTeamLeads: teamLeads,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },

  setIsTeamManagersOpen: (isOpen: boolean) => {
    return set(() => ({
      isLoading: false,
      error: null,
      isTeamManagersOpen: isOpen,
    }));
  },
  setIsTeamMembersOpen: (isOpen: boolean) => {
    return set(() => ({
      isLoading: false,
      error: null,
      isTeamMembersOpen: isOpen,
    }));
  },
  setIsInternalTeamMembersOpen: (isOpen: boolean) => {
    return set(() => ({
      isLoading: false,
      error: null,
      isInternalTeamMembersOpen: isOpen,
    }));
  },
  addTeamManagers: (name: string[]) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        filterTeamManagers: name,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addTeamMembers: (name: string[]) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        filterTeamMembers: name,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addInternalTeamMembers: (name: string[]) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        filterInternalTeamMembers: name,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addDeptManagersNames: (name: string[]) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        filterDeptManagerNames: name,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  setIsDeptManagersOpen: (isOpen: boolean) => {
    return set(() => ({
      isLoading: false,
      error: null,
      isDeptManagersOpen: isOpen,
    }));
  },
  setTeamLeadSavedFilter: (savedFilter: SavedFilter[]) => {
    try {
      localStorage.setItem(
        "team_lead_saved_filters",
        JSON.stringify(savedFilter)
      );
      return set(() => ({
        teamLeadSavedFilters: savedFilter,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addTeamLeadSavedFilter: (savedFilter: SavedFilter) => {
    try {
      const savedFiltersData = localStorage.getItem("team_lead_saved_filters");
      const parseClientdSavedFilters: SavedFilter[] = savedFiltersData
        ? JSON.parse(savedFiltersData)
        : [];
      console.log("parseClientdSavedFilters");
      console.log(parseClientdSavedFilters);

      const newClientSavedFilters = [...parseClientdSavedFilters, savedFilter];
      console.log("newClientSavedFilters");
      console.log(newClientSavedFilters);

      localStorage.setItem(
        "team_lead_saved_filters",
        JSON.stringify(newClientSavedFilters)
      );
      return set(() => ({
        teamLeadSavedFilters: newClientSavedFilters,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  editTeamLeadSavedFilter: (index: number, selectedNames: string[]) => {
    try {
      const savedFiltersData = localStorage.getItem("team_lead_saved_filters");
      const parseClientdSavedFilters: SavedFilter[] = savedFiltersData
        ? JSON.parse(savedFiltersData)
        : [];
      selectedNames.forEach((value) => {
        if (!parseClientdSavedFilters[index].selectedNames.includes(value)) {
          parseClientdSavedFilters[index].selectedNames.push(value);
        }
      });
      localStorage.setItem(
        "team_lead_saved_filters",
        JSON.stringify(parseClientdSavedFilters)
      );
      return set(() => ({
        agentSavedFilters: parseClientdSavedFilters,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  setAgentSavedFilter: (savedFilter: SavedFilter[]) => {
    try {
      localStorage.setItem("agent_saved_filters", JSON.stringify(savedFilter));
      return set(() => ({
        agentSavedFilters: savedFilter,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addAgentSavedFilter: (savedFilter: SavedFilter) => {
    try {
      const savedFiltersData = localStorage.getItem("agent_saved_filters");
      const parseClientdSavedFilters: SavedFilter[] = savedFiltersData
        ? JSON.parse(savedFiltersData)
        : [];
      console.log("parseClientdSavedFilters");
      console.log(parseClientdSavedFilters);

      const newClientSavedFilters = [...parseClientdSavedFilters, savedFilter];
      console.log("newClientSavedFilters");
      console.log(newClientSavedFilters);

      localStorage.setItem(
        "agent_saved_filters",
        JSON.stringify(newClientSavedFilters)
      );
      return set(() => ({
        agentSavedFilters: newClientSavedFilters,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  editAgentSavedFilter: (index: number, selectedNames: string[]) => {
    try {
      const savedFiltersData = localStorage.getItem("agent_saved_filters");
      const parseClientdSavedFilters: SavedFilter[] = savedFiltersData
        ? JSON.parse(savedFiltersData)
        : [];
      selectedNames.forEach((value) => {
        if (!parseClientdSavedFilters[index].selectedNames.includes(value)) {
          parseClientdSavedFilters[index].selectedNames.push(value);
        }
      });
      localStorage.setItem(
        "agent_saved_filters",
        JSON.stringify(parseClientdSavedFilters)
      );
      return set(() => ({
        agentSavedFilters: parseClientdSavedFilters,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  setClientSavedFilter: (savedFilter: SavedFilter[]) => {
    try {
      localStorage.setItem("client_saved_filters", JSON.stringify(savedFilter));
      return set(() => ({
        clientSavedFilters: savedFilter,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addClientSavedFilter: (savedFilter: SavedFilter) => {
    try {
      const savedFiltersData = localStorage.getItem("client_saved_filters");
      const parseClientdSavedFilters: SavedFilter[] = savedFiltersData
        ? JSON.parse(savedFiltersData)
        : [];
      console.log("parseClientdSavedFilters");
      console.log(parseClientdSavedFilters);

      const newClientSavedFilters = [...parseClientdSavedFilters, savedFilter];
      console.log("newClientSavedFilters");
      console.log(newClientSavedFilters);

      localStorage.setItem(
        "client_saved_filters",
        JSON.stringify(newClientSavedFilters)
      );
      return set(() => ({
        clientSavedFilters: newClientSavedFilters,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  editClientSavedFilter: (index: number, selectedNames: string[]) => {
    try {
      const savedFiltersData = localStorage.getItem("client_saved_filters");
      const parseClientdSavedFilters: SavedFilter[] = savedFiltersData
        ? JSON.parse(savedFiltersData)
        : [];
      selectedNames.forEach((value) => {
        if (!parseClientdSavedFilters[index].selectedNames.includes(value)) {
          parseClientdSavedFilters[index].selectedNames.push(value);
        }
      });
      localStorage.setItem(
        "client_saved_filters",
        JSON.stringify(parseClientdSavedFilters)
      );
      return set(() => ({
        clientSavedFilters: parseClientdSavedFilters,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addDisplayOMNames: (names) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        displayOMNames: names,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  setIsDate: (isDate) => {
    try {
      return set(() => ({
        isDate: isDate,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addCSTeamManagers: (CSTeamManagers) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        filterCSMTeamManagers: CSTeamManagers,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addClientNames: (name) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        filterClientName: name,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addDeptNames: (name) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        filterDeptName: name,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addAgentsNames: (name) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        filterAgentsName: name,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addTeamLeadsNames: (name) => {
    console.log("I go here");
    console.log(name);

    try {
      return set(() => ({
        isLoading: false,
        error: null,
        filterTeamLeadsName: user?.role === "tl" ? [user?.name] : name,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addOmsNames: (name) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        filterOMsName: name,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addCsmsNames: (name) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        filterCSMsName: name,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addstartingdate: (date) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        startingDateFilter: date,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  addendingdate: (date) => {
    try {
      return set(() => ({
        isLoading: false,
        error: null,
        endingDateFilter: date,
      }));
    } catch (error) {
      if (error instanceof Error)
        set({ error: error.message, isLoading: false });
    }
  },
  setIsDeptOpen: (open) => {
    return set(() => ({
      isLoading: false,
      error: null,
      isDeptOpen: open,
    }));
  },
  setIsCSTeamManagerOpen: (open) => {
    return set(() => ({
      isLoading: false,
      error: null,
      isCSTeamManagerOpen: open,
    }));
  },
  setIsCSMOpen: (open) => {
    return set(() => ({
      isLoading: false,
      error: null,
      isCSMOpen: open,
    }));
  },
  setIsTeamLeadsOpen: (open) => {
    return set(() => ({
      isLoading: false,
      error: null,
      isTeamLeadsOpen: open,
    }));
  },
  setIsOpsManager: (open) => {
    return set(() => ({
      isLoading: false,
      error: null,
      isOpsManager: open,
    }));
  },
  setIsDateOpen: (open) => {
    // alert(`Setting IsDateOpen to ${open}`)
    return set(() => ({
      isLoading: false,
      error: null,
      isDateOpen: open,
    }));
  },
  setIsClientOpen: (open) => {
    console.log(open);

    return set(() => ({
      isLoading: false,
      error: null,
      isClientOpen: open,
    }));
  },
  setIsAgentOpen: (open) => {
    console.log(open);

    return set(() => ({
      isLoading: false,
      error: null,
      isAgentOpen: open,
    }));
  },
}));
