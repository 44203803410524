import { useState, useEffect } from "react";
import axios from "axios";
import { Skeleton } from "../ui/skeleton.tsx";
import DownloadCSVButton from "../ui/DownloadCSVButton.js";
import editIcon from "../../assests/editIcon.jpeg";
import copyIcon from "../../assests/copyIcon.jpeg";
import Pagination2 from "../ui/Pagination2.js";
import toast from "react-hot-toast";
import Papa from "papaparse";
import StripeModal from "./UnmatchedFilterModal.js";
import ArchiveClientsModal from "./ArchiveClientsModal.js";
import AppContext from "../../context/AppContext.js";
import { useContext } from "react";

export default function HubspotDBTable() {
  const { ApplyfilterBillKeyUnmatched, BillKeySelectedProjectNameFilter } =
    useContext(AppContext);

  const [loading, setloading] = useState(true);
  const [Data, setData] = useState([]);
  const [totalPages, settotalPages] = useState();
  const [currentpage, setcurrentpage] = useState();
  const [showModal, setshowModal] = useState(false);
  const [clientInfo, setclientInfo] = useState("");
  const [showArchiveClientModal, setshowArchiveClientModal] = useState(false);

  const copyToClipboard = (text) => {
    if (navigator?.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success("Copied to clipboard");
        })
        .catch((err) => {
          toast.error("Failed to copy");
          console.error("Error copying to clipboard: ", err);
        });
    } else {
      toast.error("Clipboard not supported");
    }
  };
  const getData = async (page) => {
    try {
      setloading(true);

      const token = localStorage.getItem("auth_token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedClients =
        BillKeySelectedProjectNameFilter.length > 0
          ? BillKeySelectedProjectNameFilter.map(
              (item) => `&filter_ids=${item}`
            )
          : [];

      const url = `${
        process.env.REACT_APP_BASE_URL
      }/hubstaff-projects?page=${page}${selectedClients?.join("")}`;
      const res = await axios.get(url, config);
      if (res?.data?.projects) {
        console.log("hubstaff res.data", res?.data);
        setData(res?.data?.projects);
        settotalPages(res?.data?.pagination?.total_pages);
        setcurrentpage(res?.data?.pagination?.current_page);
      } else {
        console.log("error in getDataStripe", res);
        setloading(false);
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log("error in getDataStripe", error);
    }
  };

  useEffect(() => {
    getData(1);
  }, [ApplyfilterBillKeyUnmatched]);

  const DownloadCSVFunction = () => {
    console.log("Data", Data);
    let i = 0;
    let j = [];

    while (i < Data.length) {
      let k = {};
      k["Client Stripe ID"] = Data[i]?.customer_id ? Data[i]?.customer_id : "-";
      k["Client ID DB"] = "-";
      k["Client Name"] = Data[i]?.name ? Data[i]?.name : "-";
      k["Email Address"] = Data[i]?.email ? Data[i]?.email : "-";
      k["Original Full Time Bill Rate"] = Data[i]?.OriginalFullTimeBillRate
        ? Data[i]?.OriginalFullTimeBillRate
        : "-";
      k["Full Time Bill Rate"] = Data[i]?.FullTimeBillRate
        ? Data[i]?.FullTimeBillRate
        : "-";
      k["Google Drive Folder"] = Data[i]?.GoogleDriveFolder
        ? Data[i]?.GoogleDriveFolder
        : "-";
      k["Who to CC on invoices"] = Data[i]?.CConInvoices
        ? Data[i]?.CConInvoices
        : "-";
      k["How we Bill?"] = Data[i]?.BillingMethod ? Data[i]?.BillingMethod : "-";
      k["How we Bill?"] = Data[i]?.DontAutoBill ? Data[i]?.DontAutoBill : "-";
      k["Net Terms/Pay Delay"] = Data[i]?.netTermsPayDelay
        ? Data[i]?.netTermsPayDelay
        : "-";
      k["Payment Method"] = Data[i]?.PaymentMethod
        ? Data[i]?.PaymentMethod
        : "-";
      k["Detail"] = Data[i]?.Details ? Data[i]?.Details : "-";
      k["Rate Increase Notes"] = Data[i]?.RareIncreaseNotes
        ? Data[i]?.RareIncreaseNotes
        : "-";
      k["CSM"] = Data[i]?.csm ? Data[i]?.csm : "-";
      k["Start Date"] = Data[i]?.startDate ? Data[i]?.startDate : "-";
      k["Cancelled Date"] = Data[i]?.canceledDate ? Data[i]?.canceledDate : "-";
      k["Active/Inactive"] = Data[i]?.Active_Inactive
        ? Data[i]?.Active_Inactive
        : "-";
      k["Total Invoice Amount"] = "required";
      k["Total Hours Worked"] = "required";
      k["Expansion Cost"] = "required";
      k["OnBoarding Fee"] = "required";

      j.push(k);
      i = i + 1;
    }
    console.log("j:", j);

    const csvData = Papa.unparse(j);
    const blob = new Blob([csvData], { type: "text/csv" });
    // Create download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "HubStaff_Unmatched_Data.csv";
    // Trigger download
    link.click();
  };

  if (loading == true) {
    return (
      <div className=" w-full">
        <div className="flex  items-center align-middle justify-between px-4 h-[50px] bg-white border-r">
          <span className="font-semibold text-[16px] text-main-text  ">
            Hubstaff Client Projects
          </span>
          <div onClick={() => (Data.length > 0 ? DownloadCSVFunction() : null)}>
            {/* <DownloadCSVButton /> */}
          </div>
        </div>
        <Skeleton className=" relative w-[100%] h-[425px]  p-4 border bg-slate-200  font-bold text-[#163143]" />
      </div>
    );
  }

  if (Data?.length == 0) {
    return (
      <div className=" w-full border rounded-br-3xl h-full">
        <div className="flex  items-center align-middle justify-between px-4 h-[50px] bg-white border-r">
          <span className="font-semibold text-[16px] text-main-text  ">
            Hubstaff Client Projects
          </span>
          <div onClick={() => (Data.length > 0 ? DownloadCSVFunction() : null)}>
            {/* <DownloadCSVButton /> */}
          </div>
        </div>
        <div className=" w-full flex items-center justify-center my-10">
          No Records Found
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-t-10">
      <StripeModal
        showModal={showModal}
        setshowModal={setshowModal}
        clientInfo={clientInfo}
        setclientInfo={setclientInfo}
        showArchiveClientModal={showArchiveClientModal}
        setshowArchiveClientModal={setshowArchiveClientModal}
      />
      <ArchiveClientsModal
        showArchiveClientModal={showArchiveClientModal}
        setshowArchiveClientModal={setshowArchiveClientModal}
        showModal={showModal}
        setshowModal={setshowModal}
      />
      <div>
        <div className="flex  items-center align-middle justify-between px-4 h-[50px] bg-white border-r">
          <span className="font-semibold text-[16px] text-main-text  ">
            Hubstaff Client Projects
          </span>
          <div onClick={() => (Data.length > 0 ? DownloadCSVFunction() : null)}>
            {/* <DownloadCSVButton /> */}
          </div>
        </div>

        <div>
          <div className=" flex flex-row  bg-white  w-[100%] overflow-hidden ">
            {/* <div
            style={{ width: "100px" }}
            className="flex flex-col text-sm  h-full"
          >
            <div className=" flex  items-center w-full justify-center border-b-2 shadow-[#D7E6E7_5px_10px_9px_0px]  border-r-2 border-r-[#D7E6E7]  h-[54.3px] ">
              <span></span>
              <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
            </div>
            <div className=" flex flex-col border-b shadow-[#D7E6E7_3px_4px_9px_0px]  h-[94.5%]  border-r-2 border-r-[#D7E6E7] justify-evenly w-full">
              {Data.map((item) => (
                <div className=" bg-white border-b w-full h-[53px] flex justify-center items-center">
                  <img
                    src={editIcon}
                    className=" w-[24px] hover:w-7 hover:h-7 cursor-pointer"
                  />
                </div>
              ))}
            </div>
          </div> */}
            <div className=" w-[2000px] h-[66vh] overflow-x-auto">
              <table className=" table-fixed w-full  border-separate">
                {" "}
                {/* Adjust padding here */}
                <thead className=" border-b-2 text-main-text">
                  <tr className="p-4 bg-[#EBF3F4]">
                    {/* <th className="   w-[70px] border-r border-slate-100 font-medium text-sm text-main-text  sticky left-0 shadow-[#D7E6E7_3px_0px_3px_0px] bg-[#EBF3F4]  border-b ">
                      <div className="flex items-center align-middle justify-start space-x-2  w-full h-full  ">
                        <span className=" "> </span>
                        <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                      </div>
                    </th> */}
                    <th className="text-center px-4 h-[50px] w-[250px] font-medium text-[12px] text-main-text border-b ">
                      <div className="flex items-center align-middle justify-start space-x-2">
                        <span>Hubstaff Project Name</span>
                        <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                      </div>
                    </th>
                    <th className="text-center px-4 h-[50px] w-[200px] font-medium text-[12px] text-main-text border-b ">
                      <div className="flex items-center align-middle justify-start space-x-2">
                        <span>Hubstaff Project ID</span>
                        <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {Data.map((item, index) => (
                    <tr
                      key={index}
                      className={`${
                        index === Data.length - 1 ? "border-b-2" : "border-b"
                      } hover:bg-gray-200 hover:cursor-pointer`}
                    >
                      
                      <td className=" p-4 text-start font-normal text-[12px] text-main-text border-b ">
                        {" "}
                        <div className=" flex bgr gap-2  ">
                          <img
                            onClick={() => copyToClipboard(item?.name)}
                            className="w-5 h-5   cursor-pointer"
                            src={copyIcon}
                          />
                          {item?.name}
                        </div>
                      </td>
                      <td className="p-4 text-start font-normal text-[12px] text-main-text border-b ">
                        {" "}
                        {/* Adjust padding here */}
                        {item?.id}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* 
    <div className=" ">
        <Pagination2 totalPages={totalPages} currentpage={currentpage} getData={getData} />
      </div> */}
    </div>
  );
}
