import React, { useEffect } from "react";
import { Tabs, Tab } from "../Tabs.js";
import AllClientTable from "../BillKey/AllMatchedClientsTable.js";
import { DatePickerWithRange } from "../DateFilter.tsx";
import filterIcon from "../../assests/index.jpeg";
import ClientFilter from "../ClientFilter.js";
import AllInvoiceTable from "./AllInvoicetable.js";
import Modal from "react-modal";
import { Button } from "../ui/button.tsx";
import { useState } from "react";
import Test from "./Test.js";
import ApplyFilter from "../Filters/ApplyFilter.js";
import InvoicesToBillTable from "./InvoicesToBillTable.js";
import InvoicesToSendTable from "./InvoicesToSendTable.js";
import PendingManualReviewtable from "./PendingManualReviewTable.js";
import { X } from "lucide-react";
import { useContext } from "react";
import AppContext from "../../context/AppContext.js";
import GenerateInvoiceConfirmationModal from "../BillKey/GenerateInvoiceConfirmationModal.js";
import EditClientInvoiceModal from "./EditClientInvoiceModal.js";
import Apply from "../Filters/Apply.js";
import ClientFilterProcessBilling from "../ClientFilterProcessBilling.js";



export default function ProcessBilling() {
  const {
    CurrntActiveTab,
    ApplyfilterProcessBilling,
    setApplyfilterProcessBilling,
    setProcessBillingSelectedClientFilter
  } = useContext(AppContext);

  const [showModal, setshowModal] = useState(false);

  const [CompanyName, setCompanyName] = useState("");
  const [ShowTableData, setShowTableData] = useState(true);




  
  useEffect(() => {
    setShowTableData(true);
  }, [CurrntActiveTab]);

  

  return (
    <>
      <div className="flex bg-[#F1F5F5] text-main-text  w-full h-screen overflow-y-scroll">
        <div className="flex flex-col space-y-6 w-[98%] mx-auto p-8">
          <div className="font-bold text-2xl ">Process Billing</div>

          <div
            className={`${
              ShowTableData == false && CurrntActiveTab == "All Invoices"
                ? "opacity-20 pointer-events-none"
                : ""
            } flex gap-4`}
          >
            <ClientFilterProcessBilling />

            <Apply
              className={`text-white px-4 h-8 bg-[#69C920]`}
              onChange={() =>
                setApplyfilterProcessBilling(!ApplyfilterProcessBilling)
              }
            ></Apply>
          </div>
          <div className="flex flex-col pb-7">
            <Tabs>
              <Tab data-label="All Invoices">
                <AllInvoiceTable
                  ShowTableData={ShowTableData}
                  setShowTableData={setShowTableData}
                />
              </Tab>
              <Tab data-label="Invoices to Bill">
                <InvoicesToBillTable setshowModal={setshowModal} />
              </Tab>
              <Tab data-label="Invoices to Send">
                <InvoicesToSendTable setshowModal={setshowModal} />
              </Tab>
              <Tab data-label="Pending Manual Review">
                <PendingManualReviewtable setshowModal={setshowModal} />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}
