import { useEffect, useState } from "react";
import editIcon from "../../assests/editIcon.jpeg";
import {
  ArrowDown,
  ArrowUp,
  CheckIcon,
  ChevronDown,
  ChevronUp,
  X,
} from "lucide-react";
import AppContext from "../../context/AppContext.js";
import { useContext } from "react";
import DownloadCSVButton from "../ui/DownloadCSVButton.js";
import AllMatchedClientsModal from "./AllMatchedClientsModal.js";
import ArchiveClientsModal from "./ArchiveClientsModal.js";
import EditAgentBillkeyModal from "./EditAgentBillkeyModal.js";
import { Skeleton } from "../ui/skeleton.tsx";
import axios from "axios";
import Pagination2 from "../ui/Pagination2.js";
import toast from "react-hot-toast";
const DropDownComp = ({
  setShowModalAgent,
  data,
  agentsData,
  setagentInfo,
}) => {
  if (agentsData.length == 0) {
    return null;
  }

  return (
    <div>
      <div className=" flex flex-row  w-[3028px] ">
        <table className=" table-fixed w-full border-separate ">
          {" "}
          <thead className=" border-b-2 text-main-text">
            <tr className="">
              <th className=" sticky left-[0px]  bg-[#EBF3F4]    w-[110px] font-medium text-[12px] text-main-text border-r-2  ">
                <div className="flex flex-row     w-full h-[50px] items-center   pl-4  ">
                  <span className=" "></span>
                </div>
              </th>

              <th className=" sticky left-[110px] shadow-[#D7E6E7_3px_0px_3px_0px]  bg-[#EBF3F4]   w-[130px] font-medium text-[12px]  border-r-2 ">
                <div className="flex flex-row     w-full h-[50px] items-center  pl-4   ">
                  <span className="  text-[12px] font-medium">Agent Name</span>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[200px] font-medium text-[12px] text-main-text">
                <div className="hover:cursor-pointer flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Client Standard Bill Rate</span>
                  <div className="flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>

              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[110px]   font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" "> Adjustment to Bill Rate</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>

              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[150px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Cyber Security Plan</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[222px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Details</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[292px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Rate Increase Notes</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[200px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Team Lead</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[200px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Stripe Product ID</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[200px] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">QuickBooks ID</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4] border-r-2 p-4 w-[262px]   font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  <span className=" ">Hubstaff User ID</span>
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
              <th className="text-center bg-[#EBF3F4]  p-4   w-[9298x] font-medium text-[12px] text-main-text">
                <div className="flex items-center align-middle justify-center space-x-2">
                  {/* <span className=" ">Miscellaneous</span> */}
                  <div className="hover:cursor-pointer flex items-center align-middle justify-center flex-col"></div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className=" bg-[#FBFBFB]">
            {agentsData.map((item, index) => (
              <>
                <tr
                  key={index}
                  className={`${
                    index === data?.length - 1 ? "border-b-2" : "border-b"
                  } hover:bg-gray-200 hover:cursor-pointer bg-[#FBFBFB]`}
                >
                  <td className=" sticky left-[0px]  bg-[#FBFBFB] text-start font-normal text-sm text-main-text border-r-2 border-b-2 ">
                    {" "}
                    <div className="   w-full pl-4  h-[50px] flex items-center  ">
                      <div
                        onClick={() => [
                          setagentInfo(item),
                          setShowModalAgent(true),
                        ]}
                        className=" bg-[#FBFBFB]  w-full h-[53px] flex justify-end px-4 items-center"
                      >
                        <img
                          src={editIcon}
                          className=" w-[20px]  cursor-pointer"
                        />
                      </div>
                    </div>
                  </td>
                  <td className=" shadow-[#D7E6E7_3px_0px_3px_0px] sticky left-[110px] bg-[#FBFBFB]  text-start font-normal text-[11px] text-main-text border-r-2">
                    {" "}
                    <div className="   w-full pl-4   flex items-center ">
                      {item.user_name}
                    </div>
                  </td>
                  <td className="p-4 border-r-2  text-center font-normal text-[11px] text-main-text">
                    {" "}
                    {item.bill_rate ? item.bill_rate : "-"}
                  </td>
                  <td className="p-4 border-r-2   bg-opacity-90  text-center font-normal text-[11px] text-main-text">
                    {item.adjustment_to_bill_rate ? (
                      <>
                        {" "}
                        {"$"} {item.adjustment_to_bill_rate}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                    {" "}
                    {item.cyber_security_plan ? item.cyber_security_plan : "-"}
                  </td>
                  <td className="p-4 border-r-2  text-center font-normal text-[11px] text-main-text">
                    {" "}
                    {item.detail ? item.detail : "-"}
                  </td>{" "}
                  <td className="p-4 border-r-2  text-center font-normal text-[11px] text-main-text">
                    {" "}
                    {item.rate_increase_notes ? item.rate_increase_notes : "-"}
                  </td>{" "}
                  <td className="p-4 border-r-2  text-center font-normal text-[11px] text-main-text">
                    {" "}
                    {item.team_lead ? item.team_lead : "-"}
                  </td>{" "}
                  <td className="p-4 border-r-2  text-center font-normal text-[11px] text-main-text">
                    {" "}
                    {item.stripe_product_id ? item.stripe_product_id : "-"}
                  </td>{" "}
                  <td className="p-4 border-r-2  text-center font-normal text-[11px] text-main-text">
                    {" "}
                    {item.qb_id ? item.qb_id : "-"}
                  </td>{" "}
                  <td className="p-4 border-r-2 text-center font-normal text-[11px] text-main-text">
                    {" "}
                    {item.AgentScheduledBillRate
                      ? item.AgentScheduledBillRate
                      : "-"}
                  </td>
                  <td className="p-4   text-center font-normal text-[11px] text-main-text">
                    {" "}
                    {/* {item.Miscellaneous ? item.Miscellaneous : "-"} */}
                  </td>{" "}
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const RenderClientData = ({
  setclientInfo,
  setshowModal,
  item,
  setShowModalAgent,
  data,
  setagentInfo,
}) => {
  const [show, setshow] = useState(false);

  function formatString(input) {
    return input
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  }

  return (
    <>
      <tr>
        <td className="bg-white sticky left-0 w-[110px] border-r-2">
          <div className=" bg-white  w-full  flex  items-center px-2 h-[50px]">
            <div className=" w-1/2 flex items-center justify-center">
              {show == true ? (
                <ChevronUp
                  className="cursor-pointer duration-500 rotate-0  w-5 h-5 "
                  onClick={() => setshow(false)}
                  color="black"
                />
              ) : (
                <ChevronUp
                  className=" cursor-pointer duration-500 rotate-180  w-5 h-5  "
                  onClick={() => setshow(true)}
                  // size={0}
                  color="black"
                />
              )}
            </div>
            <div className=" w-1/2 flex items-center justify-center">
              <img
                onClick={() => [setclientInfo(item), setshowModal(true)]}
                src={editIcon}
                className=" w-[20px]  cursor-pointer"
              />
            </div>
          </div>
        </td>
        <td className=" px-2 text-[11px]  bg-white  sticky shadow-[#D7E6E7_3px_0px_3px_0px] left-[110px]">
          {item.client_name}
        </td>
        <td className=" px-2 text-[11px] bg-white   text-center break-all">
          {item.email}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center">
          {item.bill_rate}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center">
          {item.original_full_time_bill_rate}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center">
          {" "}
          {item.billing_status ? formatString(item.billing_status) : "-"}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center">
          {item.how_we_bill}
        </td>

        <td className=" px-2 text-[11px] bg-white text-center">
          {item.dont_auto_bill}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center">
          {item.payment_method}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center">
          {item.net_terms_pay_delay}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center flex-wrap whitespace-break-spaces">
          {item.detail}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center">
          {item.rate_increase_notes}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center break-all">
          {item.who_to_cc_on_email}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center">{item.csm}</td>
        <td className=" px-2 text-[11px] bg-white text-center text-blue-400 whitespace-nowrap text-ellipsis overflow-hidden ">
          {" "}
          <div
            onClick={() => window.open(item.google_drive_folder, "_blank")}
            className="cursor-pointer whitespace-nowrap text-ellipsis overflow-hidden px-2"
          >
            {item.google_drive_folder}
          </div>
        </td>

        <td className=" px-2 text-[11px] bg-white text-center">
          {item.stripe_customer_id}
        </td>
        <td className=" px-2 text-[11px]  bg-white text-center">
          {item.qb_id}
        </td>
        <td className=" px-2 text-[11px] bg-white text-center">
          <div className=" w-full flex flex-col flex-wrap items-center py-3 justify-center  gap-1">
            {item.hubstaff_projects.map((item) => (
              <div className=" bg-[#F5FFED] rounded-3xl px-2 py-1 text-[#163143] ">
                {" "}
                {item.name}
              </div>
            ))}
          </div>
        </td>
        <td className=" px-2 text-[11px] bg-white text-center">
          <div className=" w-full flex flex-col flex-wrap items-center py-3 justify-center  gap-1">
            {item?.hubstaff_clients?.map((item) => (
              <div className=" bg-[#69C920] rounded-3xl px-2 py-1 text-[white] ">
                {" "}
                {item.name}
              </div>
            ))}
          </div>
        </td>
      </tr>
      {show == false ? null : (
        <DropDownComp
          setShowModalAgent={setShowModalAgent}
          data={data}
          agentsData={item.users}
          setagentInfo={setagentInfo}
        />
      )}
    </>
  );
};

const RowComp = ({
  setshowModal,
  // selected,
  // setselected,
  setShowModalAgent,
  data,
  setclientInfo,
  setagentInfo,
  SortBy,
  setSortBy,
  SortOrder,
  setSortOrder,
}) => {
  const handleSortName = () => {
    if (SortBy == "client_name") {
      if (SortOrder == "asc") {
        setSortOrder("desc");
      } else if (SortOrder == "desc") {
        setSortOrder("asc");
      }
    } else {
      setSortBy("client_name");
      setSortOrder("asc");
    }
  };
  const handleSortEmail = () => {
    if (SortBy == "email") {
      if (SortOrder == "asc") {
        setSortOrder("desc");
      } else if (SortOrder == "desc") {
        setSortOrder("asc");
      }
    } else {
      setSortBy("email");
      setSortOrder("asc");
    }
  };
  return (
    <div>
      <div className="  w-[3028px]  ">
        <table className="table-fixed w-full border-separate">
          <thead className=" border-b-2 text-main-text bg-[#EBF3F4]  ">
            <tr>
              <th className=" bg-[#EBF3F4]  sticky left-0 w-[110px] h-[50px] font-medium text-[12px] border-r-2">
                <div className="">Actions</div>
              </th>
              <th className=" bg-[#EBF3F4]  sticky shadow-[#D7E6E7_3px_0px_3px_0px] left-[110px] w-[130px] font-medium text-[12px]">
                <div className=" flex items-center justify-center gap-2">
                  Client Name
                  <div onClick={() => handleSortName()}>
                    {SortOrder == "asc" ? (
                      <ArrowDown
                        className=" cursor-pointer"
                        strokeWidth={2}
                        size={15}
                        color={SortBy == "client_name" ? "#69C920" : "gray"}
                      />
                    ) : (
                      <ArrowUp
                        className=" cursor-pointer"
                        strokeWidth={2}
                        size={15}
                        color={SortBy == "client_name" ? "#69C920" : "gray"}
                      />
                    )}
                  </div>
                </div>
              </th>
              <th className=" w-[200px] font-medium text-[12px]">
                <div className=" flex items-center justify-center gap-2">Email Address

                <div onClick={() => handleSortEmail()}>
                    {SortOrder == "asc" ? (
                      <ArrowDown
                        className=" cursor-pointer"
                        strokeWidth={2}
                        size={15}
                        color={SortBy == "email" ? "#69C920" : "gray"}
                      />
                    ) : (
                      <ArrowUp
                        className=" cursor-pointer"
                        strokeWidth={2}
                        size={15}
                        color={SortBy == "email" ? "#69C920" : "gray"}
                      />
                    )}
                  </div>
                </div>
              </th>
              <th className=" w-[110px] font-medium text-[12px]">
                <div>Bill Rate Range</div>
              </th>

              <th className=" w-[210px] font-medium text-[12px]">
                <div>Original Full Time Bill Rate</div>
              </th>
              
              <th className=" w-[150px] font-medium text-[12px]">
                <div>Billing Status</div>
              </th>
              <th className=" w-[110px] font-medium text-[12px]">
                <div>How We Bill?</div>
              </th>
              <th className=" w-[110px] font-medium text-[12px]">
                <div>Don't Auto Bill</div>
              </th>
              <th className=" w-[110px] font-medium text-[12px]">
                <div>Payment Method</div>
              </th>
              <th className=" w-[180px] font-medium text-[12px]">
                <div>Net Terms Delay</div>
              </th>
              <th className=" w-[500px] font-medium text-[12px]">
                <div>Detail</div>
              </th>
              <th className=" w-[200px] font-medium text-[12px]">
                <div>Rate Increase Notes</div>
              </th>
              <th className=" w-[200px] font-medium text-[12px]">
                <div>Who to CC on Incvoices</div>
              </th>
              <th className=" w-[110px] font-medium text-[12px]">
                <div>CSM</div>
              </th>
              <th className=" w-[150px] font-medium text-[12px]">
                <div>Google Drive Folder</div>
              </th>

              <th className=" w-[150px] font-medium text-[12px]">
                <div>Stripe ID</div>
              </th>
              <th className=" w-[150px] font-medium text-[12px]">
                <div>Quickbooks ID</div>
              </th>
              <th className=" w-[310px] font-medium text-[12px]">
                <div>Hubstaff Projects</div>
              </th>
              <th className=" w-[310px] font-medium text-[12px]">
                <div>Hubstaff Clients Name</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <RenderClientData
                setclientInfo={setclientInfo}
                setshowModal={setshowModal}
                item={item}
                setShowModalAgent={setShowModalAgent}
                data={data}
                setagentInfo={setagentInfo}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default function AllMatchedClientsTable({}) {
  // console.log(data);
  const {
    LogOutUser,
    BillKeySelectedClientsFilter,
    ApplyfilterBillKeyMatched,
    SortBy,
    setSortBy,
    SortOrder,
    setSortOrder,
  } = useContext(AppContext);

  const [selected, setselected] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [ShowModalAgent, setShowModalAgent] = useState(false);
  const [showArchiveClientModal, setshowArchiveClientModal] = useState(false);
  const [clientInfo, setclientInfo] = useState();
  const [agentInfo, setagentInfo] = useState("");
  const [BillkeyMatchedClientLoading, setBillkeyMatchedClientLoading] =
    useState(true);
  const [BillKeyMatchedClientData, setBillKeyMatchedClientData] = useState([]);
  const [BillkeyMatchedClientTotalPages, setBillkeyMatchedClientTotalPages] =
    useState(0);
  const [BillkeyMatchedClientCurrentPage, setBillkeyMatchedClientCurrentPage] =
    useState(0);

  const getData = async (page) => {
    try {
      setBillkeyMatchedClientLoading(true);
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const selectedClients =
        BillKeySelectedClientsFilter.length > 0
          ? BillKeySelectedClientsFilter.map((item) => `&filter_ids=${item}`)
          : [];
      const url = `${
        process.env.REACT_APP_BASE_URL
      }/all-clients?page=${page}&per_page=50&sort_by=${SortBy}&sort_order=${SortOrder}${selectedClients?.join("")}`;

      const res = await axios.get(url, config);

      if (res.status == 200) {
        console.log("res.status", res.status);
        console.log("res.data", res.data);

        setBillkeyMatchedClientTotalPages(res?.data?.pagination?.total_pages);
        setBillkeyMatchedClientCurrentPage(res?.data?.pagination?.current_page);

        setBillKeyMatchedClientData(res?.data?.clients);
        setBillkeyMatchedClientLoading(false);
      } else {
        alert("error");
        console.log("res", res);
      }
    } catch (error) {
      console.log("error", error.response);
      if (error?.response?.status == 401) {
        LogOutUser();
        toast.error("Session Expired");
      }
      setBillkeyMatchedClientLoading(false);
    }
  };

  const getCSVData = async () => {
    try {
      const billKeySelectedClientQueryParam = new URLSearchParams();

      if (BillKeySelectedClientsFilter.length > 0) {
        BillKeySelectedClientsFilter.forEach((client) => {
          billKeySelectedClientQueryParam.append("filter_ids", client);
        });
      }

      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const url = `${process.env.REACT_APP_BASE_URL}/matched-clients-download?${billKeySelectedClientQueryParam}`;

      const res = await axios.get(url, config);

      if (res.status !== 200) {
        toast.error("Error downloading CSV");
      }

      // Get the CSV content as text
      // const csvData = await res.data;

      // Create a Blob from the CSV data
      const blob = new Blob([res.data], { type: "text/csv" });

      // Create a link element
      const link = document.createElement("a");

      // Set the URL for the link (createObjectURL)
      link.href = URL.createObjectURL(blob);

      // Set the download attribute to specify the filename
      link.download = "Matched_Client_Data.csv";

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(link.href);
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    getData(1);
  }, [ApplyfilterBillKeyMatched,SortBy,SortOrder]);

  if (BillkeyMatchedClientLoading == true) {
    return (
      <div className=" w-full">
        <Skeleton className=" relative w-[100%] h-[425px]  p-4 border bg-slate-200  font-bold text-[#163143]" />
      </div>
    );
  }
  if (BillKeyMatchedClientData?.length == 0) {
    return (
      <div className=" flex items-center justify-center h-[30vh] rounded-3xl bg-white w-full">
        No Data Found
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-10">
      <AllMatchedClientsModal
        showModal={showModal}
        setshowModal={setshowModal}
        clientInfo={clientInfo}
        setclientInfo={setclientInfo}
        showArchiveClientModal={showArchiveClientModal}
        setshowArchiveClientModal={setshowArchiveClientModal}
        getData={getData}
      />
      <ArchiveClientsModal
        showArchiveClientModal={showArchiveClientModal}
        setshowArchiveClientModal={setshowArchiveClientModal}
        showModal={showModal}
        setshowModal={setshowModal}
        getData={getData}
        clientInfo={clientInfo}
      />
      <EditAgentBillkeyModal
        showModal={ShowModalAgent}
        setshowModal={setShowModalAgent}
        agentInfo={agentInfo}
        setagentInfo={setagentInfo}
        showArchiveClientModal={showArchiveClientModal}
        setshowArchiveClientModal={setshowArchiveClientModal}
        getData={getData}
      />

      {BillKeyMatchedClientData?.length == 0 ? (
        <div className=" w-full flex items-center justify-center">
          No Records Found
        </div>
      ) : (
        <div>
          <div className="flex items-center align-middle h-[45px]  justify-between">
            <div className="mb-2 flex items-center  gap-4">
              <span className="font-semibold text-xl text-main-text ">
                Overview
              </span>

              {selected?.length == 0 ? null : (
                <div className=" flex items-center justify-center gap-1">
                  <div
                    onClick={() => null}
                    className=" rounded-3xl px-5 py-2 border bg-white border-slate-200 text-sm hover:bg-[#69C920] hover:text-white cursor-pointer"
                  >
                    Generate Invoice
                  </div>
                  <div className=" rounded-3xl px-5 py-2 border bg-white border-slate-200 text-sm hover:bg-[#69C920] hover:text-white cursor-pointer">
                    Move to Manual Review
                  </div>
                </div>
              )}
            </div>

            <span
              onClick={() =>
                toast.promise(getCSVData(), {
                  loading: "Fetching Data",
                  success: <b>Starting Download</b>,
                  error: <b>Error Downloading CSV</b>,
                })
              }
            >
              <DownloadCSVButton></DownloadCSVButton>
            </span>
          </div>
          <div className=" w-full   rounded-3xl overflow-hidden">
            <div className=" w-full overflow-x-scroll border   rounded-3xl ">
              <div className=" ">
                {/* <RowComp
                      index={index}
                      item={item}
                      setshowModal={setshowModal}
                      selected={selected}
                      setselected={setselected}
                      setShowModalAgent={setShowModalAgent}
                      data={BillKeyMatchedClientData}
                      setclientInfo={setclientInfo}
                    /> */}
                <RowComp
                  setshowModal={setshowModal}
                  selected={selected}
                  setselected={setselected}
                  setShowModalAgent={setShowModalAgent}
                  data={BillKeyMatchedClientData}
                  setclientInfo={setclientInfo}
                  setagentInfo={setagentInfo}
                  SortBy={SortBy}
                  setSortBy={setSortBy}
                  SortOrder={SortOrder}
                  setSortOrder={setSortOrder}
                />
              </div>
            </div>

            <Pagination2
              totalPages={BillkeyMatchedClientTotalPages}
              currentpage={BillkeyMatchedClientCurrentPage}
              getData={getData}
            />
          </div>
        </div>
      )}
    </div>
  );
}
