import { useState } from "react";

const DotBox = () => {
  return <div className=" px-2 bg-white py-2 rounded-lg border ">...</div>;
};

export default function Pagination2({ totalPages,  currentpage, getData }) {

  if(currentpage==0&&totalPages==0)
    {
      return null
    }

  if (totalPages == 1) {
    return null;
  } else if (totalPages < 5) {
    return (
      <div className="  w-full  py-2  flex items-center justify-center bg-white ">
        <div className=" flex flex-row gap-2">
          <div
            onClick={() => (currentpage > 1 ? getData(currentpage - 1) : null)}
            className={`  bg-white  px-4 py-2 rounded-lg border   ${
              currentpage == 1
                ? " text-slate-300 border-slate-300 cursor-not-allowed"
                : "cursor-pointer border-slate-400 hover:bg-slate-300 text-green-500"
            }`}
          >
            {"<"}
          </div>
          {[...Array(totalPages)].map((e, i) => (
            <div
              onClick={() => (currentpage == 1 + i ? null : getData(1 + i))}
              className={`px-4 py-2 rounded-lg border  bg-white ${
                currentpage == 1 + i
                  ? " text-green-400 border-green-400"
                  : " text-slate-600 border-slate-400 hover:bg-slate-300 cursor-pointer "
              }`}
            >
              {1 + i}
            </div>
          ))}

          <div
            onClick={() =>
              currentpage == totalPages ? null : getData(currentpage + 1)
            }
            className={` bg-white px-4 py-2 rounded-lg border   ${
              currentpage == totalPages
                ? " text-slate-300 border-slate-300 cursor-not-allowed"
                : "cursor-pointer  border-slate-400 text-green-500 hover:bg-slate-300 "
            }`}
          >
            {">"}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className=" w-full  py-2  flex items-center justify-center bg-white  ">
        
        <div className=" flex flex-row gap-2">
          <div
            onClick={() => (currentpage > 1 ? getData(currentpage - 1) : null)}
            className={`  bg-white  px-4 py-2 rounded-lg border   ${
              currentpage == 1
                ? " text-slate-300 border-slate-300 cursor-not-allowed"
                : "cursor-pointer border-slate-400 hover:bg-slate-300  text-green-400"
            }`}
          >
            {"<"}
          </div>
          <div
            onClick={() => (currentpage == 1 ? null : getData(1))}
            className={`  bg-white px-4 py-2 rounded-lg border ${
              currentpage == 1
                ? " text-green-500  border-green-400"
                : " cursor-pointer border-slate-400  hover:bg-slate-300"
            }`}
          >
            1
          </div>

          <div
            onClick={() => (currentpage == 2 ? null : getData(2))}
            className={`  bg-white px-4 py-2 rounded-lg border ${
              currentpage == 2
                ? " text-green-500  border-green-400"
                : " cursor-pointer border-slate-400  hover:bg-slate-300"
            }`}
          >
            2
          </div>

          {currentpage == 1 ||
          currentpage == 2 ||
          currentpage == 3 ||
          currentpage == totalPages ? null : (
            <DotBox />
          )}
          {currentpage == 1 ||
          currentpage == 2 ||
          currentpage == totalPages ? null : (
            <div
            //   onClick={() => getData(currentpage)}
              className={`  bg-white px-4 py-2 rounded-lg border ${" text-green-500  border-green-400"}`}
            >
              {currentpage}
            </div>
          )}

          {currentpage == totalPages - 1 ? null : <DotBox />}
          <div
            onClick={() =>
              currentpage == totalPages ? null : getData(totalPages)
            }
            className={`  bg-white px-4 py-2 rounded-lg border ${
              currentpage == totalPages
                ? " text-green-500 border-green-400 "
                : " cursor-pointer border-slate-400  hover:bg-slate-300"
            }`}
          >
            {totalPages}
          </div>
          <div
            onClick={() =>
              currentpage == totalPages ? null : getData(currentpage + 1)
            }
            className={` bg-white px-4 py-2 rounded-lg border   ${
              currentpage == totalPages
                ? " text-slate-300 border-slate-300 cursor-not-allowed"
                : "cursor-pointer border-slate-400 hover:bg-slate-300 text-green-400 "
            }`}
          >
            {">"}
          </div>
        </div>
      </div>
    );
  }
}
