import * as React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker } from "react-day-picker";

import { cn } from "../lib/utils.ts";
import { buttonVariants } from "../../components/ui/button.tsx";
// import "react-day-picker/dist/style.css";
import "./calendar.css";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-0 text-black      bg-white", className)}
      classNames={{
        months: " text-blackflex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 ",
        month: "  space-y-4 ",
        caption:
          "flex flex-row-reverse justify-end items-center pt-1 relative  ",
        caption_label:
          "flex items-center rounded-full border-2 p-2 space-x text-sm font-normal ",
        nav: "space-x-1.5 flex items-center ",
        nav_button:
          " rounded-full border h-8 w-8 p-3 flex justify-center items-center ",
        // nav_button: cn(
        //   buttonVariants({ variant: "default" }),
        //   "h-7 w-7 rounded-full border-1 border-black"
        // ),
        nav_button_previous: "absolute left-0.5 ",
        nav_button_next: "absolute left-0.5 ",
        table: "w-full border-collapse space-y-1 ",
        head_row: "flex ",
        head_cell:
          "text-black rounded-md w-9 font-normal text-[0.8rem] dark:text-black ",
        row: "flex w-full mt-2 bg-white",
        cell: "text-center text-sm p-0 relative [&:has([aria-selected])]: bg-white first:[&:has([aria-selected])]: rounded-l-md   last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20 dark:[&:has([aria-selected])]:bg-white",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          " h-9 w-9 p-0 text-black hover:bg-[#69C920] hover:text-slate-50 font-normal aria-selected:opacity-100"
        ),
        day_selected:
          "bg-[#69C920]  text-black focus:bg-[#69C920] focus:text-black dark:bg-[#69C920] dark:text-red-900  dark:focus:bg-[#69C920] dark:focus:text-slate-900",
        day_outside: " text-slate-500 opacity-50 dark:text-slate-400",
        day_disabled: " text-slate-500 opacity-50 dark:text-slate-400",
        day_range_middle:
          " text-black aria-selected:bg-[#DBFFDF] aria-selected:text-slate-900 dark:aria-selected:text-black bg-[#DBFFDF] dark:aria-selected:text-slate-900",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className="h-6 w-5" />,
        IconRight: () => <ChevronRight className="h-6 w-5" />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
